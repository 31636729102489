import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import moment from "moment";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IoSearchOutline, IoAddOutline } from "react-icons/io5";

import api from "services/api";
import pathnames from "routes/pathnames";
import CONSTANSTS from "common/constansts";
import AppEmptyData from "components/app-empty-data";
import AppTable, { TableEditButton } from "components/app-table";
import { onHandleRequestError, onHandleGetIndex } from "common/utilities";

const PageAnnouncements = (props) => {
	const { t } = useTranslation("announcements");
	const navigate = useNavigate();
	const [announcements, setAnnouncements] = useState(null);
	const isEmpty = !announcements?.notificationResponses?.length || !announcements;
	const filterBy = useRef({ length: 10, page: 0, order: "desc", sortBy: null, valueEntered: null });
	const isEmptySearch = !announcements?.notificationResponses?.length && filterBy.current.valueEntered;

	useEffect(() => {
		getAnnonucements();
	}, []);

	useEffect(() => {
		return () => props.onHandleCancelRequest();
	}, [props]);

	const getAnnonucements = async () => {
		let response = null;

		try {
			const payload = filterBy.current;
			response = await api.get.announcements(payload);
		} catch (error) {
			onHandleRequestError(error);
		}

		if (response) {
			const nextAnnouncements = response.data.result;
			setAnnouncements(nextAnnouncements);
		}
	};

	const onHandleSortTable = useCallback((order, key) => {
		filterBy.current.page = 0;
		filterBy.current.order = order;
		filterBy.current.sortBy = key;
		getAnnonucements();
	}, []);

	const columns = useMemo(
		() => [
			{
				Header: "No",
				id: "index",
				accessor: (_row, i) => onHandleGetIndex(i, filterBy.current.page),
			},
			{
				Header: t("table.name"),
				accessor: "title",
				setFilter: (value) => {
					onHandleSortTable(value, "title");
				},
			},
			{
				Header: t("table.date"),
				accessor: "createdAt",
				Cell: ({ cell: { value } }) => moment(value).format(CONSTANSTS.DATE_FORMAT.YYYYMMDDHH_MMA),
				setFilter: (value) => {
					onHandleSortTable(value, "createdAt");
				},
			},
			{
				Header: "",
				accessor: "t-action",
				Cell: ({ row }) => {
					const announcement = row.original;
					return (
						<TableEditButton
							onClick={() => {
								navigate(pathnames.announcement, { state: { ...announcement, mode: CONSTANSTS.MODE.EDIT } });
							}}
						/>
					);
				},
			},
		],
		[t, navigate, onHandleSortTable]
	);

	const onChangePagination = (event) => {
		filterBy.current.page = event.target.value;
		getAnnonucements();
	};

	const onNavigateCreateAnnouncement = () => {
		navigate(pathnames.announcement, { state: { mode: CONSTANSTS.MODE.CREATE } });
	};

	const onSearchAnnouncement = (event) => {
		const value = event.target.value;
		filterBy.current = { length: 10, page: 0, order: "desc", sortBy: null, valueEntered: value };
		getAnnonucements();
	};

	const onHandleSearchAnnouncement = debounce(onSearchAnnouncement, 2000);

	return (
		<div className="page-announcements">
			<div className="announcements">
				<h1 className="announcements__title">{t("title")}</h1>

				<div className="announcements__header">
					<p className="announcements__description">{t("showing", { total: announcements?.notificationResponses?.length })}</p>

					<div className="announcements__search">
						<IoSearchOutline />
						<input size="28" name="search" placeholder="Search by announcement title" onChange={onHandleSearchAnnouncement} />
						<button className="announcements__new-cta" onClick={onNavigateCreateAnnouncement}>
							<IoAddOutline className="announcements__icon" />
							<p className="announcements__label">{t("newCta")}</p>
						</button>
					</div>
				</div>

				<div className="announcements__body">
					{(isEmpty || isEmptySearch) && <AppEmptyData data={announcements?.notificationResponses} search={isEmptySearch} />}
					{!isEmpty && <AppTable columns={columns} data={announcements.notificationResponses} total={announcements.totalPage} page={announcements.page} onChangePagination={onChangePagination} />}
				</div>
			</div>
		</div>
	);
};

export default PageAnnouncements;
