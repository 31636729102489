import React, { useEffect } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { IoChevronBack } from "react-icons/io5";
import { useNavigate, useLocation } from "react-router-dom";

import pathnames from "routes/pathnames";
import CONSTANSTS from "common/constansts";

const PageNotifications = (props) => {
	const { t } = useTranslation("notifications");
	const { state } = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		if (!state) navigate(pathnames.customers);
	}, [navigate, state]);

	const onHandleGoBack = () => {
		if (state.referrer) {
			navigate(state.referrer);
		} else {
			navigate(pathnames.customers);
		}
	};

	if (!state) return null;

	return (
		<div className="page-notifications">
			<div className="notifications">
				<div className="notifications__breadcrumb" onClick={onHandleGoBack}>
					<IoChevronBack className="notifications__arrow" />
					<p className="notifications__text">{t("back")}</p>
				</div>
				<div className="notifications__header">
					<h1 className="notifications__title">{state.title}</h1>
				</div>

				<div className="notifications__body">
					<p className="notifications__date">{moment(state.updatedAt).format(CONSTANSTS.DATE_FORMAT.YYYYMMDDHH_MMA)}</p>
					<p className="notifications__description">{state.message}</p>
				</div>
			</div>
		</div>
	);
};

export default PageNotifications;
