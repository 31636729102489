import React, { useMemo, useState, useCallback, useRef, useEffect } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { IoAddOutline } from "react-icons/io5";
import { Link, useParams, useNavigate } from "react-router-dom";

import api from "services/api";
import pathnames from "routes/pathnames";
import CONSTANSTS from "common/constansts";
import useIsMount from "hooks/use-is-mount";
import AppEmptyData from "components/app-empty-data";
import AppBreadCrumb from "components/app-bread-crumb";
import AppTable, { TableToggleButton, onDimissSelectedTable } from "components/app-table";
import AppPurchaseHistoryForm from "components/pages/page-customers/app-purchase-history-form";
import AppPurchaseHistoryCreateModal from "components/pages/page-customers/app-purchase-history-create-modal";
import { onHandleRequestError, onHandleRequestSuccess, onHandleGetIndex } from "common/utilities";

const getTabItemClassNames = ({ activeTab }) => {
	const classNames = ["purchase-transaction__item"];

	if (activeTab) classNames.push("purchase-transaction__item--active");

	return classNames.join(" ");
};

const PagePurchaseTransaction = (props) => {
	const { t } = useTranslation("purchaseHistory");
	const params = useParams();
	const isMount = useIsMount();
	const navigate = useNavigate();
	const purchaseHistoryCreateModalRef = useRef();
	const [purchaseHistory, setPurchaseHistory] = useState(null);
	const [purchaseHistoryDetails, setPurchaseHistoryDetails] = useState(null);
	const isEmpty = !purchaseHistory?.purchaseHistoryList?.length || !purchaseHistory;
	const filterBy = useRef({ length: 10, page: 0, order: "desc", sortBy: null, customerId: params.customerId });
	//prettier-ignore
	const breadCrumbData = useMemo(() => ({
		default: [{ label: t("breadCrumb.0.label"), path: pathnames.customers }, { label: t("breadCrumb.1.label") }],
	}), [t]);

	useEffect(() => {
		return () => props.onHandleCancelRequest();
	}, [props]);

	const getPurchaseHistroy = useCallback(async () => {
		let response = null;

		try {
			const payload = filterBy.current;
			response = await api.get.customerPurchaseHistory(payload);
		} catch (error) {
			onHandleRequestError(error);
			navigate(pathnames.customers);
		}

		if (response) {
			setPurchaseHistory(response.data.result);
		}
	}, [navigate]);

	useEffect(() => {
		if (isMount) getPurchaseHistroy();
	}, [isMount, getPurchaseHistroy]);

	//prettier-ignore
	const onHandleSortTable = useCallback((order, key) => {
		filterBy.current.page = 0;
		filterBy.current.order = order;
		filterBy.current.sortBy = key;
		getPurchaseHistroy();
	}, [getPurchaseHistroy]);

	//prettier-ignore
	const columns = useMemo(() => [
		{
			Header: "No",
			id: "index",
			accessor: (_row, i) => onHandleGetIndex(i, filterBy.current.page),
		},
		{
			Header: t("table.purchaseDate"),
			accessor: "purchaseDate",
			Cell: ({ cell: { value } }) => moment(value).format(CONSTANSTS.DATE_FORMAT.DDMMYYYY),
			setFilter: (value) => {
				onHandleSortTable(value, "purchaseDate");
			},
		},
		{
			Header: t("table.outlet"),
			accessor: "outletName",
			setFilter: (value) => {
				onHandleSortTable(value, "outletName");
			},
		},
		{
			Header: "",
			accessor: "t-action",
			Cell: ({ row }) => {
				const customerPurchaseHistory = row.original;
				return (
					<TableToggleButton onClick={() => { setPurchaseHistoryDetails(customerPurchaseHistory); }} />
				);
			},
		},
	], [t, onHandleSortTable]);

	const onHandleUpdatePurchaseTransaction = async (values, callback) => {
		const isEditMode = purchaseHistoryDetails?.purchaseHistoryId;
		const isCreateMode = !isEditMode;
		let response = null;

		try {
			const payload = {
				customerId: params.customerId,
				outletId: values.outlet.value,
				clPrescribed: values.clPrescribed,
				lensPrescribed: values.lensPrescribed,
				numberOfPurchase: values.numberOfPurchase,
				purchaseDate: values.purchaseDate.toISOString(),
			};

			if (values.index) payload.indexNum = values.index.value;
			if (values.clType) payload.clType = values.clType.value;
			if (values.lensType) payload.lensType = values.lensType.value;
			if (values.lensAccessories) payload.lensAccessories = values.lensAccessories.value;
			if (values.prescriptionRange) payload.prescriptionRange = values.prescriptionRange.value;
			if (values.clMaterial) payload.clMaterial = values.clMaterial.value;

			if (isEditMode) {
				payload.purchaseHistoryId = purchaseHistoryDetails.purchaseHistoryId;
				response = await api.post.updateCustomerPurchaseHistory(payload);
			}

			if (isCreateMode) response = await api.post.createCustomerPurchaseHistory(payload);
		} catch (error) {
			onHandleRequestError(error);
		} finally {
			callback();
		}

		if (response) {
			if (isEditMode) onHandleRequestSuccess(t("editSuccess"));

			if (isCreateMode) {
				onHandleRequestSuccess(t("createSuccess"));
			}

			getPurchaseHistroy();
		}
	};

	const onChangePagination = (event) => {
		filterBy.current.page = event.target.value;
		getPurchaseHistroy();
	};

	const getTabPath = (path) => {
		const nextPath = path + params.customerId;
		return nextPath;
	};

	const onHandleDismissUpdatePurchaseTransaction = () => {
		onDimissSelectedTable();
		setPurchaseHistoryDetails(null);
	};

	//prettier-ignore
	const onHandleVisibleCreateModal = useCallback(() => {
		onHandleDismissUpdatePurchaseTransaction(null);
		purchaseHistoryCreateModalRef.current?.onHandleShow();
	}, []);

	return (
		<div className="page-purchase-transaction">
			<div className="purchase-transaction">
				<AppBreadCrumb data={breadCrumbData} />

				<ul className="purchase-transaction__list">
					{CONSTANSTS.TABS.CUSTOMERS.map((item, i) => {
						const activeTab = i === 1;

						return (
							<li key={i} className={getTabItemClassNames({ activeTab })}>
								<Link to={getTabPath(item.path)}>{item.title}</Link>
							</li>
						);
					})}
				</ul>

				<h1 className="purchase-transaction__title">{t("title")}</h1>

				<div className="purchase-transaction__header">
					<p className="purchase-transaction__description">{t("showing", { total: purchaseHistory?.purchaseHistoryList?.length })}</p>
					<button className="purchase-transaction__new-cta" onClick={onHandleVisibleCreateModal}>
						<IoAddOutline className="purchase-transaction__icon" />
						<p className="purchase-transaction__label">{t("newCta")}</p>
					</button>
				</div>

				<div className="purchase-transaction__body">
					{isEmpty && <AppEmptyData data={purchaseHistory?.purchaseHistoryList} />}
					{!isEmpty && <AppTable columns={columns} data={purchaseHistory.purchaseHistoryList} total={purchaseHistory?.totalPage} onChangePagination={onChangePagination} />}
				</div>
				{purchaseHistoryDetails && (
					<div className="purchase-transaction__footer">
						<h1 className="purchase-transaction__title">{t("formTitle")}</h1>
						<AppPurchaseHistoryForm purchaseHistory={purchaseHistoryDetails} onHandleDismiss={onHandleDismissUpdatePurchaseTransaction} onHandleConfirm={onHandleUpdatePurchaseTransaction} />
					</div>
				)}
			</div>
			<AppPurchaseHistoryCreateModal ref={purchaseHistoryCreateModalRef} onHandleConfirm={onHandleUpdatePurchaseTransaction} />
		</div>
	);
};

export default PagePurchaseTransaction;
