import React, { memo, useCallback, useEffect } from "react";
import { IoChevronBack, IoClose } from "react-icons/io5";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import CONSTANSTS from "common/constansts";
import usePrevious from "hooks/use-previous";
import { resetAlert } from "redux/slices/alert-slice";

const getAlertClassNames = ({ error }) => {
	const classNames = ["alert-message"];

	if (error) classNames.push("alert-message--error");

	return classNames.join(" ");
};

const AppAlertMessage = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const prevLocation = usePrevious(location);
	const alert = useSelector((state) => state.alert);

	const onToggleMessage = () => {
		const invisibleClassName = "alert-message__text--hide";
		const caretActiveClassName = "alert-message__icon--active";
		const alertMessage = document.querySelector(".alert-message__text");
		const alertCaret = document.querySelector(".alert-message__icon");
		alertMessage.classList.toggle(invisibleClassName);
		alertCaret.classList.toggle(caretActiveClassName);
	};

	const onHandleDismissAlert = useCallback(() => {
		dispatch(resetAlert());
	}, [dispatch]);

	useEffect(() => {
		if (!prevLocation) return;
		const isActive = alert.active;
		const locationChanged = prevLocation.pathname !== location.pathname;
		let condition = isActive;
		let duration = 3000;

		if (CONSTANSTS.IS_DEVELOPMENT_MODE) {
			condition = isActive && locationChanged;
			duration = 10000;
		}

		if (condition) {
			setTimeout(() => {
				onHandleDismissAlert();
			}, duration);
		}
	}, [alert, prevLocation, location, onHandleDismissAlert]);

	if (!alert.active) return null;

	return (
		<div className="app-alert-message">
			<div className={getAlertClassNames({ error: alert.error })}>
				{CONSTANSTS.IS_DEVELOPMENT_MODE && <IoClose className="alert-message__close" onClick={onHandleDismissAlert} />}
				<p className="alert-message__text alert-message__text--hide">{alert.message}</p>
				{CONSTANSTS.IS_DEVELOPMENT_MODE && <IoChevronBack className="alert-message__icon" onClick={onToggleMessage} />}
			</div>
		</div>
	);
};

export default memo(AppAlertMessage);
