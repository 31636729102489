import React, { Fragment, useState, useCallback } from "react";

import AppLightboxImage from "components/app-lightbox-image";

//prettier-ignore
const withLightboxModal = (WrappedComponent) => ({ ...props }) => {
	const [source, setSource] = useState(null);
	const [visible, setVisible] = useState(false);

	
    const onHandleDimissLightbox = useCallback(() => {
		setVisible(false);
	}, []);

    const onHandleOpenLightbox = useCallback((image) => {
        setSource(image);
        setVisible(true);
	}, []);

    const onHandleDownloadPDF = useCallback((file) => {
        const link = document.createElement('a');
        const isFile = file.size > 0;
        const isUrl = file.size === 0;
        
        if(isFile) {
            link.download = file.name;
            link.href = window.URL.createObjectURL(file);
        }
        if(isUrl) {
            link.href = file.name;
            link.setAttribute('target', '_blank');
        }

        link.onclick = function() {
            let current = this;
            setTimeout(function() {
                window.URL.revokeObjectURL(current.href);
            }, 1500);
        };
        
        link.click();
        link.remove();

    }, []);

    return (
        <Fragment>
            <AppLightboxImage source={source} isOpen={visible} onHandleDismiss={onHandleDimissLightbox} />
            <WrappedComponent {...props} onHandleOpenLightbox={onHandleOpenLightbox} onHandleDownloadPDF={onHandleDownloadPDF}/>
        </Fragment>
    );
};

export default withLightboxModal;
