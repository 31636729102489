import React, { useMemo, useState, useRef, useCallback, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";

import api from "services/api";
import pathnames from "routes/pathnames";
import CONSTANSTS from "common/constansts";
import AppTable, { TableStatus } from "components/app-table";
import useIsMount from "hooks/use-is-mount";
import AppEmptyData from "components/app-empty-data";
import AppBreadCrumb from "components/app-bread-crumb";
import { onHandleRequestError } from "common/utilities";

const getTabItemClassNames = ({ activeTab }) => {
	const classNames = ["vouchers__item"];

	if (activeTab) classNames.push("vouchers__item--active");

	return classNames.join(" ");
};

const PageVouchers = (props) => {
	const params = useParams();
	const isMount = useIsMount();
	const navigate = useNavigate();
	const { t } = useTranslation("vouchers");
	const [vouchers, setVouchers] = useState(null);
	const isEmpty = !vouchers?.vouchers?.length || !vouchers;
	const filterBy = useRef({ length: 10, page: 0, customerId: params.customerId, status: "all" });
	//prettier-ignore
	const breadCrumbData = useMemo(() => ({
		default: [{ label: t("breadCrumb.0.label"), path: pathnames.customers }, { label: t("breadCrumb.1.label") }],
	}), [t]);

	const getVouchers = useCallback(async () => {
		let response = null;

		try {
			const payload = filterBy.current;
			response = await api.get.customerVouchers(payload);
		} catch (error) {
			onHandleRequestError(error);
			navigate(pathnames.customers);
		}

		if (response) {
			setVouchers(response.data.result);
		}
	}, [navigate]);

	useEffect(() => {
		if (isMount) {
			getVouchers();
		}
	}, [isMount, getVouchers]);

	useEffect(() => {
		return () => props.onHandleCancelRequest();
	}, [props]);

	//prettier-ignore
	const columns = useMemo(() => [
		{
			Header: t("table.voucherCode"),
			accessor: "voucherSerialNum",
		},
		{
			Header: t("table.voucherTitle"),
			accessor: "voucherTitle",
		},
		{
            Header: t("table.status"),
            accessor: "status",
            filterOptions: CONSTANSTS.FILTER_BY.VOUCHER_STATUS,
			Cell: ({ cell: { value } }) => <TableStatus status={value}/>,
            setFilter: (value) => {
				filterBy.current.page = 0;
				filterBy.current.status = value;
				getVouchers();
            },
        },
		{
			Header: t("table.dateUsed"),
			accessor: "consumeDate",
			Cell: ({ cell: { value } }) => value ? moment(value).format(CONSTANSTS.DATE_FORMAT.YYYYMMDDHH_MMA): "N/A",
		},
		{
			Header: t("table.redemptionDate"),
			accessor: "createdAt",
			Cell: ({ cell: { value } }) => moment(value).format(CONSTANSTS.DATE_FORMAT.YYYYMMDDHH_MMA),
		},
		{
			Header: t("table.expiredDate"),
			accessor: "expireAfter",
			Cell: ({ cell: { value } }) => moment(value).format(CONSTANSTS.DATE_FORMAT.YYYYMMDDHH_MMA),
		},
	], [t, getVouchers]);

	const onChangePagination = (event) => {
		filterBy.current.page = event.target.value;
		getVouchers();
	};

	const getTabPath = (path) => {
		const nextPath = path + params.customerId;
		return nextPath;
	};

	return (
		<div className="page-vouchers">
			<div className="vouchers">
				<AppBreadCrumb data={breadCrumbData} />

				<ul className="vouchers__list">
					{CONSTANSTS.TABS.CUSTOMERS.map((item, i) => {
						const activeTab = i === 4;

						return (
							<li key={i} className={getTabItemClassNames({ activeTab })}>
								<Link to={getTabPath(item.path)} state={{ customerId: 1 }}>
									{item.title}
								</Link>
							</li>
						);
					})}
				</ul>

				<h1 className="vouchers__title">{t("title")}</h1>

				<div className="vouchers__header">
					<p className="vouchers__description">{t("showing", { total: vouchers?.vouchers?.length })}</p>
				</div>

				<div className="vouchers__body">
					{isEmpty && <AppEmptyData data={vouchers?.vouchers} />}
					{!isEmpty && <AppTable columns={columns} data={vouchers.vouchers} total={vouchers?.totalPage} onChangePagination={onChangePagination} />}
				</div>
			</div>
		</div>
	);
};

export default PageVouchers;
