import React, { useEffect, useState, useCallback, useMemo, memo } from "react";
import { cloneDeep } from "lodash";
import { BsCheckCircleFill, BsCircle } from "react-icons/bs";

const initialValue = (multi, value) => {
	let init = "";

	if (multi && !value) {
		init = [];
	} else if (multi && value instanceof Array) {
		init = value;
	} else {
		init = value;
	}

	return init;
};

const AppRadioInput = ({ options = [], multi, error, touched, disabled, ...props }) => {
	const init = useMemo(() => initialValue(multi, props.value), [props, multi]);
	const [value, setValue] = useState("");
	const invalid = touched && error;

	useEffect(() => {
		if (init && !value) setValue(init);
	}, [init, value]);

	//prettier-ignore
	const onHandleSelect = useCallback((radio) => ( ) => {
		let nextValue = cloneDeep(value);
		if(multi) {
			if(value) {
				const valueExist = value.findIndex(o => o === radio.value) > -1;

				if(valueExist) { 
					nextValue = nextValue.filter(o => o !== radio.value);
				}
				else {
					nextValue.push(radio.value);
				}
			}
			setValue(nextValue);
			props.onChange(props.name, nextValue);
		}
		else {
			setValue(radio.value);
			props.onChange(props.name, radio.value);

		}
	}, [value, multi, props]);

	return (
		<div className="app-radio-input">
			<div className="radio-input">
				{options.map((item, i) => {
					const selected = multi && value ? value?.findIndex((o) => o === item.value) > -1 : item.value === value;
					return (
						<button key={i} type="button" className={getButtonClassNames({ selected })} onClick={onHandleSelect(item)} disabled={disabled}>
							{selected ? <BsCheckCircleFill className="radio-input__icon radio-input__icon--selected" /> : <BsCircle className="radio-input__icon" />}
							<p className="radio-input__label">{item.label}</p>
						</button>
					);
				})}
			</div>
			{invalid && <p className="radio-input__text radio-input__text--error">{error}</p>}
		</div>
	);
};

const getButtonClassNames = ({ selected }) => {
	const classNames = ["radio-input__button"];

	if (selected) classNames.push("radio-input__button--selected");

	return classNames.join(" ");
};

export default memo(AppRadioInput);
