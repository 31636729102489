import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import pathnames from "routes/pathnames";
import logo from "assets/images/menara-logo.svg";
import heroImage from "assets/images/hero-image.svg";

const PageNotFound = () => {
	const { t } = useTranslation("notFound");

	return (
		<div className="app-page page-not-found">
			<div className="not-found">
				<div className="not-found__container">
					<div className="not-found__hero-image">
						<img src={heroImage} alt="" />
					</div>
				</div>
				<div className="not-found__container">
					<div className="not-found__logo">
						<Link to={pathnames.signIn}>
							<img src={logo} alt="" />
						</Link>
					</div>
					<h1 className="not-found__title">404</h1>
					<p className="not-found__sub-title">{t("title")}</p>
				</div>
			</div>
		</div>
	);
};

export default PageNotFound;
