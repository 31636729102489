import React, { useMemo, useEffect, useCallback, useState, useRef } from "react";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IoSearchOutline, IoAddOutline } from "react-icons/io5";

import api from "services/api";
import pathnames from "routes/pathnames";
import CONSTANSTS from "common/constansts";
import AppEmptyData from "components/app-empty-data";
import AppTable, { TableStatus, TableEditButton } from "components/app-table";
import { onHandleRequestError, onHandleGetIndex } from "common/utilities";

const PageOutlets = (props) => {
	const { t } = useTranslation("outlets");
	const profile = useSelector((state) => state.auth.profile);
	const navigate = useNavigate();
	const [outlets, setOutlets] = useState(null);
	const filterBy = useRef({ length: 10, page: 0, status: null, state: null, order: "desc", sortBy: null, valueEntered: null });
	const isEmpty = !outlets?.storeResponseList?.length || !outlets;
	const isEmptySearch = !outlets?.storeResponseList?.length && filterBy.current.valueEntered;
	const isSuperAdmin = CONSTANSTS.ROLE.SUPER_ADMIN === profile?.role;

	useEffect(() => {
		getOutlets();
	}, []);

	useEffect(() => {
		return () => props.onHandleCancelRequest();
	}, [props]);

	const getOutlets = async () => {
		let response = null;

		try {
			const payload = filterBy.current;
			response = await api.get.outlets(payload);
		} catch (error) {
			onHandleRequestError(error);
		}

		if (response) {
			setOutlets(response.data.result);
		}
	};

	const onHandleSortTable = useCallback((order, key) => {
		filterBy.current.order = 0;
		filterBy.current.order = order;
		filterBy.current.sortBy = key;
		getOutlets();
	}, []);

	//prettier-ignore
	const columns = useMemo(() => [
		{
			Header: "No",
			id: "index",
			accessor: (_row, i) => onHandleGetIndex(i, filterBy.current.page),
		},
		{
			Header: t("table.code"),
			accessor: "storeCode",
		},
		{
			Header: t("table.name"),
			accessor: "storeName",
			setFilter: (value) => {
				onHandleSortTable(value, "name");
			},
		},
		{
			Header: t("table.pic"),
			accessor: "storePersonInCharge",
			setFilter: (value) => {
				onHandleSortTable(value, "storePersonInCharge");
			},
		},
		{
			Header: t("table.state"),
			accessor: "state",
			filterOptions: CONSTANSTS.FILTER_BY.STATES,
			Cell: ({ cell: { value } }) => <TableStatus status={value} />,
			setFilter: (value) => {
				filterBy.current.page = 0;
				filterBy.current.state = value;
				getOutlets();
			},
		},
		{
			Header: t("table.phone"),
			accessor: "storePhoneNumber",
			setFilter: (value) => {
				onHandleSortTable(value, "storePersonInCharge");
			},
		},
		{
			Header: t("table.status"),
			accessor: "storeStatus",
			filterOptions: CONSTANSTS.FILTER_BY.STATUS,
			Cell: ({ cell: { value } }) => <TableStatus status={value} />,
			setFilter: (value) => {
				filterBy.current.page = 0;
				filterBy.current.status = value;
				getOutlets();
			},
		},
		{
			Header: "",
			accessor: "t-action",
			Cell: ({ row }) => {
				const outlet = row.original;
				return (
					<TableEditButton
						onClick={() => {
							navigate(pathnames.outlet, { state: { ...outlet, mode: CONSTANSTS.MODE.EDIT } });
						}}
					/>
				);
			},
		},
	], [t, navigate, onHandleSortTable]);

	const onChangePagination = (event) => {
		filterBy.current.page = event.target.value;
		getOutlets();
	};

	const onNavigateCreateOutlet = () => {
		navigate(pathnames.outlet, { state: { mode: CONSTANSTS.MODE.CREATE } });
	};

	const onSearchOutlet = (event) => {
		const value = event.target.value;
		filterBy.current = { length: 10, page: 0, status: null, state: null, order: "desc", sortBy: null, valueEntered: value };
		getOutlets();
	};

	const onHandleSearchOutlet = debounce(onSearchOutlet, 2000);

	return (
		<div className="page-outlets">
			<div className="outlets">
				<h1 className="outlets__title">{t("title")}</h1>

				<div className="outlets__header">
					<p className="outlets__description">{t("showing", { total: outlets?.storeResponseList?.length })}</p>

					<div className="outlets__search">
						<IoSearchOutline />
						<input size={t("search").length} name="search" placeholder={t("search")} onChange={onHandleSearchOutlet} />
						{isSuperAdmin && (
							<button className="outlets__new-cta" onClick={onNavigateCreateOutlet}>
								<IoAddOutline className="outlets__icon" />
								<p className="outlets__label">{t("newCta")}</p>
							</button>
						)}
					</div>
				</div>

				<div className="outlets__body">
					{(isEmpty || isEmptySearch) && <AppEmptyData data={outlets?.storeResponseList} search={isEmptySearch} />}
					{!isEmpty && <AppTable columns={columns} data={outlets.storeResponseList} total={outlets.totalPage} page={outlets.page} onChangePagination={onChangePagination} />}
				</div>
			</div>
		</div>
	);
};

export default PageOutlets;
