import axios from "axios";
import moment from "moment";
import store from "redux/store";
import { updateAlert } from "redux/slices/alert-slice";

import CONSTANSTS from "./constansts";

export const trimStrings = (values) => {
	const isObject = values instanceof Object;
	const initialValue = {};

	if (isObject) {
		const trimmedValues = Object.keys(values).reduce((obj, key) => {
			const isString = values[key] instanceof String;

			if (isString) {
				obj[key] = values[key].trim();
			} else {
				obj[key] = values[key];
			}
			return obj;
		}, initialValue);

		return trimmedValues;
	}
};

export const sanitizeError = (originalError) => {
	let error = originalError;

	if (originalError instanceof Object) {
		if (originalError.response) {
			if (originalError.response.data) {
				error = originalError.response.data;
			} else {
				error = originalError.response;
			}
		} else if (originalError.data) {
			error = originalError.data;
		}

		if (error.translationKey) {
			return { message: error.translationKey };
		} else {
			return { message: error.message };
		}
	} else {
		error = originalError;
		return { message: error };
	}
};

export const onHandleRequestError = (error, callback) => {
	const requestCancelled = axios.isCancel(error);

	if (requestCancelled) return;

	const dispatch = store.dispatch;
	const sanitizedError = sanitizeError(error);
	sanitizedError.error = true;

	dispatch(updateAlert(sanitizedError));

	if (callback) callback(false);
};

export const onHandleRequestSuccess = (message, callback) => {
	const dispatch = store.dispatch;
	dispatch(updateAlert({ message }));

	if (callback) callback(false);
};

export const onHandleGetIndex = (i, currentPage) => {
	return currentPage >= 1 ? i + 10 * currentPage : i + 1;
};

export const getTimeStops = (start = "00:00", end = "23.59") => {
	var startTime = moment(start, CONSTANSTS.DATE_FORMAT.HHMM);
	var endTime = moment(end, CONSTANSTS.DATE_FORMAT.HHMM);

	if (endTime.isBefore(startTime)) {
		endTime.add(1, "day");
	}

	var timeStops = [];

	while (startTime <= endTime) {
		const timeSlot = new moment(startTime);
		const time = {
			label: timeSlot.format(CONSTANSTS.DATE_FORMAT.HHMM),
			value: timeSlot.utc().format(),
		};
		timeStops.push(time);
		startTime.add(30, "minutes");
	}
	return timeStops;
};

export const getBlobToBase64 = (blob) => {
	return new Promise((resolve) => {
		const reader = new FileReader();
		reader.onloadend = () => resolve(reader.result);
		reader.readAsDataURL(blob);
	});
};

export const formatDate = (date, format) => {
	let formattedDate = moment(date).calendar(null, {
		lastDay: "[Yesterday]",
		sameDay: "[Today]",
		nextDay: "[Tomorrow]",
		lastWeek: "DD MMM YYYY",
		nextWeek: "DD MMM YYYY",
		sameElse: "DD MMM YYYY",
	});

	if (format) {
		formattedDate = moment(date).format(format);

		if (format === "timestamp") {
			formattedDate = moment(date).unix() * 1000;
		}
	}

	return formattedDate;
};

export const getBreadCrumbList = (data, mode) => {
	const breadCrumbData = [];

	if (Array.isArray(data.default)) {
		breadCrumbData.push(...data.default);
	} else {
		breadCrumbData.push(data.default);
	}

	switch (mode) {
		case CONSTANSTS.MODE.CREATE:
			breadCrumbData.push(data.create);
			break;
		case CONSTANSTS.MODE.EDIT:
			breadCrumbData.push(data.edit);
			break;
		default:
			break;
	}

	return breadCrumbData;
};
