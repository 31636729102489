import React, { memo, useContext } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

import pathnames from "routes/pathnames";
import CONSTANSTS from "common/constansts";
import { AxiosContext } from "contexts/with-interceptor-provider";

const AuthenticateProvider = ({ children, authenticate, restrict }) => {
	const auth = localStorage.getItem(CONSTANSTS.LOCAL_STORAGE.STORAGE_TOKEN);
	const { profile } = useSelector((state) => state.auth);
	const props = useContext(AxiosContext);
	const location = useLocation();
	const WrappedComponent = children;
	const isNotSuperAdmin = restrict && restrict !== profile?.role;

	if (!auth && authenticate) return <Navigate replace to={pathnames.signIn} state={{ referrer: location.pathname }} />;

	if (isNotSuperAdmin) return <Navigate replace to={pathnames.customers} />;

	return <WrappedComponent {...props} />;
};

export default memo(AuthenticateProvider);
