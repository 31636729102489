import React, { useMemo, useEffect, useCallback } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";

import api from "services/api";
import pathnames from "routes/pathnames";
import CONSTANSTS from "common/constansts";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppTextarea from "components/app-textarea";
import AppRadioInput from "components/app-radio-input";
import AppBreadCrumb from "components/app-bread-crumb";
import { onHandleRequestError, onHandleRequestSuccess } from "common/utilities";

const PageAnnouncement = () => {
	const { t } = useTranslation("announcement");
	const navigate = useNavigate();
	const { state } = useLocation();
	const { profile } = useSelector((state) => state.auth);
	const isCreateMode = state?.mode === CONSTANSTS.MODE.CREATE;
	const isEditMode = state?.mode === CONSTANSTS.MODE.EDIT;
	const submitButtonLabel = isCreateMode ? t("create") : t("update");
	//prettier-ignore
	const breadCrumbData = useMemo(() => ({
		default: { label: t("breadCrumb.0.label"), path: pathnames.announcements },
		create: { label: t("breadCrumb.1.label") },
		edit: { label: t("breadCrumb.2.label") },
	}), [t]);
	//prettier-ignore
	const validationSchema = useMemo(() => Yup.object().shape({
		title: Yup.string().required(t("common:required")),
		description: Yup.string().required(t("common:required")),
		recipient: Yup.array().min(1, t("common:required")).typeError(t("common:required")),
	}), [t]);
	const initialValues = useMemo(() => {
		const values = { title: "", description: "", recipient: [] };

		if (state) {
			const userId = profile.superAdminId || profile.adminId;

			if (state.title) values.title = state.title;
			if (state.message) values.description = state.message;
			if (state.roles) values.recipient = state.roles;
			if (userId) values.userId = userId;
		}

		return values;
	}, [state, profile]);

	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values) => {
			onHandleUpdateAnnouncement(values);
		},
	});

	useEffect(() => {
		if (!state) navigate(pathnames.announcements);
	}, [navigate, state]);

	const onHandleUpdateAnnouncement = async (values) => {
		let response = null;

		try {
			const payload = values;

			if (isEditMode) {
				payload.id = state.notificationId;
				response = await api.post.updateAnnouncement(payload);
			}

			if (isCreateMode) response = await api.post.createAnnouncement(payload);
		} catch (error) {
			onHandleRequestError(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			if (isEditMode) onHandleRequestSuccess(t("editSuccess"));

			if (isCreateMode) onHandleRequestSuccess(t("createSuccess"));

			navigate(pathnames.announcement);
		}
	};

	const form = useMemo(() => {
		const fields = [
			{
				name: "title",
				label: t("field.0.label"),
				placeholder: t("field.0.placeholder"),
				disabled: false,
				type: "text",
			},

			{
				name: "description",
				label: t("field.1.label"),
				placeholder: t("field.1.placeholder"),
				disabled: false,
				type: "textarea",
			},
			{
				name: "recipient",
				label: t("field.1.label"),
				placeholder: "",
				type: "radio",
				options: CONSTANSTS.OPTIONS.RECIPIENTS,
				disabled: false,
			},
		];
		return fields;
	}, [t]);

	const onNavigateGoBack = useCallback(() => {
		navigate(pathnames.announcement);
	}, [navigate]);

	return (
		<div className="page-announcement">
			<div className="announcement">
				<AppBreadCrumb data={breadCrumbData} mode={state?.mode} />
				<h1 className="announcement__title">{t("title")}</h1>

				<form autoComplete="off" onSubmit={formik.handleSubmit}>
					<div className="announcement__body">
						<div className="announcement__content">
							{form.map(({ label, ...res }, i) => {
								const value = formik.values[res.name];
								const error = formik.errors[res.name];
								const touched = formik.touched[res.name];
								const isRadioField = res.type === "radio";
								const isTextareaField = res.type === "textarea";

								if (isRadioField) {
									return (
										<div key={i} className="announcement__field">
											<p className="announcement__label">{label}</p>
											<AppRadioInput {...res} multi error={error} touched={touched} value={value} onChange={formik.setFieldValue} />
										</div>
									);
								}

								if (isTextareaField) {
									return (
										<div key={i} className="announcement__field">
											<p className="announcement__label">{label}</p>
											<AppTextarea {...res} value={value} error={error} touched={touched} onChange={formik.handleChange} />
										</div>
									);
								}

								return (
									<div key={i} className="announcement__field">
										<p className="announcement__label">{label}</p>
										<AppInput {...res} value={value} error={error} touched={touched} onChange={formik.handleChange} />
									</div>
								);
							})}
						</div>
					</div>
					<div className="announcement__button-container">
						<div className="announcement__button-wrapper">
							<AppButton label="Cancel" type="button" cancel onClick={onNavigateGoBack} />
							<AppButton label={submitButtonLabel} type="submit" loading={formik.isSubmitting} />
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default PageAnnouncement;
