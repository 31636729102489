import React, { memo } from "react";
import DatePicker from "react-datepicker";
import { IoCalendarClearOutline } from "react-icons/io5";

import CONSTANSTS from "common/constansts";

const AppDateInput = ({ touched, error, type, value, onChange, placeholder, ...props }) => {
	const disabled = props.disabled;
	const invalid = touched && error;

	return (
		<div className="app-date-input">
			<div className={getWrapperClassNames({ invalid, disabled })}>
				<DatePicker
					{...props}
					className="app-date-input__input form-control"
					dateFormat={CONSTANSTS.DATE_FORMAT.ddMMYYYY}
					dropdownMode="select"
					showYearDropdown
					selected={value}
					placeholderText={placeholder}
					onChange={(date) => onChange(props.name, date)}
				/>
				<IoCalendarClearOutline className="app-date-input__icon" />
			</div>

			{invalid && <p className="app-date-input__text app-date-input__text--error">{error}</p>}
		</div>
	);
};

const getWrapperClassNames = ({ invalid, disabled }) => {
	const classNames = ["app-date-input__container"];

	if (disabled) classNames.push("app-date-input__container--disabled");

	if (invalid) classNames.push("app-date-input__container--error");

	return classNames.join(" ");
};

export default memo(AppDateInput);
