import React, { useEffect, useState, useMemo, useCallback } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";

import api from "services/api";
import pathnames from "routes/pathnames";
import CONSTANSTS from "common/constansts";
import useIsMount from "hooks/use-is-mount";
import usePrevious from "hooks/use-previous";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppTextarea from "components/app-textarea";
import AppDropdown from "components/app-dropdown";
import AppRadioInput from "components/app-radio-input";
import AppBreadCrumb from "components/app-bread-crumb";
import AppUploadInput from "components/app-upload-input";
import { onHandleRequestError, onHandleRequestSuccess } from "common/utilities";

const PageProduct = () => {
	const { t } = useTranslation("product");
	const profile = useSelector((state) => state.auth.profile);
	const isMount = useIsMount();
	const navigate = useNavigate();
	const { state } = useLocation();
	const isCreateMode = state?.mode === CONSTANSTS.MODE.CREATE;
	const isEditMode = state?.mode === CONSTANSTS.MODE.EDIT;
	const isAdmin = CONSTANSTS.ROLE.ADMIN === profile?.role;
	const isSuperAdmin = CONSTANSTS.ROLE.SUPER_ADMIN === profile?.role;
	const [categories, setCategories] = useState(null);
	const submitButtonLabel = isCreateMode ? t("create") : t("update");

	//prettier-ignore
	const breadCrumbData = useMemo(() => ({
		default: { label: t("breadCrumb.0.label"), path: pathnames.products },
		create: { label: t("breadCrumb.1.label") },
		edit: { label: t("breadCrumb.2.label") },
	}), [t]);
	//prettier-ignore
	const validationSchema = useMemo(() => Yup.object().shape({
		name: Yup.string().required(t("common:required")),
		category: Yup.mixed().when("requiredCategory", {
			is: (value) => value,
			then: Yup.object().required(t("common:required"))
		}),
		subcategory: Yup.mixed().when("requiredSubCategory", {
			is: (value) => value,
			then: Yup.object().required(t("common:required"))
		}),
		image: Yup.mixed().when("pdf", {
			is: (value) => !value,
			then: Yup.mixed()
				.required(t("common:required"))
				.test(CONSTANSTS.UPLOAD.ERRORS[0].type, CONSTANSTS.UPLOAD.ERRORS[0].error, (value) => value?.size <= CONSTANSTS.UPLOAD.SIZES_LIMIT)
				.test(CONSTANSTS.UPLOAD.ERRORS[1].type, CONSTANSTS.UPLOAD.ERRORS[1].error, (value) => CONSTANSTS.UPLOAD.IMAGES_TYPE.includes(value?.type)),
		}),
		pdf: Yup.mixed()
			.test(CONSTANSTS.UPLOAD.ERRORS[0].type, CONSTANSTS.UPLOAD.ERRORS[0].error, (value) => (value ? value?.size <= CONSTANSTS.UPLOAD.SIZES_LIMIT : true))
			.test(CONSTANSTS.UPLOAD.ERRORS[1].type, CONSTANSTS.UPLOAD.ERRORS[1].error, (value) => (value ? CONSTANSTS.UPLOAD.PDF_TYPE.includes(value?.type) : true)),
		description: Yup.string().when("pdf", {
			is: (value) => !value,
			then: Yup.string().required(t("common:required")),
		}),
	}), [t]);
	const initialValues = useMemo(() => {
		const values = {
			sku: "",
			name: "",
			category: "",
			subcategory: "",
			image: "",
			pdf: "",
			description: "",
			status: CONSTANSTS.STATUS.ACTIVE,
			requiredCategory: false,
			requiredSubCategory: false,
		};

		if (state) {
			if (state.requiredCategory) values.requiredCategory = state.requiredCategory;
			if (state.productSku) values.sku = state.productSku;
			if (state.productImage) values.image = { name: state.productImage, type: CONSTANSTS.UPLOAD.IMAGES_TYPE[0], size: 0 };
			if (state.productContent) values.pdf = { name: state.productContent, type: CONSTANSTS.UPLOAD.PDF_TYPE[0], size: 0 };
			if (state.productTitle) values.name = state.productTitle;
			if (state.productStatus) values.status = state.productStatus;
			if (state.productDescription) values.description = state.productDescription;
			if (state.categoryId) values.category = { label: state.categoryName, value: state.categoryId };
			if (state.variationId) values.subcategory = { label: state.variationName, value: state.variationId };
		}
		return values;
	}, [state]);
	const formik = useFormik({
		enableReinitialize: true,
		initialValues,
		validationSchema,
		onSubmit: (values) => {
			updateProduct(values);
		},
	});

	const previousFormikValues = usePrevious(formik.values);

	useEffect(() => {
		/* Reset Variation */
		const previousCategory = previousFormikValues?.category;
		const category = formik.values.category;

		if (category && previousCategory) {
			if (category.value !== previousCategory.value) {
				formik.setFieldValue("subcategory", "");
			}
		}
	}, [previousFormikValues, formik, categories]);

	useEffect(() => {
		if (categories) {
			/* Set Sub Category is Require */
			const categoryId = formik.values.category.value;
			const previousRequiredSubCategory = previousFormikValues?.requiredSubCategory;
			const requiredSubCategory = !!categories.filter((o) => o.categoryId === categoryId)[0]?.variationList?.length;

			if (previousRequiredSubCategory !== requiredSubCategory) {
				formik.setFieldValue("requiredSubCategory", requiredSubCategory);
			}
		}
	}, [previousFormikValues, formik, categories]);

	const getCategoriesDropdown = useCallback(() => {
		if (!categories) return [];
		const dropdownItems = categories.map((o) => ({ label: o.categoryTitle, value: o.categoryId }));

		return dropdownItems;
	}, [categories]);

	const getVariationsDropdown = useCallback(() => {
		if (!categories || !categories?.length) return [];
		const categoryId = formik.values.category.value;
		const seletedCategory = categories.filter((o) => o.categoryId === categoryId)[0];
		const isNotEmpty = seletedCategory?.variationList?.length;

		if (seletedCategory?.variationList && isNotEmpty) {
			return seletedCategory.variationList.map((o) => ({ label: o.variantTitle, value: o.variantId }));
		}

		return [];
	}, [formik, categories]);

	const form = useMemo(() => {
		const fields = [
			{
				name: "sku",
				label: t("field.0.label"),
				placeholder: t("field.0.placeholder"),
				disabled: true,
				type: "text",
			},
			{
				name: "name",
				label: t("field.1.label"),
				placeholder: t("field.1.placeholder"),
				disabled: isAdmin,
				type: "text",
			},
			{
				name: "category",
				label: t("field.2.label"),
				placeholder: t("field.2.placeholder"),
				options: getCategoriesDropdown(),
				disabled: isAdmin || !categories || !categories?.length,
				type: "select",
			},
			{
				name: "subcategory",
				label: t("field.3.label"),
				placeholder: t("field.3.placeholder"),
				options: getVariationsDropdown(),
				disabled: isAdmin || !formik.values.requiredSubCategory,
				type: "select",
			},
			{
				name: "image",
				label: t("field.4.label"),
				placeholder: t("field.4.placeholder"),
				uploadType: CONSTANSTS.UPLOAD.IMAGE,
				accept: CONSTANSTS.UPLOAD.IMAGES_TYPE,
				disabled: isAdmin,
				type: "file",
			},
			{
				name: "pdf",
				label: t("field.5.label"),
				placeholder: t("field.5.placeholder"),
				uploadType: CONSTANSTS.UPLOAD.PDF,
				accept: CONSTANSTS.UPLOAD.PDF_TYPE,
				disabled: isAdmin,
				type: "file",
			},
			{
				name: "description",
				label: t("field.6.label"),
				placeholder: t("field.6.placeholder"),
				disabled: isAdmin,
				type: "textarea",
			},
			{
				name: "status",
				label: t("field.7.label"),
				placeholder: t("field.7.placeholder"),
				type: "radio",
				options: CONSTANSTS.OPTIONS.STATUS,
				disabled: isAdmin,
			},
		];
		return fields;
	}, [t, formik, categories, isAdmin, getCategoriesDropdown, getVariationsDropdown]);

	const getCategories = useCallback(async () => {
		let response = null;

		try {
			const payload = { length: 20, page: 0, groupId: state.groupId, status: CONSTANSTS.STATUS.ACTIVE };
			response = await api.get.categoriesAndVariation(payload);
		} catch (error) {
			onHandleRequestError(error);
		}

		if (response) {
			setCategories(response.data.result.catVarDTOS);
		}
	}, [state]);

	useEffect(() => {
		if (isMount) getCategories();
	}, [isMount, getCategories]);

	//prettier-ignore
	const updateProduct = useCallback(async (values) => {
		let response = null;

		try {
			const formData = new FormData();
			formData.append("groupId", state.groupId);
			formData.append("status", values.status);
			formData.append("title", values.name);
			formData.append("description", values.description);
			formData.append("categoryId", values.category?.value || "");
			formData.append("variationId", values.subcategory?.value || "");
			
			if (values.image.size) formData.append("image", values.image);
			if (values.pdf.size) formData.append("pdf", values.pdf);
			if (values.removePdf) formData.append("removePdf", values.removePdf);
			if (values.removeImage) formData.append("removeImage", values.removeImage);


			if (isEditMode) {
				formData.append("productId", state.productId);
				response = await api.post.updateProduct(formData);
			}

			if (isCreateMode) response = await api.post.createProduct(formData);
		} catch (error) {
			onHandleRequestError(error);
		}
		finally {
			formik.setSubmitting(false);
		}

		if (response) {
			if (isEditMode) onHandleRequestSuccess(t("editSuccess"));

			if (isCreateMode) onHandleRequestSuccess(t("createSuccess"));

			navigate(pathnames.products, { state: { groupId: state.groupId }});
		}
	}, [t, formik, state, isCreateMode, isEditMode, navigate]);

	const onNavigateGoBack = useCallback(() => {
		navigate(pathnames.products);
	}, [navigate]);

	return (
		<div className="page-product">
			<div className="product">
				<AppBreadCrumb data={breadCrumbData} mode={state?.mode} />
				<h1 className="product__title">{t("title")}</h1>

				<form autoComplete="off" onSubmit={formik.handleSubmit}>
					<div className="product__body">
						<div className="product__content">
							{form.map(({ label, ...res }, i) => {
								const value = formik.values[res.name];
								const error = formik.errors[res.name];
								const touched = formik.touched[res.name];
								const isRadioField = res.type === "radio";
								const isUploadField = res.type === "file";
								const isTextareaField = res.type === "textarea";
								const isDropdownField = res.type === "select";

								if (isUploadField) {
									return (
										<div key={i} className="product__field">
											<p className="product__label">{label}</p>
											<AppUploadInput value={value} error={error} touched={touched} onChange={formik.setFieldValue} {...res} />
										</div>
									);
								}

								if (isDropdownField) {
									return (
										<div key={i} className="product__field">
											<p className="product__label">{label}</p>
											<AppDropdown {...res} value={value} error={error} touched={touched} data={res.options} onChange={formik.setFieldValue} />
										</div>
									);
								}

								if (isRadioField) {
									return (
										<div key={i} className="product__field">
											<p className="product__label">{label}</p>
											<AppRadioInput {...res} value={value} onChange={formik.setFieldValue} />
										</div>
									);
								}

								if (isTextareaField) {
									return (
										<div key={i} className="product__field">
											<p className="product__label">{label}</p>
											<AppTextarea {...res} value={value} error={error} touched={touched} onChange={formik.handleChange} />
										</div>
									);
								}

								return (
									<div key={i} className="product__field">
										<p className="product__label">{label}</p>
										<AppInput {...res} value={value} error={error} touched={touched} onChange={formik.handleChange} />
									</div>
								);
							})}
						</div>
					</div>
					{isSuperAdmin && (
						<div className="product__button-container">
							<div className="product__button-wrapper">
								<AppButton label={t("cancel")} type="button" cancel onClick={onNavigateGoBack} />
								<AppButton label={submitButtonLabel} type="submit" loading={formik.isSubmitting} />
							</div>
						</div>
					)}
				</form>
			</div>
		</div>
	);
};

export default PageProduct;
