import React, { useRef, useEffect, useState, useMemo, useCallback } from "react";
import * as Yup from "yup";
import { compose } from "redux";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, useParams, useNavigate } from "react-router-dom";

import api from "services/api";
import pathnames from "routes/pathnames";
import CONSTANSTS from "common/constansts";
import useIsMount from "hooks/use-is-mount";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppTextarea from "components/app-textarea";
import AppDropdown from "components/app-dropdown";
import AppDateInput from "components/app-date-input";
import AppRadioInput from "components/app-radio-input";
import AppBreadCrumb from "components/app-bread-crumb";
import AppMobileInput from "components/app-mobile-input";
import asianCountries from "common/asian-countries";
import withLightboxModal from "contexts/with-lightbox-modal";
import { onHandleRequestError, onHandleRequestSuccess } from "common/utilities";
import AppAllocatePointsModal from "components/pages/page-customers/app-allocate-points-modal";
import creditPointsIcon from "assets/images/pages/page-customers/credit-points-icon.svg";

const getTabItemClassNames = ({ activeTab }) => {
	const classNames = ["customer__item"];

	if (activeTab) classNames.push("customer__item--active");

	return classNames.join(" ");
};

const getLabelClassNames = ({ important }) => {
	const classNames = ["customer__label"];

	if (important) classNames.push("customer__label--important");

	return classNames.join(" ");
};

const PageCustomer = (props) => {
	const { t } = useTranslation("customer");
	const params = useParams();
	const isMount = useIsMount();
	const navigate = useNavigate();
	const uploadImageRef = useRef();
	const allocatePointsModalRef = useRef();
	const profile = useSelector((state) => state.auth.profile);
	const [customer, setCustomer] = useState(null);
	const isAdmin = CONSTANSTS.ROLE.ADMIN === profile?.role;
	const isPendingVerification = useMemo(() => CONSTANSTS.STATUS.PENDING_VERIFICATION === customer?.accountStatus, [customer]);
	//prettier-ignore
	const breadCrumbData = useMemo(() => ({
		default: [{ label: t("breadCrumb.0.label"), path: pathnames.customers }, { label: t("breadCrumb.1.label") }],
	}), [t]);
	//prettier-ignore
	const validationSchema = useMemo(() => Yup.object().shape({
		fullName: Yup.string().required(t("common:required")),
		phoneNum: Yup.number().positive().min(1, t("common:errors.phoneNumber")).required(t("common:required")).typeError(t("common:errors.phoneNumber")),
	}), [t]);
	const initialValues = useMemo(() => {
		const values = {
			image: "",
			userId: "",
			signedUpApp: "",
			migratedCode: "",
			displayName: "",
			fullName: "",
			migratedEmail: "",
			email: "",
			dateOfBirth: null,
			phoneNum: "",
			dialCode: asianCountries[0],
			addressOne: "",
			addressTwo: "",
			postcode: "",
			city: "",
			state: null,
			occupation: "",
			remarks: "",
			dateJoined: new Date(),
			country: CONSTANSTS?.COUNTRIES[0],
			points: 0,
			status: "",
			subStatus: "",
		};

		if (customer) {
			values.signedUpApp = customer.signUpApp ? "Yes" : "No";
			if (customer.fullName) values.fullName = customer.fullName;
			if (customer.customerId) values.userId = customer.customerId;
			if (customer.displayName) values.displayName = customer.displayName;
			if (customer.dialCode) values.dialCode = asianCountries.filter((o) => o.dialCode === customer.dialCode)[0];
			if (customer.phoneNum) values.phoneNum = customer.phoneNum;
			if (customer.familyCodeAndUserId) values.migratedCode = customer.familyCodeAndUserId;
			if (customer.occupation) values.occupation = customer.occupation;
			if (customer.emailAddress) values.email = customer.emailAddress;
			if (customer.dob) values.dateOfBirth = new Date(customer.dob);
			if (customer.dateJoined) values.dateJoined = new Date(customer.dateJoined);
			if (customer.migratedEmail) values.migratedEmail = customer.migratedEmail;
			if (customer.addressLineOne) values.addressOne = customer.addressLineOne;
			if (customer.addressLineTwo) values.addressTwo = customer.addressLineTwo;
			if (customer.state) values.state = CONSTANSTS.OPTIONS.STATES.filter((o) => o.value === customer.state)[0];
			if (customer.city) values.city = customer.city;
			if (customer.postCode) values.postcode = customer.postCode;
			if (customer.remarks) values.remarks = customer.remarks;
			if (customer.points) values.points = customer.points;
			if (customer.subStatus) values.subStatus = customer.subStatus;
			if (customer.image) values.image = { name: customer.image, type: CONSTANSTS.UPLOAD.IMAGES_TYPE[0], size: 0 };
			if (customer.accountStatus) {
				values.status = customer.accountStatus;
				if (isPendingVerification) values.status = "Pending Verification";
			}
		}
		return values;
	}, [customer, isPendingVerification]);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues,
		validationSchema,
		onSubmit: (values) => {
			updateCustomer(values);
		},
	});
	const profileImage = formik.values.image?.size ? URL.createObjectURL(formik.values.image) : formik.values.image.name;
	const profileImageStyle = { backgroundImage: `url(${profileImage})` };

	const getCustomer = useCallback(async () => {
		let response = null;

		try {
			response = await api.get.customer(params.customerId);
		} catch (error) {
			onHandleRequestError(error);
			navigate(pathnames.customers);
		}

		if (response) {
			setCustomer(response.data.result);
		}
	}, [params, navigate]);

	useEffect(() => {
		if (isMount) {
			getCustomer();
		}
	}, [isMount, getCustomer]);

	const form = useMemo(() => {
		const getStatusField = () => {
			if (isPendingVerification) {
				return {
					name: "status",
					label: t("field.19.label"),
					placeholder: t("field.19.placeholder"),
					type: "text",
					disabled: true,
				};
			} else {
				return {
					name: "status",
					label: t("field.19.label"),
					placeholder: t("field.19.placeholder"),
					type: "radio",
					options: CONSTANSTS.OPTIONS.STATUS,
					disabled: false,
				};
			}
		};

		const fields = [
			// {
			// 	name: "signedUpApp",
			// 	label: t("field.0.label"),
			// 	placeholder: t("field.0.placeholder"),
			// 	disabled: true,
			// 	type: "text",
			// },
			{
				name: "userId",
				label: t("field.1.label"),
				placeholder: t("field.1.placeholder"),
				disabled: true,
				type: "text",
			},
			// {
			// 	name: "migratedCode",
			// 	label: t("field.2.label"),
			// 	placeholder: t("field.2.placeholder"),
			// 	disabled: false,
			// 	type: "text",
			// },
			{
				name: "displayName",
				label: t("field.3.label"),
				placeholder: t("field.3.placeholder"),
				disabled: false,
				type: "text",
			},
			{
				name: "fullName",
				label: t("field.4.label"),
				placeholder: t("field.4.placeholder"),
				important: true,
				disabled: false,
				type: "text",
			},
			// {
			// 	name: "migratedEmail",
			// 	label: t("field.5.label"),
			// 	placeholder: t("field.5.placeholder"),
			// 	disabled: true,
			// 	type: "text",
			// },
			{
				name: "email",
				label: t("field.6.label"),
				placeholder: t("field.6.placeholder"),
				disabled: true,
				type: "text",
			},
			{
				name: "dateOfBirth",
				label: t("field.7.label"),
				placeholder: t("field.7.placeholder"),
				maxDate: new Date(),
				disabled: false,
				type: "date",
			},
			{
				name: "phoneNum",
				label: t("field.8.label"),
				placeholder: t("field.8.placeholder"),
				important: true,
				disabled: false,
				type: "mobile",
			},
			{
				name: "addressOne",
				label: t("field.9.label"),
				placeholder: t("field.9.placeholder"),
				disabled: false,
				type: "text",
			},
			{
				name: "addressTwo",
				label: t("field.10.label"),
				placeholder: t("field.10.placeholder"),
				disabled: false,
				type: "text",
			},
			{
				name: "postcode",
				label: t("field.11.label"),
				placeholder: t("field.11.placeholder"),
				disabled: false,
				type: "text",
			},
			{
				name: "city",
				label: t("field.12.label"),
				placeholder: t("field.12.placeholder"),
				disabled: false,
				type: "text",
			},
			{
				name: "state",
				label: t("field.13.label"),
				placeholder: t("field.13.placeholder"),
				options: CONSTANSTS.OPTIONS.STATES,
				disabled: false,
				type: "select",
			},
			{
				name: "country",
				label: t("field.14.label"),
				placeholder: t("field.14.placeholder"),
				options: CONSTANSTS.OPTIONS.STATES,
				disabled: true,
				type: "select",
			},
			{
				name: "occupation",
				label: t("field.15.label"),
				placeholder: t("field.15.placeholder"),
				disabled: false,
				type: "text",
			},
			{
				name: "remarks",
				label: t("field.16.label"),
				placeholder: t("field.16.placeholder"),
				maxLength: 2500,
				disabled: false,
				type: "textarea",
			},
			{
				name: "dateJoined",
				label: t("field.17.label"),
				placeholder: t("field.17.placeholder"),
				disabled: true,
				type: "date",
			},
			{
				name: "points",
				label: t("field.18.label"),
				placeholder: t("field.18.placeholder"),
				disabled: true,
				type: "points",
			},
			getStatusField(),
			{
				name: "subStatus",
				label: t("field.20.label"),
				placeholder: t("field.20.placeholder"),
				type: "radio",
				options: CONSTANSTS.OPTIONS.SUB_STATUS,
				disabled: false,
			},
		];

		if (isAdmin) return fields.filter((o) => o.name !== "status");

		return fields;
	}, [t, isPendingVerification, isAdmin]);

	const updateCustomer = async (values) => {
		let response = null;

		try {
			const formData = new FormData();
			formData.append("customerId", customer.customerId);
			formData.append("addressLineOne", values.addressOne);
			formData.append("addressLineTwo", values.addressTwo);
			formData.append("city", values.city);
			formData.append("postCode", values.postcode);
			formData.append("phoneNumber", values.phoneNum);
			formData.append("dialCode", values.dialCode.dialCode);
			formData.append("displayName", values.displayName);
			formData.append("familyCodeAndUserId", values.migratedCode);
			formData.append("fullName", values.fullName);
			formData.append("occupation", values.occupation);
			formData.append("subStatus", values.subStatus);

			if (!isPendingVerification) formData.append("status", values.status);
			if (values.dateOfBirth) formData.append("dateOfBirth", values.dateOfBirth.toISOString());
			if (values.dateJoined) formData.append("dateJoined", values.dateJoined.toISOString());
			if (values.state) formData.append("state", values.state.value);
			if (values.country) formData.append("country", values.country.value);
			if (values.image.size) formData.append("image", values.image);
			if (values.remarks) formData.append("remarks", values.remarks);

			response = await api.post.updateCustomer(formData);
		} catch (error) {
			onHandleRequestError(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			setCustomer(response.data.result);
			onHandleRequestSuccess(t("success"));
		}
	};

	const onHandleConfirmAllocatePoints = async (values) => {
		let response = null;

		try {
			const payload = {
				customerId: customer.customerId,
				invoiceNumber: values.invoice,
				pointsValue: values.points,
				updatedBy: profile.name,
			};

			response = await api.post.updateCustomerPoints(payload);
		} catch (error) {
			onHandleRequestError(error);
		}

		if (response) {
			formik.setSubmitting(false);
			setCustomer(response.data.result.customerProfileDTO);
			onHandleRequestSuccess(t("pointsSuccess"));
		}
	};

	//prettier-ignore
	const onHandleSetImage = useCallback((event) => {
		const file = event.currentTarget.files[0];

		if (file) formik.setFieldValue("image", file);
	}, [formik]);

	const onNavigateGoBack = useCallback(() => {
		navigate(pathnames.customers);
	}, [navigate]);

	const onHandleToggleProfileActions = () => {
		document.querySelector(".customer__actions").classList.toggle("customer__actions--active");
	};

	const onHandleUploadImage = () => {
		uploadImageRef.current.click();
	};

	const getTabPath = (path) => {
		const nextPath = path + params.customerId;
		return nextPath;
	};

	return (
		<div className="page-customer">
			<div className="customer">
				<AppBreadCrumb data={breadCrumbData} />

				<ul className="customer__list">
					{CONSTANSTS.TABS.CUSTOMERS.map((item, i) => {
						const activeTab = i === 0;
						return (
							<li key={i} className={getTabItemClassNames({ activeTab })}>
								<Link to={getTabPath(item.path)}>{item.title}</Link>
							</li>
						);
					})}
				</ul>

				<h1 className="customer__title">{t("title")}</h1>

				<form autoComplete="off" onSubmit={formik.handleSubmit}>
					<div className="customer__body">
						<div className="customer__profile">
							<input hidden type="file" name="image" ref={uploadImageRef} accept="image/png, image/jpeg" onChange={onHandleSetImage} />
							{/* prettier-ignore */}
							<div tabIndex="0" className="customer__profile-image" onFocus={onHandleToggleProfileActions} onBlur={onHandleToggleProfileActions} style={profileImageStyle}>
                                <ul className="customer__actions">
                                    <li className="customer__action-item" onClick={() => props.onHandleOpenLightbox(formik.values.image)}>
                                        {t("uploadCta.view")}
                                    </li>
                                    <li className="customer__action-item" onClick={onHandleUploadImage}>
										{t("uploadCta.update")}
                                    </li>
                                </ul>
                            </div>
							<p className="customer__name">{customer?.displayName}</p>
						</div>

						<div className="customer__content">
							{form.map(({ label, important, ...res }, i) => {
								const value = formik.values[res.name];
								const error = formik.errors[res.name];
								const touched = formik.touched[res.name];
								const isDateField = res.type === "date";
								const isRadioField = res.type === "radio";
								const isPointsField = res.type === "points";
								const isDropdownField = res.type === "select";
								const isTextareaField = res.type === "textarea";
								const isMobileField = res.type === "mobile";

								if (isMobileField) {
									return (
										<div key={i} className="customer__field">
											<p className={getLabelClassNames({ important })}>{label}</p>
											<AppMobileInput
												{...res}
												value={value}
												error={error}
												touched={touched}
												onChange={formik.handleChange}
												dialCode={formik.values.dialCode}
												onSelectCountryCode={(value) => formik.setFieldValue("dialCode", value)}
											/>
										</div>
									);
								}

								if (isDropdownField) {
									return (
										<div key={i} className="customer__field">
											<p className="customer__label">{label}</p>
											<AppDropdown {...res} value={value} error={error} touched={touched} data={res.options} onChange={formik.setFieldValue} />
										</div>
									);
								}

								if (isTextareaField) {
									return (
										<div key={i} className="customer__field">
											<p className="customer__label">{label}</p>
											<AppTextarea {...res} value={value} error={error} touched={touched} onChange={formik.handleChange} />
										</div>
									);
								}

								if (isRadioField) {
									return (
										<div key={i} className="customer__field">
											<p className="customer__label">{label}</p>
											<AppRadioInput {...res} value={value} onChange={formik.setFieldValue} />
										</div>
									);
								}

								if (isDateField) {
									return (
										<div key={i} className="customer__field">
											<p className="customer__label">{label}</p>
											<AppDateInput {...res} value={value} error={error} touched={touched} onChange={formik.setFieldValue} />
										</div>
									);
								}

								if (isPointsField) {
									return (
										<div key={i} className="customer__field">
											<p className="customer__label">{label}</p>
											<p className="customer__points">{value}</p>
											<div className="customer__top-up">
												<button type="button" className="customer__top-up-cta" onClick={allocatePointsModalRef.current?.onHandleShow}>
													<img className="customer__top-up-icon" src={creditPointsIcon} alt="" />
													<p className="customer__top-up-label">{t("creditPoints")}</p>
												</button>
											</div>
										</div>
									);
								}

								return (
									<div key={i} className="customer__field">
										<p className={getLabelClassNames({ important })}>{label}</p>
										<AppInput {...res} value={value} error={error} touched={touched} onChange={formik.handleChange} />
									</div>
								);
							})}
						</div>
					</div>
					<div className="customer__button-container">
						<div className="customer__button-wrapper">
							<AppButton label={t("cancel")} type="button" cancel onClick={onNavigateGoBack} />
							<AppButton label={t("cta")} type="submit" loading={formik.isSubmitting} />
						</div>
					</div>
				</form>
			</div>

			<AppAllocatePointsModal ref={allocatePointsModalRef} onHandleConfirm={onHandleConfirmAllocatePoints} />
		</div>
	);
};

export default compose(withLightboxModal)(PageCustomer);
