import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createLogger } from "redux-logger";

import CONSTANSTS from "common/constansts";
import authReducer from "redux/slices/auth-slice";
import alertReducer from "redux/slices/alert-slice";
import emptyStateSlice from "redux/slices/empty-state-slice";

const debug = CONSTANSTS.IS_DEVELOPMENT_MODE;
const persistConfig = { key: "root", storage };
const logger = createLogger({ collapsed: true });
const reducers = {
	auth: authReducer,
	alert: alertReducer,
	emptyState: emptyStateSlice,
};
const combinedReducers = combineReducers(reducers);
const middleware = [debug && logger].filter(Boolean);

const rootReducer = (state, action) => {
	if (action.type === "logout") {
		state = undefined;
	}
	return combinedReducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(middleware),
});
export const persistor = persistStore(store);

export default store;
