import React, { memo, useImperativeHandle, forwardRef, useState, useCallback, useMemo } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import AppButton from "components/app-button";
import AppInput from "components/app-input";
import allocatePointsIcon from "assets/images/pages/page-customers/allocate-points-modal-icon.svg";

const initialValues = {
	invoice: "",
	points: "",
};

const AppAllocatePointsModal = forwardRef(({ onHandleConfirm }, ref) => {
	const { t } = useTranslation("components");
	const [visible, setVisible] = useState(false);

	//prettier-ignore
	const validationSchema = useMemo(() => Yup.object().shape({
		points: Yup.string().required(t("common:required")),
	}), [t]);
	const formik = useFormik({
		enableReinitialize: true,
		initialValues,
		validationSchema,
		onSubmit: (values) => {
			onHandleDismiss();
			onHandleConfirm(values);
		},
	});

	useImperativeHandle(ref, () => ({
		onHandleDismiss: onHandleDismiss,
		onHandleShow: onHandleShow,
	}));

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	const onHandleShow = useCallback(() => {
		setVisible(true);
	}, []);

	const onHandlePointsChange = (event) => {
		event.target.value = event.target.value.slice(0, 8);
		formik.handleChange(event);
	};

	return (
		<Modal className="app-allocate-points-modal" contentClassName="allocate-points-modal" centered isOpen={visible} toggle={onHandleDismiss}>
			<ModalHeader className="allocate-points-modal__header" toggle={onHandleDismiss}></ModalHeader>
			<form autoComplete="off" onSubmit={formik.handleSubmit}>
				<ModalBody className="allocate-points-modal__body">
					<img className="allocate-points-modal__image" src={allocatePointsIcon} alt="" />
					<h1 className="allocate-points-modal__title">{t("allocatePointsModal.title")}</h1>

					<div className="allocate-points-modal__field">
						<p className="allocate-points-modal__label">{t("allocatePointsModal.field.0.label")}</p>
						<AppInput type="text" name="invoice" placeholder={t("allocatePointsModal.field.0.placeholder")} error={formik.errors.invoice} touched={formik.touched.invoice} onChange={formik.handleChange} />
					</div>
					<div className="allocate-points-modal__field">
						<p className="allocate-points-modal__label">{t("allocatePointsModal.field.1.label")}</p>
						<AppInput type="number" name="points" placeholder={t("allocatePointsModal.field.1.placeholder")} error={formik.errors.points} touched={formik.touched.points} onChange={onHandlePointsChange} />
					</div>
				</ModalBody>
				<ModalFooter className="allocate-points-modal__footer">
					<AppButton type="button" label={t("allocatePointsModal.cancel")} cancel onClick={onHandleDismiss} />
					<AppButton type="submit" label={t("allocatePointsModal.cta")} />
				</ModalFooter>
			</form>
		</Modal>
	);
});

export default memo(AppAllocatePointsModal);
