import React, { memo, useImperativeHandle, forwardRef, useMemo, useState, useCallback } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppTextarea from "components/app-textarea";
import { ReactComponent as FaqIcon } from "assets/images/pages/page-faqs/faq-icon.svg";

const initialValues = {
	faqTitle: "",
	faqDescription: "",
};

const AppFaqCreateModal = forwardRef(({ isOpen, onHandleConfirm }, ref) => {
	const { t } = useTranslation("components");
	const [visible, setVisible] = useState(false);

	//prettier-ignore
	const validationSchema = useMemo(() => Yup.object().shape({
		faqTitle: Yup.string().required(t("common:required")),
		faqDescription: Yup.string().required(t("common:required")),
	}), [t]);

	const formik = useFormik({
		enableReinitialize: isOpen,
		initialValues,
		validationSchema,
		onSubmit: (values, arg) => {
			arg.resetForm();
			onHandleDismiss();
			onHandleConfirm(values, enableSubmitButton);
		},
	});

	const enableSubmitButton = useCallback(() => {
		formik.setSubmitting(false);
	}, [formik]);

	useImperativeHandle(ref, () => ({
		onHandleDismiss: onHandleDismiss,
		onHandleShow: onHandleShow,
	}));

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	const onHandleShow = useCallback(() => {
		setVisible(true);
	}, []);

	return (
		<Modal className="app-faq-create-modal" contentClassName="faq-create-modal" centered size="lg" isOpen={visible} toggle={onHandleDismiss}>
			<form autoComplete="off" onSubmit={formik.handleSubmit}>
				<ModalHeader className="faq-create-modal__header" toggle={onHandleDismiss}></ModalHeader>
				<ModalBody className="faq-create-modal__body">
					<div className="faq-create-modal__body-header">
						<FaqIcon className="faq-create-modal__icon" />
						<h1 className="faq-create-modal__title">{t("faqForm.title")}</h1>
					</div>

					<div className="faq-create-modal__field">
						<p className="faq-create-modal__label">{t("faqForm.field.0.label")}</p>
						<AppInput type="text" name="faqTitle" placeholder={t("faqForm.field.0.placeholder")} error={formik.errors.faqTitle} touched={formik.touched.faqTitle} onChange={formik.handleChange} />
					</div>

					<div className="faq-create-modal__field">
						<p className="faq-create-modal__label">{t("faqForm.field.1.label")}</p>
						<AppTextarea name="faqDescription" placeholder={t("faqForm.field.1.placeholder")} error={formik.errors.faqDescription} touched={formik.touched.faqDescription} onChange={formik.handleChange} />
					</div>
				</ModalBody>
				<ModalFooter className="faq-create-modal__footer">
					<AppButton type="button" label={t("faqForm.cancel")} cancel onClick={onHandleDismiss} />
					<AppButton type="submit" label={t("faqForm.update")} loading={formik.isSubmitting} />
				</ModalFooter>
			</form>
		</Modal>
	);
});

export default memo(AppFaqCreateModal);
