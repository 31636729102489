import PageSignIn from "pages/page-sign-in";
import PageForgotPassword from "pages/forgot-password/page-forgot-password";
import PageForgotPasswordEmailSent from "pages/forgot-password/page-forgot-password-email-sent";
import PageResetPassword from "pages/forgot-password/page-reset-password";
import PagePasswordUpdated from "pages/forgot-password/page-password-updated";
import PageExpiredLink from "pages/forgot-password/page-expired-link";
import PageCustomers from "pages/customers/page-customers";
import PageCustomer from "pages/customers/page-customer";
import PagePurchaseTransaction from "pages/customers/page-purchase-transaction";
import PagePointsHistory from "pages/customers/page-points-history";
import PageEyeExamination from "pages/customers/page-eye-examination";
import PageVouchers from "pages/customers/page-vouchers";
import PageGroups from "pages/page-groups";
import PageLoyaltyPrograms from "pages/loyalty-programs/page-loyalty-programs";
import PageLoyaltyProgram from "pages/loyalty-programs/page-loyalty-program";
import PageProducts from "pages/products/page-products";
import PageProduct from "pages/products/page-product";
import PageAnnouncements from "pages/announcements/page-announcements";
import PageAnnouncement from "pages/announcements/page-announcement";
import PageBanners from "pages/banners/page-banners";
import PageBanner from "pages/banners/page-banner";
import PageOutlets from "pages/outlets/page-outlets";
import PageOutlet from "pages/outlets/page-outlet";
import PageFaqs from "pages/page-faqs";
import PageSettings from "pages/settings/page-settings";
import PageHelpdesk from "pages/settings/page-helpdesk";
import PageUsers from "pages/settings/page-users";
import PageUser from "pages/settings/page-user";
import PageNotifications from "pages/page-notifications";
import PageNotFound from "pages/page-not-found";

import pathnames from "routes/pathnames";
import CONSTANSTS from "common/constansts";

const routes = [
	{
		path: pathnames.signIn,
		component: PageSignIn,
		query: null,
		title: "Sign In",
		authenticate: false,
		restrict: null,
	},
	{
		path: pathnames.forgotPassword,
		component: PageForgotPassword,
		query: null,
		title: "Forget Password",
		authenticate: false,
		restrict: null,
	},
	{
		path: pathnames.forgotPasswordEmailSent,
		component: PageForgotPasswordEmailSent,
		query: null,
		title: "Forget Password",
		authenticate: false,
		restrict: null,
	},
	{
		path: pathnames.resetPassword,
		component: PageResetPassword,
		query: null,
		title: "Reset Password",
		authenticate: false,
		restrict: null,
	},
	{
		path: pathnames.passwordUpdated,
		component: PagePasswordUpdated,
		query: null,
		title: "Password Updated",
		authenticate: false,
		restrict: null,
	},
	{
		path: pathnames.expiredLink,
		component: PageExpiredLink,
		query: null,
		title: "Expired",
		authenticate: false,
		restrict: null,
	},
	{
		path: pathnames.notification,
		component: PageNotifications,
		query: null,
		title: "Notification",
		authenticate: true,
		restrict: null,
	},
	{
		path: pathnames.customers,
		component: PageCustomers,
		query: null,
		title: "Customers",
		authenticate: true,
		restrict: null,
	},
	{
		path: pathnames.customer,
		component: PageCustomer,
		query: ":customerId",
		title: "Customer",
		authenticate: true,
		restrict: null,
	},
	{
		path: pathnames.purchaseTransaction,
		component: PagePurchaseTransaction,
		query: ":customerId",
		title: "Purchase Transaction",
		authenticate: true,
		restrict: null,
	},
	{
		path: pathnames.pointsHistory,
		component: PagePointsHistory,
		query: ":customerId",
		title: "Points History",
		authenticate: true,
		restrict: null,
	},
	{
		path: pathnames.eyeExamination,
		component: PageEyeExamination,
		query: ":customerId",
		title: "Eye examination",
		authenticate: true,
		restrict: null,
	},
	{
		path: pathnames.vouchers,
		component: PageVouchers,
		query: ":customerId",
		title: "Vouchers",
		authenticate: true,
		restrict: null,
	},
	{
		path: pathnames.groups,
		component: PageGroups,
		query: null,
		title: "Groups & Categories",
		authenticate: true,
		restrict: CONSTANSTS.ROLE.SUPER_ADMIN,
	},
	{
		path: pathnames.loyaltyPrograms,
		component: PageLoyaltyPrograms,
		query: null,
		title: "Loyalty Program",
		authenticate: true,
		restrict: null,
	},
	{
		path: pathnames.loyaltyProgram,
		component: PageLoyaltyProgram,
		query: null,
		title: "Loyalty Program",
		authenticate: true,
		restrict: null,
	},
	{
		path: pathnames.products,
		component: PageProducts,
		query: null,
		title: "Products",
		authenticate: true,
		restrict: null,
	},
	{
		path: pathnames.product,
		component: PageProduct,
		query: null,
		title: "Product",
		authenticate: true,
		restrict: null,
	},
	{
		path: pathnames.outlets,
		component: PageOutlets,
		query: null,
		title: "Outlets",
		authenticate: true,
		restrict: null,
	},
	{
		path: pathnames.outlet,
		component: PageOutlet,
		query: null,
		title: "Outlet",
		authenticate: true,
		restrict: null,
	},
	{
		path: pathnames.announcements,
		component: PageAnnouncements,
		query: null,
		title: "Announcements",
		authenticate: true,
		restrict: CONSTANSTS.ROLE.SUPER_ADMIN,
	},
	{
		path: pathnames.announcement,
		component: PageAnnouncement,
		query: null,
		title: "Announcement",
		authenticate: true,
		restrict: CONSTANSTS.ROLE.SUPER_ADMIN,
	},
	{
		path: pathnames.banners,
		component: PageBanners,
		query: null,
		title: "Banners",
		authenticate: true,
		restrict: null,
	},
	{
		path: pathnames.banner,
		component: PageBanner,
		query: null,
		title: "Banner",
		authenticate: true,
		restrict: null,
	},
	{
		path: pathnames.faqs,
		component: PageFaqs,
		query: null,
		title: "FAQ",
		authenticate: true,
		restrict: null,
	},
	{
		path: pathnames.settings,
		component: PageSettings,
		query: null,
		title: "Settings",
		authenticate: true,
		restrict: null,
	},
	{
		path: pathnames.helpdesk,
		component: PageHelpdesk,
		query: null,
		title: "Help Desk",
		authenticate: true,
		restrict: CONSTANSTS.ROLE.SUPER_ADMIN,
	},
	{
		path: pathnames.users,
		component: PageUsers,
		query: null,
		title: "Admin List",
		authenticate: true,
		restrict: CONSTANSTS.ROLE.SUPER_ADMIN,
	},
	{
		path: pathnames.user,
		component: PageUser,
		query: null,
		title: "Admin",
		authenticate: true,
		restrict: CONSTANSTS.ROLE.SUPER_ADMIN,
	},
	{
		path: "*",
		component: PageNotFound,
		query: null,
		title: "404",
		authenticate: false,
		restrict: null,
	},
];

export default routes;
