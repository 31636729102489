import React, { useMemo } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import api from "services/api";
import CONSTANSTS from "common/constansts";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import { onHandleRequestError, onHandleRequestSuccess } from "common/utilities";

const initialValues = { currentPassword: "", password: "", confirmPassword: "" };

const AppPasswordForm = () => {
	const { t } = useTranslation("components");
	const { profile } = useSelector((state) => state.auth);
	const isAdmin = CONSTANSTS.ROLE.ADMIN === profile?.role;
	const isSuperAdmin = CONSTANSTS.ROLE.SUPER_ADMIN === profile?.role;
	//prettier-ignore
	const validationSchema = useMemo(() => Yup.object().shape({
		currentPassword: Yup.string().required(t("common:required")),
		password: Yup.string().required(t("common:required")).matches(CONSTANSTS.REGEX.PASSWORD, t("common:errors.password")),
		confirmPassword: Yup.string()
			.required(t("common:required"))
			.oneOf([Yup.ref("password"), null], t("common:errors.confirmPassword")),
	}), [t]);
	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values) => {
			onHandleResetPassword(values);
		},
	});

	const onHandleResetPassword = async (values) => {
		let response = null;

		try {
			const payload = { newPassword: values.password, oldPassword: values.currentPassword };

			if (isAdmin) {
				payload.adminId = profile.adminId;
				response = await api.post.resetPassword(payload);
			}

			if (isSuperAdmin) {
				payload.superAdminId = profile.superAdminId;
				response = await api.post.resetSuperPassword(payload);
			}
		} catch (error) {
			onHandleRequestError(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			formik.resetForm();
			onHandleRequestSuccess(t("passwordForm.editSuccess"));
		}
	};

	return (
		<div className="app-password-form">
			<form className="password-form" autoComplete="off" onSubmit={formik.handleSubmit}>
				<h1 className="password-form__title">{t("passwordForm.title")}</h1>
				<div className="password-form__content">
					<div className="password-form__field">
						<p className="password-form__label">{t("passwordForm.field.0.label")}</p>
						<AppInput
							type="password"
							name="currentPassword"
							placeholder={t("passwordForm.field.0.placeholder")}
							value={formik.values.currentPassword}
							error={formik.errors.currentPassword}
							touched={formik.touched.currentPassword}
							onChange={formik.handleChange}
						/>
					</div>
					<div className="password-form__field">
						<p className="password-form__label">{t("passwordForm.field.1.label")}</p>
						<AppInput type="password" name="password" placeholder={t("passwordForm.field.1.placeholder")} value={formik.values.password} error={formik.errors.password} touched={formik.touched.password} onChange={formik.handleChange} />
					</div>
					<div className="password-form__field">
						<p className="password-form__label">{t("passwordForm.field.2.label")}</p>
						<AppInput
							type="password"
							name="confirmPassword"
							placeholder={t("passwordForm.field.2.placeholder")}
							value={formik.values.confirmPassword}
							error={formik.errors.confirmPassword}
							touched={formik.touched.confirmPassword}
							onChange={formik.handleChange}
						/>
					</div>
				</div>
				<div className="password-form__button-container">
					<div className="password-form__button-wrapper">
						<AppButton label={t("passwordForm.cancel")} type="reset" cancel />
						<AppButton label={t("passwordForm.cta")} type="submit" loading={formik.isSubmitting} />
					</div>
				</div>
			</form>
		</div>
	);
};

export default AppPasswordForm;
