import React, { useRef, useMemo, useCallback, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { cloneDeep } from "lodash";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { UncontrolledTooltip } from "reactstrap";
import { useNavigate, useLocation } from "react-router-dom";

import api from "services/api";
import pathnames from "routes/pathnames";
import CONSTANSTS from "common/constansts";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppTextarea from "components/app-textarea";
import AppRadioInput from "components/app-radio-input";
import AppBreadCrumb from "components/app-bread-crumb";
import AppUploadInput from "components/app-upload-input";
import AppSubBannerCreateModal from "components/pages/page-banners/app-sub-banner-create-modal";
import { getBlobToBase64, onHandleRequestError, onHandleRequestSuccess } from "common/utilities";
import { ReactComponent as InfoIcon } from "assets/images/info-icon.svg";
import { ReactComponent as RemoveIcon } from "assets/images/remove-icon.svg";

const maximumSubBanners = 20;

const PageBanner = () => {
	const subBannerCreateModalRef = useRef();
	const profile = useSelector((state) => state.auth.profile);
	const navigate = useNavigate();
	const { state } = useLocation();
	const { t } = useTranslation("banner");
	const isCreateMode = state?.mode === CONSTANSTS.MODE.CREATE;
	const isEditMode = state?.mode === CONSTANSTS.MODE.EDIT;
	const isAdmin = CONSTANSTS.ROLE.ADMIN === profile?.role;
	const isSuperAdmin = CONSTANSTS.ROLE.SUPER_ADMIN === profile?.role;
	const submitButtonLabel = isCreateMode ? t("create") : t("update");
	//prettier-ignore
	const breadCrumbData = useMemo(() => ({
		default: { label: t("breadCrumb.0.label"), path: pathnames.banners },
		create: { label: t("breadCrumb.1.label") },
		edit: { label: t("breadCrumb.2.label") },
	}), [t]);
	//prettier-ignore
	const validationSchema = useMemo(() => Yup.object().shape({
		title: Yup.string().required(t("common:required")),
		image: Yup.mixed()
			.required(t("common:required"))
			.test(CONSTANSTS.UPLOAD.ERRORS[0].type, CONSTANSTS.UPLOAD.ERRORS[0].error, (value) => value?.size <= CONSTANSTS.UPLOAD.SIZES_LIMIT)
			.test(CONSTANSTS.UPLOAD.ERRORS[1].type, CONSTANSTS.UPLOAD.ERRORS[1].error, (value) => CONSTANSTS.UPLOAD.IMAGES_TYPE.includes(value?.type)),
		pdf: Yup.mixed()
			.test(CONSTANSTS.UPLOAD.ERRORS[0].type, CONSTANSTS.UPLOAD.ERRORS[0].error, (value) => (value ? value?.size <= CONSTANSTS.UPLOAD.SIZES_LIMIT : true))
			.test(CONSTANSTS.UPLOAD.ERRORS[1].type, CONSTANSTS.UPLOAD.ERRORS[1].error, (value) => (value ? CONSTANSTS.UPLOAD.PDF_TYPE.includes(value?.type) : true)),
		description: Yup.string().when(["subBanner", "pdf"], {
			is: (subBanner, pdf) => !subBanner.length && !pdf,
			then: Yup.string().required(t("common:required")),
		}),
	}), [t]);
	const initialValues = useMemo(() => {
		const values = {
			title: "",
			subBanner: [],
			image: "",
			pdf: "",
			description: "",
			status: CONSTANSTS.STATUS.ACTIVE,
			removeSubbanner: [],
		};

		if (state) {
			if (state.title) values.title = state.title;
			if (state.subbanners) values.subBanner = state.subbanners;
			if (state.image) values.image = { name: state.image, type: CONSTANSTS.UPLOAD.IMAGES_TYPE[0], size: 0 };
			if (state.content) values.pdf = { name: state.content, type: CONSTANSTS.UPLOAD.PDF_TYPE[0], size: 0 };
			if (state.description) values.description = state.description;
			if (state.bannerStatus) values.status = state.bannerStatus;
		}

		return values;
	}, [state]);

	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values) => {
			onHandleUpdateBanner(values);
		},
	});

	useEffect(() => {
		if (!state) navigate(pathnames.banners);
	}, [navigate, state]);

	const onHandleUpdateBanner = async (values) => {
		let response = null;

		try {
			const formData = new FormData();
			formData.append("title", values.title);
			formData.append("bannerStatus", values.status);

			if (values.image.size) formData.append("image", values.image);
			if (values.pdf.size) formData.append("pdf", values.pdf);
			if (values.description) formData.append("description", values.description);
			if (values.removePdf) formData.append("removePdf", values.removePdf);
			if (values.removeImage) formData.append("removeImage", values.removeImage);
			if (values.removeSubbanner.length) formData.append("removeSubbanner", values.removeSubbanner);

			if (values.subBanner.length) {
				const newUploadedSubBanner = cloneDeep(values.subBanner);

				const enrichSubBanner = await Promise.all(
					newUploadedSubBanner.map(async ({ image, pdf, ...res }) => {
						/* createdTime UI Purpose only */
						const nextSubBanner = { ...res };

						if (image || pdf) {
							if (image && image.size) {
								nextSubBanner.image = await getBlobToBase64(image);
							}
							if (pdf && pdf.size) {
								nextSubBanner.pdf = await getBlobToBase64(pdf);
							}
						}
						return nextSubBanner;
					})
				);

				if (enrichSubBanner.length) {
					const removeNonUpdateSubbanner = enrichSubBanner.filter((o) => o.updatedTime);
					removeNonUpdateSubbanner.forEach(({ updatedTime, ...res }) => {
						/* updatedTime UI Purpose only */
						formData.append("subbannerList", JSON.stringify(res));
					});
				}
			}

			if (isEditMode) {
				formData.append("bannerId", state.bannerId);
				response = await api.post.updateBanner(formData);
			}

			if (isCreateMode) response = await api.post.createBanner(formData);
		} catch (error) {
			onHandleRequestError(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			if (isEditMode) onHandleRequestSuccess(t("editSuccess"));

			if (isCreateMode) onHandleRequestSuccess(t("createSuccess"));

			navigate(pathnames.banners);
		}
	};

	const form = useMemo(() => {
		const fields = [
			{
				name: "title",
				label: t("field.0.label"),
				placeholder: t("field.0.placeholder"),
				disabled: isAdmin,
				type: "text",
			},
			{
				name: "subBanner",
				label: t("field.1.label"),
				placeholder: t("field.1.placeholder"),
				disabled: isAdmin,
				type: "subbanner",
			},
			{
				name: "image",
				label: t("field.2.label"),
				placeholder: t("field.2.placeholder"),
				uploadType: CONSTANSTS.UPLOAD.IMAGE,
				accept: CONSTANSTS.UPLOAD.IMAGES_TYPE,
				disabled: isAdmin,
				type: "file",
			},
			{
				name: "pdf",
				label: t("field.3.label"),
				placeholder: t("field.3.placeholder"),
				uploadType: CONSTANSTS.UPLOAD.PDF,
				accept: CONSTANSTS.UPLOAD.PDF_TYPE,
				tooltips: true,
				disabled: isAdmin,
				type: "file",
			},
			{
				name: "description",
				label: t("field.4.label"),
				placeholder: t("field.4.placeholder"),
				disabled: isAdmin,
				type: "textarea",
			},
			{
				name: "status",
				label: t("field.5.label"),
				placeholder: t("field.5.placeholder"),
				type: "radio",
				options: CONSTANSTS.OPTIONS.STATUS,
				disabled: isAdmin,
			},
		];
		return fields;
	}, [t, isAdmin]);

	const onHandleSubBannerConfirmCreate = (values) => {
		const isEditSubBanner = values.bannerId;
		const isCreateSubBanner = !isEditSubBanner;
		let prevSubBanner = cloneDeep(formik.values.subBanner);
		let nextSubBanner = [];
		let insertIndex = 0;

		if (isEditSubBanner) {
			insertIndex = prevSubBanner.findIndex((item) => item.bannerId === values.bannerId);
			prevSubBanner = prevSubBanner.filter((item) => item.bannerId !== values.bannerId);
		}

		if (isCreateSubBanner) {
			insertIndex = prevSubBanner.findIndex((item) => item.updatedTime === values.updatedTime);
			prevSubBanner = prevSubBanner.filter((item) => item.updatedTime !== values.updatedTime);
		}

		if (prevSubBanner.length) nextSubBanner.push(...prevSubBanner);

		if (insertIndex < 0) insertIndex = 0;

		nextSubBanner.splice(insertIndex, 0, values);

		formik.setFieldValue("subBanner", nextSubBanner);
	};

	const onHandleSubBannerRemove = (subBannerIndex) => {
		const prevSubBanner = cloneDeep(formik.values.subBanner);
		const prevRemoveSubbanner = cloneDeep(formik.values.removeSubbanner);

		const nextSubBanner = prevSubBanner.filter((item, i) => i !== subBannerIndex);
		const nextRemoveSubbanner = prevRemoveSubbanner.concat(prevSubBanner[subBannerIndex].bannerId);

		formik.setFieldValue("subBanner", nextSubBanner);
		formik.setFieldValue("removeSubbanner", nextRemoveSubbanner);
	};

	const onNavigateGoBack = useCallback(() => {
		navigate(pathnames.banners);
	}, [navigate]);

	return (
		<div className="page-banner">
			<div className="banner">
				<AppBreadCrumb data={breadCrumbData} mode={state?.mode} />
				<h1 className="banner__title">{t("title")}</h1>

				<form autoComplete="off" onSubmit={formik.handleSubmit}>
					<div className="banner__body">
						<div className="banner__content">
							{form.map(({ label, tooltips, ...res }, i) => {
								const value = formik.values[res.name];
								const error = formik.errors[res.name];
								const touched = formik.touched[res.name];
								const isRadioField = res.type === "radio";
								const isUploadField = res.type === "file";
								const isTextareaField = res.type === "textarea";
								const isSubBannerField = res.type === "subbanner";

								if (isSubBannerField) {
									const maxSubBanner = value.length === maximumSubBanners;

									return (
										<div key={i} className="banner__field">
											<p className="banner__label">{label}</p>
											<div key={i} className="banner__upload-container">
												{value?.map?.((item, j) => {
													return (
														<div key={j} className="banner__upload-content">
															{/* prettier-ignore */}
															<p className="banner__text banner__text--name" onClick={() => subBannerCreateModalRef.current?.onHandleShow(item)}>{item.title}</p>
															{isSuperAdmin && <RemoveIcon className="banner__remove-cta" onClick={() => onHandleSubBannerRemove(j)} />}
														</div>
													);
												})}

												{!value.length && isAdmin && (
													<div className="banner__upload-content">
														<p className="banner__text">N/A</p>
													</div>
												)}

												{isSuperAdmin && (
													<button type="button" className="banner__upload-cta" disabled={maxSubBanner} onClick={() => subBannerCreateModalRef.current?.onHandleShow()}>
														<p className="banner__text banner__text--subbanner">{t("newSubBanner")}</p>
													</button>
												)}
											</div>
										</div>
									);
								}

								if (isUploadField) {
									return (
										<div key={i} className="banner__field">
											<p className="banner__label" id="pdf-content-description">
												{/*prettier-ignore */}
												<span>{label} {tooltips && <InfoIcon />}</span>
											</p>
											{tooltips && (
												<UncontrolledTooltip placement="top" target="pdf-content-description">
													{t("tooltipsPDF")}
												</UncontrolledTooltip>
											)}
											<AppUploadInput value={value} error={error} touched={touched} onChange={formik.setFieldValue} {...res} />
										</div>
									);
								}

								if (isRadioField) {
									return (
										<div key={i} className="banner__field">
											<p className="banner__label">{label}</p>
											<AppRadioInput {...res} value={value} error={error} touched={touched} onChange={formik.setFieldValue} />
										</div>
									);
								}

								if (isTextareaField) {
									return (
										<div key={i} className="banner__field">
											<p className="banner__label" id="banner-description">
												{/*prettier-ignore */}
												<span>{label} <InfoIcon /></span>
											</p>
											{/*prettier-ignore */}
											<UncontrolledTooltip placement="top" target="banner-description">{t("tooltips")}</UncontrolledTooltip>

											<AppTextarea {...res} value={value} error={error} touched={touched} onChange={formik.handleChange} />
										</div>
									);
								}

								return (
									<div key={i} className="banner__field">
										<p className="banner__label">{label}</p>
										<AppInput {...res} value={value} error={error} touched={touched} onChange={formik.handleChange} />
									</div>
								);
							})}
						</div>
					</div>
					{isSuperAdmin && (
						<div className="banner__button-container">
							<div className="banner__button-wrapper">
								<AppButton label={t("cancel")} type="button" cancel onClick={onNavigateGoBack} />
								<AppButton label={submitButtonLabel} type="submit" loading={formik.isSubmitting} />
							</div>
						</div>
					)}
				</form>
			</div>
			<AppSubBannerCreateModal ref={subBannerCreateModalRef} onHandleConfirm={onHandleSubBannerConfirmCreate} />
		</div>
	);
};

export default PageBanner;
