import React, { useEffect, useRef, useMemo, useCallback } from "react";
import * as Yup from "yup";
import moment from "moment";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";

import api from "services/api";
import pathnames from "routes/pathnames";
import CONSTANSTS from "common/constansts";
import usePrevious from "hooks/use-previous";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppTextarea from "components/app-textarea";
import AppDateInput from "components/app-date-input";
import AppRadioInput from "components/app-radio-input";
import AppBreadCrumb from "components/app-bread-crumb";
import AppUploadInput from "components/app-upload-input";
import AppDeactivateRewardModal from "components/pages/page-loyalty-programs/app-deactivate-reward-modal";
import { onHandleRequestError, onHandleRequestSuccess } from "common/utilities";

const PageLoyaltyProgram = () => {
	const { t } = useTranslation("loyaltyProgram");
	const profile = useSelector((state) => state.auth.profile);
	const deactivateRewardModalRef = useRef();
	const { state } = useLocation();
	const navigate = useNavigate();
	const isCreateMode = state?.mode === CONSTANSTS.MODE.CREATE;
	const isEditMode = state?.mode === CONSTANSTS.MODE.EDIT;
	const isAdmin = CONSTANSTS.ROLE.ADMIN === profile?.role;
	const isSuperAdmin = CONSTANSTS.ROLE.SUPER_ADMIN === profile?.role;
	const submitButtonLabel = isCreateMode ? t("create") : t("update");

	//prettier-ignore
	const breadCrumbData = useMemo(() => ({
		default: { label: t("breadCrumb.0.label"), path: pathnames.loyaltyPrograms },
		create: { label: t("breadCrumb.1.label") },
		edit: { label: t("breadCrumb.2.label") },
	}), [t]);

	//prettier-ignore
	const validationSchema = useMemo(() => Yup.object().shape({
		code: Yup.string().required(t("common:required")),
		name: Yup.string().required(t("common:required")),
		image: Yup.mixed()
			.required(t("common:required"))
			.test(CONSTANSTS.UPLOAD.ERRORS[0].type, CONSTANSTS.UPLOAD.ERRORS[0].error, (value) => value?.size <= CONSTANSTS.UPLOAD.SIZES_LIMIT)
			.test(CONSTANSTS.UPLOAD.ERRORS[1].type, CONSTANSTS.UPLOAD.ERRORS[1].error, (value) => CONSTANSTS.UPLOAD.IMAGES_TYPE.includes(value?.type)),
		description: Yup.string().required(t("common:required")),
		voucherTitle: Yup.string().required(t("common:required")),
		voucherSubtitle: Yup.string().required(t("common:required")),
		voucherDescription: Yup.string().required(t("common:required")),
		points: Yup.number().positive().min(0, t("common:errors.redemptionPoints")).required(t("common:required")).typeError(t("common:errors.redemptionPoints")),
		startDate: Yup.date().required(t("common:required")),
		endDate: Yup.date().required(t("common:required")),
		validPeriod: Yup.number().positive().min(1, t("common:errors.validityPeriod")).required(t("common:required")).typeError(t("common:errors.validityPeriod")),
	}), [t]);

	const initialValues = useMemo(() => {
		const values = {
			code: "",
			name: "",
			image: "",
			description: "",
			voucherTitle: "",
			voucherSubtitle: "",
			voucherDescription: "",
			points: "",
			startDate: "",
			endDate: "",
			validPeriod: "",
			status: CONSTANSTS.STATUS.ACTIVE,
		};

		if (state) {
			if (state.rewardsCode) values.code = state.rewardsCode;
			if (state.rewardsName) values.name = state.rewardsName;
			if (state.image) values.image = { name: state.image, type: CONSTANSTS.UPLOAD.IMAGES_TYPE[0], size: 0 };
			if (state.description) values.description = state.description;
			if (state.voucherTitle) values.voucherTitle = state.voucherTitle;
			if (state.voucherSubtitle) values.voucherSubtitle = state.voucherSubtitle;
			if (state.voucherDescription) values.voucherDescription = state.voucherDescription;
			if (!isNaN(state.redeemPoints)) values.points = state.redeemPoints;
			if (state.activeFrom) values.startDate = new Date(state.activeFrom);
			if (state.activeTo) values.endDate = new Date(state.activeTo);
			if (state.expireAfterDays) values.validPeriod = state.expireAfterDays;
			if (state.status) values.status = state.status;
		}

		return values;
	}, [state]);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues,
		validationSchema,
		onSubmit: (values) => {
			validateRewardStatus(values);
		},
	});
	const previousFormikValues = usePrevious(formik.values);

	useEffect(() => {
		if (!state) navigate(pathnames.loyaltyPrograms);
	}, [navigate, state]);

	const validateRewardStatus = (values) => {
		const previousStatusIsActive = state.status === CONSTANSTS.STATUS.ACTIVE;
		const currentStatusIsInactive = values.status === CONSTANSTS.STATUS.INACTIVE;

		if (previousStatusIsActive && currentStatusIsInactive) return deactivateRewardModalRef.current?.onHandleShow();

		onHandleUpdateReward();
	};

	const onHandleUpdateReward = async () => {
		const values = formik.values;
		let response = null;

		try {
			const formData = new FormData();
			formData.append("rewardCode", values.code);
			formData.append("rewardName", values.name);
			formData.append("description", values.description);
			formData.append("startDate", values.startDate.toISOString());
			formData.append("endDate", values.endDate.toISOString());
			formData.append("redemptionPoint", values.points);
			formData.append("voucherDescription", values.voucherDescription);
			formData.append("voucherSubtitle", values.voucherSubtitle);
			formData.append("voucherTitle", values.voucherTitle);
			formData.append("validityPeriod", values.validPeriod);
			formData.append("rewardStatus", values.status);

			if (values.image?.size) formData.append("rewardImage", values.image);

			if (isEditMode) {
				formData.append("rewardId", state.rewardId);
				response = await api.post.updateReward(formData);
			}

			if (isCreateMode) response = await api.post.createReward(formData);
		} catch (error) {
			onHandleRequestError(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			if (isEditMode) onHandleRequestSuccess(t("editSuccess"));

			if (isCreateMode) {
				navigate(pathnames.loyaltyPrograms);
				onHandleRequestSuccess(t("createSuccess"));
			}
		}
	};

	const form = useMemo(() => {
		const startDate = formik.values.startDate;
		const endDateminDate = startDate ? moment(startDate).add(1, "days").toDate() : null;
		const fields = [
			{
				name: "code",
				label: t("field.0.label"),
				placeholder: t("field.0.placeholder"),
				disabled: isAdmin,
				type: "text",
			},
			{
				name: "name",
				label: t("field.1.label"),
				placeholder: t("field.1.placeholder"),
				disabled: isAdmin,
				type: "text",
			},
			{
				name: "image",
				label: t("field.2.label"),
				placeholder: t("field.2.placeholder"),
				disabled: isAdmin,
				type: "file",
			},
			{
				name: "description",
				label: t("field.3.label"),
				placeholder: t("field.3.placeholder"),
				disabled: isAdmin,
				type: "textarea",
			},
			{
				name: "voucherTitle",
				label: t("field.4.label"),
				placeholder: t("field.4.placeholder"),
				disabled: isAdmin,
				type: "text",
			},
			{
				name: "voucherSubtitle",
				label: t("field.5.label"),
				placeholder: t("field.5.placeholder"),
				disabled: isAdmin,
				type: "text",
			},
			{
				name: "voucherDescription",
				label: t("field.6.label"),
				placeholder: t("field.6.placeholder"),
				maxLength: 300,
				disabled: isAdmin,
				type: "textarea",
			},
			{
				name: "points",
				label: t("field.7.label"),
				placeholder: t("field.7.placeholder"),
				disabled: isAdmin,
				type: "text",
			},
			{
				name: "startDate",
				label: t("field.8.label"),
				placeholder: t("field.8.placeholder"),
				minDate: new Date(),
				disabled: isAdmin,
				type: "date",
			},
			{
				name: "endDate",
				label: t("field.9.label"),
				placeholder: t("field.9.placeholder"),
				disabled: isAdmin || !startDate,
				minDate: endDateminDate,
				type: "date",
			},
			{
				name: "validPeriod",
				label: t("field.10.label"),
				placeholder: t("field.10.placeholder"),
				disabled: isAdmin,
				type: "number",
			},
			{
				name: "status",
				label: t("field.11.label"),
				placeholder: t("field.11.placeholder"),
				type: "radio",
				options: CONSTANSTS.OPTIONS.STATUS,
				disabled: isAdmin,
			},
		];
		return fields;
	}, [t, formik, isAdmin]);

	useEffect(() => {
		/* Reset End Date */
		const previousStartDate = previousFormikValues?.startDate;
		const startDate = formik.values.startDate;

		if (previousStartDate) {
			const startDateIsEqual = moment(previousStartDate).isSame(startDate);

			if (!startDateIsEqual) formik.setFieldValue("endDate", "");
		}
	}, [previousFormikValues, formik]);

	const onNavigateGoBack = useCallback(() => {
		navigate(pathnames.loyaltyPrograms);
	}, [navigate]);

	return (
		<div className="page-loyalty-program">
			<div className="loyalty-program">
				<AppBreadCrumb data={breadCrumbData} mode={state?.mode} />
				<h1 className="loyalty-program__title">{t("title")}</h1>

				<form autoComplete="off" onSubmit={formik.handleSubmit}>
					<div className="loyalty-program__body">
						<div className="loyalty-program__content">
							{form.map(({ label, ...res }, i) => {
								const value = formik.values[res.name];
								const error = formik.errors[res.name];
								const touched = formik.touched[res.name];
								const isRadioField = res.type === "radio";
								const isDateField = res.type === "date";
								const isUploadField = res.type === "file";
								const isTextareaField = res.type === "textarea";

								if (isUploadField) {
									return (
										<div key={i} className="loyalty-program__field">
											<p className="loyalty-program__label">{label}</p>
											<AppUploadInput accept={CONSTANSTS.UPLOAD.IMAGES_TYPE} uploadType={CONSTANSTS.UPLOAD.IMAGE} value={value} error={error} touched={touched} onChange={formik.setFieldValue} {...res} />
										</div>
									);
								}

								if (isRadioField) {
									return (
										<div key={i} className="loyalty-program__field">
											<p className="loyalty-program__label">{label}</p>
											<AppRadioInput {...res} value={value} onChange={formik.setFieldValue} />
										</div>
									);
								}

								if (isTextareaField) {
									return (
										<div key={i} className="loyalty-program__field">
											<p className="loyalty-program__label">{label}</p>
											<AppTextarea {...res} value={value} error={error} touched={touched} onChange={formik.handleChange} />
										</div>
									);
								}

								if (isDateField) {
									return (
										<div key={i} className="loyalty-program__field">
											<p className="loyalty-program__label">{label}</p>
											<AppDateInput {...res} value={value} error={error} touched={touched} onChange={formik.setFieldValue} />
										</div>
									);
								}

								return (
									<div key={i} className="loyalty-program__field">
										<p className="loyalty-program__label">{label}</p>
										<AppInput {...res} value={value} error={error} touched={touched} onChange={formik.handleChange} />
									</div>
								);
							})}
						</div>
					</div>
					{isSuperAdmin && (
						<div className="loyalty-program__button-container">
							<div className="loyalty-program__button-wrapper">
								<AppButton label={t("cancel")} type="button" cancel onClick={onNavigateGoBack} />
								<AppButton label={submitButtonLabel} type="submit" loading={formik.isSubmitting} />
							</div>
						</div>
					)}
				</form>
			</div>
			<AppDeactivateRewardModal ref={deactivateRewardModalRef} formik={formik} onHandleConfirm={onHandleUpdateReward} />
		</div>
	);
};

export default PageLoyaltyProgram;
