import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import pathnames from "routes/pathnames";
import logo from "assets/images/menara-logo.svg";
import heroImage from "assets/images/hero-image.svg";

const PageForgotPasswordEmailSent = () => {
	const { t } = useTranslation("emailSent");

	return (
		<div className="app-page page-forgot-password-email-sent">
			<div className="email-sent">
				<div className="email-sent__container">
					<div className="email-sent__hero-image">
						<img src={heroImage} alt="" />
					</div>
				</div>
				<div className="email-sent__container">
					<div className="email-sent__logo">
						<Link to={pathnames.signIn}>
							<img src={logo} alt="" />
						</Link>
					</div>
					<h1 className="email-sent__title">{t("title")}</h1>
					<p className="email-sent__sub-title">{t("subTitle")}</p>
					<p className="email-sent__description">{t("description")}</p>

					<Link className="app-cta" to={pathnames.signIn}>
						{t("cta")}
					</Link>
				</div>
			</div>
		</div>
	);
};

export default PageForgotPasswordEmailSent;
