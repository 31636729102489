import * as firebase from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

import CONSTANSTS from "common/constansts";

const config = {
	apiKey: "AIzaSyDw6sY455sOEO-BPIW8ussYer0kRrc-t1c",
	authDomain: "menara-push-notification.firebaseapp.com",
	projectId: "menara-push-notification",
	storageBucket: "menara-push-notification.appspot.com",
	messagingSenderId: "715063649661",
	appId: "1:715063649661:web:6fcfa12a65fa14e5dd9814",
};

const fmc = firebase.initializeApp(config);

export const messaging = getMessaging(fmc);

const vapidKey = process.env.REACT_APP_FMC_VAPID_KEY;

export const requestNotificationPermission = async () => {
	try {
		const fmcToken = await getToken(messaging, { vapidKey });
		return fmcToken;
	} catch (error) {
		if (CONSTANSTS.IS_DEVELOPMENT_MODE) console.log(error);
	}
};
