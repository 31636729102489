import React, { memo, useImperativeHandle, useState, useCallback, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import CONSTANSTS from "common/constansts";
import AppButton from "components/app-button";
import { ReactComponent as DeactivateIcon } from "assets/images/warning-icon.svg";
import { ReactComponent as ActivateIcon } from "assets/images/pages/page-groups/activate-group-icon.svg";

const AppGroupStatusModal = forwardRef(({ onHandleConfirm }, ref) => {
	const { t } = useTranslation("components");
	const [visible, setVisible] = useState(false);
	const [group, setGroup] = useState(null);
	const [status, setStatus] = useState(CONSTANSTS.STATUS.ACTIVE);
	const isActivate = status === CONSTANSTS.STATUS.ACTIVE;

	useImperativeHandle(ref, () => ({
		onHandleDismiss: onHandleDismiss,
		onHandleShow: onHandleShow,
	}));

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	const onHandleShow = useCallback((selectedGroup) => {
		setGroup(selectedGroup);
		setStatus(selectedGroup.groupStatus);
		setVisible(true);
	}, []);

	const onHandleSubmit = useCallback(() => {
		onHandleDismiss();
		onHandleConfirm(group);
	}, [group, onHandleConfirm, onHandleDismiss]);

	return (
		<Modal size="lg" className="app-group-status-modal" contentClassName="group-status-modal" centered isOpen={visible} toggle={onHandleDismiss}>
			<ModalHeader className="group-status-modal__header" toggle={onHandleDismiss}></ModalHeader>
			<ModalBody className="group-status-modal__body">
				{isActivate ? <DeactivateIcon className="group-status-modal__icon" /> : <ActivateIcon className="group-status-modal__icon" />}
				<h1 className="group-status-modal__title">{t(`statusGroupModal.${status}.title`)}</h1>
				<p className="group-status-modal__description">{t(`statusGroupModal.${status}.description`)}</p>
			</ModalBody>
			<ModalFooter className="group-status-modal__footer">
				<AppButton type="button" label={t("statusGroupModal.cancel")} cancel onClick={onHandleDismiss} />
				<AppButton type="button" label={t(`statusGroupModal.cta`)} onClick={onHandleSubmit} />
			</ModalFooter>
		</Modal>
	);
});

export default memo(AppGroupStatusModal);
