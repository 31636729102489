import React, { useState, createContext, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { onMessage } from "firebase/messaging";

import api from "services/api";
import CONSTANSTS from "common/constansts";
import usePrevious from "hooks/use-previous";
import { onHandleRequestError } from "common/utilities";
import { updateNotification } from "redux/slices/auth-slice";
import { requestNotificationPermission, messaging } from "notifications/fmc-notification";
import menaraIcon from "assets/images/menara-icon.png";

export const NotificationContext = createContext();

const withNotifications = (WrappedComponent) => () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const prevLocation = usePrevious(location);
	const profile = useSelector((state) => state.auth.profile);
	const notification = useSelector((state) => state.auth.notification);
	const [notificationAlert, setNotificationAlert] = useState(false);

	useEffect(() => {
		if (prevLocation && location.pathname !== prevLocation.pathname) getNotificationAlert();
	}, [location, prevLocation]);

	const getNotificationAlert = async () => {
		try {
			const response = await api.get.notificationAlert();
			const alertNotify = response.data.result.haveNewNotification;
			setNotificationAlert(alertNotify);
		} catch (error) {
			console.error(error);
		}
	};

	//prettier-ignore
	const onRegisterPushNotification = useCallback(async (firebaseToken) => {
		const userId = profile.superAdminId || profile.adminId;
		const userRole = profile.superAdminId ? CONSTANSTS.ROLE.SUPER_ADMIN : CONSTANSTS.ROLE.ADMIN;
		const payload = { userId, token: firebaseToken, userRole };

		try {
			await api.post.pushNotification(payload);
		} catch (error) {
			console.error(error)
		} finally {
			dispatch(updateNotification(payload));
		}
	}, [profile, dispatch]);

	const onRemovePushNotification = useCallback(async () => {
		if (!notification) return;

		try {
			return await api.post.removePushNotification(notification);
		} catch (error) {
			console.error(error);
		}
	}, [notification]);

	const onRequestNotificationPermission = useCallback(async () => {
		if (!profile) return;

		if (!("Notification" in window)) return onHandleRequestError("This browser does not support desktop notification");

		try {
			const firebaseToken = await requestNotificationPermission();
			if (firebaseToken) onRegisterPushNotification(firebaseToken);
		} catch (error) {
			console.error(error);
		}
	}, [profile, onRegisterPushNotification]);

	onMessage(messaging, (payload) => {
		setNotificationAlert(true);

		var options = {
			body: payload.notification.body,
			icon: menaraIcon,
			vibrate: [100, 50, 100],
			data: {
				dateOfArrival: Date.now(),
				primaryKey: 1,
			},
		};
		new Notification(payload.notification.title, options);
	});

	return (
		<NotificationContext.Provider value={{ onRemovePushNotification, onRequestNotificationPermission }}>
			<WrappedComponent onRemovePushNotification={onRemovePushNotification} onRequestNotificationPermission={onRequestNotificationPermission} notificationAlert={notificationAlert} />
		</NotificationContext.Provider>
	);
};

export default withNotifications;
