import React, { useState, useCallback, memo } from "react";
import { Input as Field, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

import asianCountries from "common/asian-countries";

const AppMobileInput = ({ note, touched, error, icon, type, label, dialCode = {}, onSelectCountryCode, ...props }) => {
	const [isOpen, setIsOpen] = useState(false);
	const disabled = props.disabled;
	const invalid = touched && error;
	const selected = !!dialCode.dialCode;

	const onHandleToggleDropdown = () => {
		setIsOpen(!isOpen);
	};

	//prettier-ignore
	const onHandleSelectItem = useCallback((item) => {
		onSelectCountryCode(item);
	}, [onSelectCountryCode]);

	//prettier-ignore
	const onHandleBlur = useCallback((event) => {
        const name = event.currentTarget.name;
        event.currentTarget.value = event.currentTarget.value.trim();
        props.onChange(name, event);
    }, [props]);

	return (
		<div className="app-mobile-input">
			<div className={getWrapperClassNames({ invalid, disabled })}>
				<Dropdown isOpen={isOpen} toggle={onHandleToggleDropdown}>
					<DropdownToggle caret className={getToggleClassNames({ selected })} disabled={disabled} onClick={onHandleToggleDropdown}>
						<img className="app-mobile-input__flag" src={dialCode.flag} alt={dialCode.name} /> +{dialCode.dialCode}
					</DropdownToggle>
					<DropdownMenu className="app-mobile-input__list">
						{asianCountries?.map((item, i) => {
							return (
								<DropdownItem key={i} className="app-mobile-input__item" onClick={() => onHandleSelectItem(item)}>
									<img className="app-mobile-input__flag" src={item.flag} alt={item.name} /> +{item.dialCode}
								</DropdownItem>
							);
						})}
					</DropdownMenu>
				</Dropdown>

				<Field {...props} type="number" className="app-mobile-input__input" onBlur={onHandleBlur} autoComplete="off" />
			</div>

			{invalid && <p className="app-mobile-input__text app-mobile-input__text--error">{error}</p>}

			{note && <p className="app-mobile-input__text">{note}</p>}
		</div>
	);
};

const getWrapperClassNames = ({ invalid, disabled }) => {
	const classNames = ["app-mobile-input__container"];

	if (disabled) classNames.push("app-mobile-input__container--disabled");

	if (invalid) classNames.push("app-mobile-input__container--error");

	return classNames.join(" ");
};

const getToggleClassNames = ({ selected }) => {
	const classNames = ["app-mobile-input__toggle"];

	if (selected) classNames.push("app-mobile-input__toggle--active");

	return classNames.join(" ");
};

export default memo(AppMobileInput);
