import React, { Fragment, useState } from "react";
import { useLocation } from "react-router-dom";

import routes from "routes/routes";
import AppSideNav from "components/app-side-nav";
import AppNavHeader from "components/app-nav-header";

const AppLayout = ({ children, ...props }) => {
	const location = useLocation();
	const [headerTitle, setHeaderTitle] = useState("");

	const authenticated = routes.some((i) => {
		const locationWithQuery = location.pathname.split("/").slice(0, -1).join("/") + "/";
		let isEqual = i.path === location.pathname || locationWithQuery === i.path;
		return isEqual && i.authenticate;
	});

	return (
		<div className="app-layout">
			{authenticated && <AppSideNav setHeaderTitle={setHeaderTitle} />}
			<main className="app-layout__main">
				{authenticated && <AppNavHeader title={headerTitle} />}
				<div className="app-layout__container">
					{children.map((c, i) => {
						return <Fragment key={i}>{React.cloneElement(c, { ...props })}</Fragment>;
					})}
				</div>
			</main>
		</div>
	);
};

export default AppLayout;
