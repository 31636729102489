import React, { memo, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ReactComponent as EmptyIcon } from "assets/images/components/app-empty-data/empty-icon.svg";
import { ReactComponent as SearchEmptyIcon } from "assets/images/components/app-empty-data/search-empty-icon.svg";

const AppEmptyData = ({ data, search }) => {
	const { t } = useTranslation("common");
	const error = useSelector((state) => state.emptyState.error);
	const [text, setText] = useState(t("search.loading"));
	const isEmptyData = useMemo(() => data && data.length === 0, [data]);
	const isSearching = useMemo(() => search, [search]);

	useEffect(() => {
		let interval = null;

		if (!data || isSearching) {
			interval = setInterval(() => {
				setText((prev) => {
					if (prev.length >= 12) {
						return t("search.loading");
					} else {
						return (prev += "∙");
					}
				});
			}, 300);
		}

		if (isSearching && isEmptyData) {
			clearInterval(interval);
			return setText(t("search.empty"));
		}

		if (isEmptyData || error) {
			clearInterval(interval);
			return setText(t("search.error"));
		}

		return () => clearInterval(interval);
	}, [isEmptyData, data, isSearching, error, t]);

	return (
		<div className="app-empty-data">
			<div className="empty-data">
				{isSearching && isEmptyData ? <SearchEmptyIcon /> : <EmptyIcon />}
				<p className="empty-data__text">{text}</p>
			</div>
		</div>
	);
};

export default memo(AppEmptyData);
