import React, { useImperativeHandle, forwardRef, memo, useRef, useCallback, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { IoChevronForward } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { Modal } from "reactstrap";
import { cloneDeep } from "lodash";
import moment from "moment";

import api from "services/api";
import pathnames from "routes/pathnames";
import CONSTANSTS from "common/constansts";
import { formatDate } from "common/utilities";
import { ReactComponent as NotificationIcon } from "assets/images/components/app-notification-modal/notification-icon.svg";

const AppNotificationModal = forwardRef((props, ref) => {
	const { t } = useTranslation("components");
	const navigate = useNavigate();
	const location = useLocation();
	const [fetching, setFetching] = useState(false);
	const [visible, setVisible] = useState(false);
	const [notifications, setNotifications] = useState(null);
	const filterBy = useRef({ length: 10, page: 0, order: "desc" });
	const seeMoreVisible = notifications?.totalPage > 1 && filterBy.current.page + 1 !== notifications?.totalPage;

	useImperativeHandle(ref, () => ({
		onHandleDismiss: onHandleDismiss,
		onHandleShow: onHandleShow,
	}));

	//prettier-ignore
	const getNotifications = useCallback(async (concatNotification) => {
		let response = null;

		setFetching(true);

		try {
			response = await api.get.notifications(filterBy.current);
		} catch (error) {
			console.error(error);
		} finally {
			setFetching(false);
		}

		if (response) {
			const prevNotifications = cloneDeep(notifications);
			const nextNotifications = response.data.result;

			if (concatNotification) {
				const newNotifications = prevNotifications.notificationResponses.concat(nextNotifications.notificationResponses);
				nextNotifications.notificationResponses = newNotifications;
			}

			setNotifications(nextNotifications);
		}
	}, [notifications]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
		setNotifications(null);
		filterBy.current = { length: 10, page: 0, order: "desc" };
	}, []);

	const onHandleShow = useCallback(() => {
		getNotifications();
		setVisible(true);
	}, [getNotifications]);

	const onHandleSeeMore = () => {
		const concatNotification = true;
		filterBy.current.page += 1;
		getNotifications(concatNotification);
	};

	const onHandleReadNotification = async (item) => {
		try {
			await api.patch.readNotification(item.notificationId);
		} catch (error) {
			console.error(error);
		} finally {
			onHandleDismiss();
			navigate(pathnames.notification, { state: { ...item, referrer: location.pathname } });
		}
	};

	return (
		<Modal className="app-notification-modal" contentClassName="notification-modal" backdropClassName="app-notification-modal__backdrop" isOpen={visible} toggle={onHandleDismiss}>
			<div className="notification-modal__header">
				<p className="notification-modal__text">{t("notificationModal.title")}</p>
			</div>
			<div className="notification-modal__body">
				<ul className="notification-modal__list">
					{notifications?.notificationResponses?.map((item, i) => {
						const date = formatDate(item.updatedAt);
						const prevUpdatedDate = notifications.notificationResponses?.[i - 1]?.updatedAt;
						const nextUpdatedDate = notifications.notificationResponses?.[i + 1]?.updatedAt;
						const displayDate = i === 0 || formatDate(prevUpdatedDate) !== date;
						const nextDate = formatDate(nextUpdatedDate) !== date;

						return (
							<li className="notification-modal__item" key={i}>
								{displayDate && <div className="notification-modal__date">{date}</div>}
								<div className={getContentClassNames({ nextDate })} onClick={() => onHandleReadNotification(item)}>
									<div className={getIconClassNames({ status: item.status })}>
										<NotificationIcon />
									</div>
									<div className="notification-modal__wrapper">
										<p className="notification-modal__title">{item.title}</p>
										<p className="notification-modal__time">{moment(item.updatedAt).format(CONSTANSTS.DATE_FORMAT.HHMM)}</p>
									</div>
									<IoChevronForward className="notification-modal__arrow" />
								</div>
							</li>
						);
					})}
					{fetching && <div className="notification-modal__loading">{t("common:loading")}</div>}
					{seeMoreVisible && (
						<button className="notification-modal__read-more" onClick={onHandleSeeMore}>
							{t("notificationModal.cta")}
						</button>
					)}
				</ul>
			</div>
		</Modal>
	);
});

const getIconClassNames = ({ status }) => {
	const classNames = ["notification-modal__icon"];

	if (status === CONSTANSTS.NOTIFICATIONS.READ) classNames.push("notification-modal__icon--read");

	return classNames.join(" ");
};

const getContentClassNames = ({ nextDate }) => {
	const classNames = ["notification-modal__content"];

	if (nextDate) classNames.push("notification-modal__content--no-border");

	return classNames.join(" ");
};

export default memo(AppNotificationModal);
