import React, { memo, useCallback, useState } from "react";
import { cloneDeep } from "lodash";
import { useTable } from "react-table";
import { IoChevronDownOutline } from "react-icons/io5";
import { BsFillCaretDownFill, BsFillCaretUpFill } from "react-icons/bs";

import CONSTANSTS from "common/constansts";
import AppPagination from "components/app-pagination";
import { ReactComponent as EditIcon } from "assets/images/edit-icon.svg";

export const onDimissSelectedTable = () => {
	document.querySelectorAll(".table__row--selected").forEach((o) => o.classList.remove("table__row--selected"));
	document.querySelectorAll(".table__toggle-cta").forEach((o) => o.classList.remove("table__toggle-cta--active"));
};

export const TableStatus = ({ status }) => {
	switch (status) {
		case CONSTANSTS.STATUS.ACTIVE:
			return <span className="table__text table__text--bold table__text--active">Active</span>;
		case CONSTANSTS.STATUS.INACTIVE:
		case CONSTANSTS.STATUS.EXPIRED:
			return <span className="table__text table__text--bold table__text--inactive">{status}</span>;
		case CONSTANSTS.STATUS.PENDING_VERIFICATION:
			return <span className="table__text table__text--bold table__text--inactive">Pending Verification</span>;
		case CONSTANSTS.STATUS.USED:
			return <span className="table__text table__text--bold">Completed</span>;
		default:
			return <span className="table__text">{status}</span>;
	}
};

export const TablePoints = ({ points }) => {
	if (points) {
		let nextPoints = points;
		nextPoints = points.replace(/\s/gm, "");
		nextPoints = Number(nextPoints);

		if (nextPoints > 0) {
			return <span className="table__text table__text--points">{points}</span>;
		} else {
			return <span className="table__text table__text--inactive">{points}</span>;
		}
	} else {
		return "N/A";
	}
};

export const TableEditButton = memo(({ onClick }) => {
	return (
		<button className="table__edit-cta" onClick={onClick}>
			<EditIcon />
		</button>
	);
});

export const TableToggleButton = memo(({ onClick }) => {
	const onHandleToggle = (event) => {
		const row = event.currentTarget.parentNode.parentNode;
		document.querySelectorAll(".table__row--selected").forEach((o) => o.classList.remove("table__row--selected"));
		document.querySelectorAll(".table__toggle-cta").forEach((o) => o.classList.remove("table__toggle-cta--active"));

		if (row) row.classList = "table__row--selected";
		event.currentTarget.classList.add("table__toggle-cta--active");

		onClick(event);
	};

	return (
		<button className="table__toggle-cta" onClick={onHandleToggle}>
			<IoChevronDownOutline className="table__toggle-arrow" />
		</button>
	);
});

const visibleClassName = "table__filters--visible";

const onHandleDisplayFilterOptions = () => (event) => {
	const currentTarget = event.target;
	const tableFilters = currentTarget.querySelector(".table__filters");

	if (tableFilters.classList.contains(visibleClassName)) {
		onHandleHideFilterOptions();
	} else {
		onHandleHideFilterOptions();
		tableFilters.classList.toggle(visibleClassName);
	}
};

const onHandleHideFilterOptions = () => {
	const table = document.getElementById("fn-app-table");
	Array.from(table.querySelectorAll(".table__filters")).forEach((item) => {
		if (item.classList.contains(visibleClassName)) item.classList.remove(visibleClassName);
	});
};

const getCellStyle = (id) => {
	const isAction = id === "t-action";
	const isIndex = id === "index";

	if (isAction) return { textAlign: "right", width: "75px" };

	if (isIndex) return { width: "50px" };
};

const AppTable = ({ columns, data, total = 1, page = 0, onChangePagination }) => {
	const [tableSortStatus, setTableSortStatus] = useState({});
	const tableInstance = useTable({ columns, data });
	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

	//prettier-ignore
	const onHandleSetSorting = useCallback((i, column) => () => {
		onHandleHideFilterOptions();
		let nextStatus = cloneDeep(tableSortStatus);
		let currentStatus = nextStatus[i];

		const resetAllSorting = () => {
			for (const key in nextStatus) {
				nextStatus[key] = "desc";
			}
		};

		if (!currentStatus) {
			resetAllSorting();
			nextStatus[i] = "asc";
		}

		if (currentStatus) {
			switch (nextStatus[i]) {
				case "asc":
					resetAllSorting();
					nextStatus[i] = "desc";
					break;
				case "desc":
					resetAllSorting();
					nextStatus[i] = "asc";
					break;
				default:
					break;
			}
		}

		setTableSortStatus(nextStatus);
		column?.setFilter?.(nextStatus[i]);
		
	}, [tableSortStatus]);

	//prettier-ignore
	const setFilter = useCallback((value, column) => (event) => {
		event.stopPropagation();
		column?.setFilter?.(value);
		onHandleHideFilterOptions();
	}, []);

	const getCurrentSortingIcon = useCallback(
		(i) => {
			const isDescendingSort = tableSortStatus[i] === "desc" || tableSortStatus[i] === undefined;
			return isDescendingSort ? <BsFillCaretDownFill className="table__filter-caret" /> : <BsFillCaretUpFill className="table__filter-caret" />;
		},
		[tableSortStatus]
	);

	return (
		<div id="fn-app-table" className="app-table">
			<table className="table" {...getTableProps()}>
				<thead>
					{headerGroups.map((headerGroup) => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column, i) => {
								const haveFilter = column.filterOptions;
								const style = getCellStyle(column.id);
								const hideCaret = column.id === "t-action" || column.id === "index" || !column.setFilter;
								const filterAction = haveFilter ? onHandleDisplayFilterOptions(i) : onHandleSetSorting(i, column);

								return (
									<th width={column.width} {...column.getHeaderProps({ style })} onClick={filterAction}>
										{column.render("Header")}

										{haveFilter && <BsFillCaretDownFill className="table__filter-caret" />}

										{!haveFilter && !hideCaret && getCurrentSortingIcon(i)}

										{haveFilter && (
											<ul className="table__filters">
												{column?.filterOptions?.map((item, j) => (
													<li className="table__filter-item" key={j} onClick={setFilter(item.value, column)}>
														{item.label}
													</li>
												))}
											</ul>
										)}
									</th>
								);
							})}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{rows.map((row) => {
						prepareRow(row);
						return (
							<tr {...row.getRowProps()}>
								{row.cells.map((cell) => {
									const style = getCellStyle(cell.column.id);

									return (
										<td width={cell.column.width} {...cell.getCellProps({ style })}>
											{cell.render("Cell")}
										</td>
									);
								})}
							</tr>
						);
					})}
				</tbody>
			</table>
			<AppPagination total={total} page={page} onChangePagination={onChangePagination} />
		</div>
	);
};

export default AppTable;
