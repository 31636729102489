import React, { memo, useImperativeHandle, forwardRef, useState, useCallback, useMemo } from "react";
import * as Yup from "yup";
import { compose } from "redux";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from "reactstrap";

import CONSTANSTS from "common/constansts";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppTextarea from "components/app-textarea";
import AppUploadInput from "components/app-upload-input";
import { ReactComponent as BannerIcon } from "assets/images/pages/page-banners/banner-icon.svg";
import { ReactComponent as InfoIcon } from "assets/images/info-icon.svg";

const AppSubBannerCreateModal = forwardRef(({ isOpen, onHandleConfirm, ...props }, ref) => {
	const { t } = useTranslation("components");
	const profile = useSelector((state) => state.auth.profile);
	const [visible, setVisible] = useState(false);
	const [state, setState] = useState(null);
	const isAdmin = CONSTANSTS.ROLE.ADMIN === profile?.role;
	const isSuperAdmin = CONSTANSTS.ROLE.SUPER_ADMIN === profile?.role;

	useImperativeHandle(ref, () => ({
		onHandleDismiss: onHandleDismiss,
		onHandleShow: onHandleShow,
	}));

	//prettier-ignore
	const validationSchema = useMemo(() =>  Yup.object().shape({
		title: Yup.string().required(t("common:required")),
		image: Yup.mixed()
			.required(t("common:required"))
			.test(CONSTANSTS.UPLOAD.ERRORS[0].type, CONSTANSTS.UPLOAD.ERRORS[0].error, (value) => value?.size <= CONSTANSTS.UPLOAD.SIZES_LIMIT)
			.test(CONSTANSTS.UPLOAD.ERRORS[1].type, CONSTANSTS.UPLOAD.ERRORS[1].error, (value) => CONSTANSTS.UPLOAD.IMAGES_TYPE.includes(value?.type)),
		pdf: Yup.mixed()
			.test(CONSTANSTS.UPLOAD.ERRORS[0].type, CONSTANSTS.UPLOAD.ERRORS[0].error, (value) => (value ? value?.size <= CONSTANSTS.UPLOAD.SIZES_LIMIT : true))
			.test(CONSTANSTS.UPLOAD.ERRORS[1].type, CONSTANSTS.UPLOAD.ERRORS[1].error, (value) => (value ? CONSTANSTS.UPLOAD.PDF_TYPE.includes(value?.type) : true)),
		description: Yup.string().when("pdf", {
			is: (value) => !value,
			then: Yup.string().required(t("common:required")),
		}),
	}), [t]);

	const initialValues = useMemo(() => {
		const values = {
			updatedTime: state?.updatedTime,
			title: "",
			image: "",
			pdf: "",
			description: "",
			removeImage: "",
			removePdf: "",
			bannerId: state?.bannerId,
		};

		if (state) {
			if (state.title) values.title = state.title;
			if (state.pdf) values.pdf = state.pdf;
			if (state.description) values.description = state.description;
			/* previous response Image */
			if (typeof state.image === "string") values.image = { name: state.image, type: CONSTANSTS.UPLOAD.IMAGES_TYPE[0], size: 0 };
			if (state.image instanceof Object) values.image = state.image;
			if (typeof state.content === "string") values.pdf = { name: state.content, type: CONSTANSTS.UPLOAD.PDF_TYPE[0], size: 0 };
			if (state.content instanceof Object) values.pdf = state.content;
		}

		return values;
	}, [state]);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues,
		validationSchema,
		onSubmit: (values) => {
			/* UI Purpose only */
			if (!values.updatedTime) values.updatedTime = new Date().toISOString();

			onHandleConfirm(values);
			onHandleDimissSubBannerModal();
		},
	});

	const errors = formik.errors;
	const values = formik.values;
	const touched = formik.touched;
	const submitButtonLabel = state?.bannerId || state?.updatedTime ? t("subBannerModal.update") : t("subBannerModal.create");

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	const onHandleShow = useCallback((item = null) => {
		setState(item);
		setVisible(true);
	}, []);

	const onHandleDimissSubBannerModal = useCallback(() => {
		onHandleDismiss();
		formik.resetForm();
	}, [formik, onHandleDismiss]);

	return (
		<Modal className="app-sub-banner-create-modal" contentClassName="sub-banner-create-modal" centered size="lg" isOpen={visible} toggle={onHandleDimissSubBannerModal}>
			<form autoComplete="off" onSubmit={formik.handleSubmit}>
				<ModalHeader className="sub-banner-create-modal__header" toggle={onHandleDimissSubBannerModal}></ModalHeader>
				<ModalBody className="sub-banner-create-modal__body">
					<div className="sub-banner-create-modal__body-header">
						<BannerIcon className="sub-banner-create-modal__icon" />
						<h1 className="sub-banner-create-modal__title">{t("subBannerModal.title")}</h1>
					</div>

					<div className="sub-banner-create-modal__field">
						<p className="sub-banner-create-modal__label">{t("subBannerModal.field.0.label")}</p>
						<AppInput type="text" name="title" value={values.title} placeholder={t("subBannerModal.field.0.placeholder")} error={errors.title} touched={touched.title} disabled={isAdmin} onChange={formik.handleChange} />
					</div>

					<div className="sub-banner-create-modal__field">
						<p className="sub-banner-create-modal__label">{t("subBannerModal.field.1.label")}</p>
						<AppUploadInput
							name="image"
							value={values.image}
							error={errors.image}
							touched={touched.image}
							disabled={isAdmin}
							onChange={formik.setFieldValue}
							uploadType={CONSTANSTS.UPLOAD.IMAGE}
							accept={CONSTANSTS.UPLOAD.IMAGES_TYPE}
							placeholder={t("subBannerModal.field.1.placeholder")}
						/>
					</div>

					<div className="sub-banner-create-modal__field">
						<p className="sub-banner-create-modal__label" id="pdf-content-description">
							<span>
								{t("subBannerModal.field.2.label")} <InfoIcon />
							</span>
						</p>

						{/*prettier-ignore */}
						<UncontrolledTooltip placement="top" target="pdf-content-description">{t("subBannerModal.tooltipsPDF")}</UncontrolledTooltip>

						<AppUploadInput
							name="pdf"
							value={values.pdf}
							error={errors.pdf}
							touched={touched.pdf}
							disabled={isAdmin}
							onChange={formik.setFieldValue}
							uploadType={CONSTANSTS.UPLOAD.PDF}
							accept={CONSTANSTS.UPLOAD.PDF_TYPE}
							placeholder={t("subBannerModal.field.2.placeholder")}
						/>
					</div>

					<div className="sub-banner-create-modal__field">
						<p className="sub-banner-create-modal__label" id="sb-description">
							{/*prettier-ignore */}
							<span>{t("subBannerModal.field.3.label")} <InfoIcon /></span>
						</p>

						{/*prettier-ignore */}
						<UncontrolledTooltip placement="top" target="sb-description">{t("subBannerModal.tooltips")}</UncontrolledTooltip>

						<AppTextarea name="description" value={values.description} placeholder={t("subBannerModal.field.3.placeholder")} error={errors.description} touched={touched.description} disabled={isAdmin} onChange={formik.handleChange} />
					</div>
				</ModalBody>

				{isSuperAdmin && (
					<ModalFooter className="sub-banner-create-modal__footer">
						<AppButton type="button" label={t("subBannerModal.cancel")} cancel onClick={onHandleDimissSubBannerModal} />
						<AppButton type="submit" label={submitButtonLabel} />
					</ModalFooter>
				)}
			</form>
		</Modal>
	);
});

export default compose(memo)(AppSubBannerCreateModal);
