import React, { memo, useRef, useEffect, useState, useCallback, Fragment } from "react";
import { cloneDeep } from "lodash";
import { useTranslation } from "react-i18next";
import { IoAddOutline } from "react-icons/io5";

import api from "services/api";
import CONSTANSTS from "common/constansts";
import AppEmptyData from "components/app-empty-data";
import AppPagination from "components/app-pagination";
import AppGroupTab from "components/pages/page-groups/app-group-tab";
import AppGroupEditForm from "components/pages/page-groups/app-group-edit-form";
import AppGroupStatusModal from "components/pages/page-groups/app-group-status-modal";
import AppGroupCreateModal from "components/pages/page-groups/app-group-create-modal";
import AppCategoryForm from "components/pages/page-groups/app-category-form";
import { onHandleRequestError, onHandleRequestSuccess } from "common/utilities";
import { ReactComponent as ActivateIcon } from "assets/images/pages/page-groups/activate-icon.svg";

const getActivateIconClassNames = ({ inactive }) => {
	const classNames = ["accordion__activate-cta"];

	if (inactive) classNames.push("accordion__activate-cta--deactivate");

	return classNames.join(" ");
};

const getAddButtonClassNames = ({ active }) => {
	const classNames = ["accordion__add-cta"];

	if (active) classNames.push("accordion__add-cta--active");

	return classNames.join(" ");
};

const CreateButton = memo(({ label, type, active, onHandleClick, onHandleDismiss, onHandleConfirm }) => (
	<Fragment>
		<button className={getAddButtonClassNames({ active })} onClick={onHandleClick}>
			<p className="accordion__text">{label}</p>
		</button>
		{active && <AppCategoryForm type={type} onHandleDismiss={onHandleDismiss} onHandleConfirm={onHandleConfirm} />}
	</Fragment>
));

const PageGroups = (props) => {
	const { t } = useTranslation("groups");
	const groupStatusModalRef = useRef();
	const groupCreateModalRef = useRef();
	const [groups, setGroups] = useState(null);
	const [groupTab, setGroupTab] = useState(null);
	const [groupEditTab, setGroupEditTab] = useState(null);
	const [categoryTab, setCategoryTab] = useState(null);
	const [categoryEditTab, setCategoryEditTab] = useState(null);
	const [isCreateCategoryOpen, setIsCreateCategoryOpen] = useState(false);
	const [variation, setVariation] = useState(null);
	const [variantEditTab, setVariantEditTab] = useState(false);
	const [isCreateVariationOpen, setIsCreateVariationOpen] = useState(false);
	const filterBy = useRef({ length: 10, page: 0, order: "desc" });
	const isEmpty = !groups?.groupViews;

	useEffect(() => {
		getGroups();
	}, []);

	useEffect(() => {
		return () => props.onHandleCancelRequest();
	}, [props]);

	const getGroups = async () => {
		let response = null;

		try {
			const payload = filterBy.current;
			response = await api.get.groups(payload);
		} catch (error) {
			onHandleRequestError(error);
		}

		if (response) {
			const groupViews = response.data.result;
			setGroups(groupViews);
		}
	};

	const resetTabs = (type) => {
		switch (type) {
			case "variation":
				setGroupEditTab(false);
				setVariantEditTab(false);
				setCategoryEditTab(false);
				setIsCreateCategoryOpen(false);
				setIsCreateVariationOpen(false);
				break;
			case "category":
				setVariation(false);
				setCategoryTab(false);
				setGroupEditTab(false);
				setVariantEditTab(false);
				setCategoryEditTab(false);
				setIsCreateCategoryOpen(false);
				setIsCreateVariationOpen(false);
				break;
			default:
				setGroupTab(false);
				setVariation(false);
				setGroupEditTab(false);
				setCategoryTab(false);
				setVariantEditTab(false);
				setCategoryEditTab(false);
				setIsCreateCategoryOpen(false);
				setIsCreateVariationOpen(false);
				break;
		}
	};

	const onHandleAccordionTab = (item) => () => {
		const isExpandedGroupTab = groupTab === item.groupId;

		if (isExpandedGroupTab) {
			setGroupTab(null);
		} else {
			setGroupTab(item.groupId);
		}

		setIsCreateVariationOpen(false);
		setIsCreateCategoryOpen(false);
		setGroupEditTab(null);
	};

	const onHandleAccordionEditTab = (item) => (event) => {
		const type = event.target.dataset.type;

		switch (type) {
			case "category":
				resetTabs("category");
				setCategoryEditTab(item.categoryId);
				break;
			case "group":
				resetTabs();
				setGroupEditTab(item.groupId);
				break;
			case "variation":
				resetTabs("variation");
				setVariantEditTab(item.variantId);
				break;
			default:
				break;
		}
	};

	//prettier-ignore
	const onHandleGroupConfirmUpdate = useCallback(async (values, callback) => {
		let response = null;

		try {
			response = await api.post.updateGroup(values);
		} catch (error) {
			onHandleRequestError(error);
		} finally {
			callback();
		}

		if (response) {
			getGroups();
			resetTabs();
			onHandleRequestSuccess(t("groupEditSuccess"));
		}
	}, [t]);

	//prettier-ignore
	const onHandleGroupConfirmCreate = useCallback(async (values, callback) => {
		let response = null;

		try {
			response = await api.post.createGroup(values);
		} catch (error) {
			onHandleRequestError(error);
		} finally {
			callback();
		}

		if (response) {
			getGroups();
			onHandleRequestSuccess(t("groupCreateSuccess"));
		}
	}, [t]);

	const onHandleUpdateGroupStatus = useCallback(async (group) => {
		const groupStatus = group.groupStatus;
		let response = null;

		try {
			const payload = { groupId: group.groupId };
			switch (groupStatus) {
				case CONSTANSTS.STATUS.INACTIVE:
					payload.status = CONSTANSTS.TRIGGER_EVENTS.ACTIVE;
					break;
				case CONSTANSTS.STATUS.ACTIVE:
					payload.status = CONSTANSTS.TRIGGER_EVENTS.INACTIVE;
					break;
				default:
					break;
			}

			response = await api.patch.updateGroupStatus(payload);
		} catch (error) {
			onHandleRequestError(error);
		}

		if (response) {
			getGroups();

			switch (groupStatus) {
				case CONSTANSTS.STATUS.INACTIVE:
					onHandleRequestSuccess(`Group has been activated.`);
					break;
				case CONSTANSTS.STATUS.ACTIVE:
					onHandleRequestSuccess(`Group has been deactivated.`);
					break;
				default:
					break;
			}
		}
	}, []);

	const onHandleCategoryConfirmUpdate = async (values, callback) => {
		const isEditMode = values.categoryId && values.groupId;
		const isCreateMode = !values.categoryId && !values.groupId;
		let response = null;

		try {
			const formData = new FormData();
			formData.append("title", values.title);
			formData.append("status", values.status);

			if (values.image.size) formData.append("image", values.image);

			if (isEditMode) {
				formData.append("groupId", values.groupId);
				formData.append("categoryId", values.categoryId);

				response = await api.post.updateCategory(formData);
			}

			if (isCreateMode) {
				formData.append("groupId", groupTab);
				response = await api.post.createCategory(formData);
			}
		} catch (error) {
			onHandleRequestError(error);
		} finally {
			callback();
		}

		if (response) {
			getGroups();
			if (isEditMode) onHandleRequestSuccess(t("categoryEditSuccess"));

			if (isCreateMode) {
				setIsCreateCategoryOpen(false);
				onHandleRequestSuccess(t("categoryCreateSuccess"));
			}
		}
	};

	const onHandleVariationConfirmUpdate = async (values, callback) => {
		const isEditMode = values.variationId && values.categoryId;
		const isCreateMode = !values.variationId && !values.categoryId;
		let response = null;

		try {
			const formData = new FormData();
			formData.append("title", values.title);
			formData.append("status", values.status);

			if (values.image.size) formData.append("image", values.image);

			if (isEditMode) {
				formData.append("variantId", values.variationId);
				formData.append("categoryId", values.categoryId);

				response = await api.post.updateVariant(formData);
			}

			if (isCreateMode) {
				formData.append("groupId", groupTab);
				formData.append("categoryId", categoryTab);

				response = await api.post.createVariant(formData);
			}
		} catch (error) {
			onHandleRequestError(error);
		} finally {
			callback();
		}

		if (response) {
			const nextVariation = cloneDeep(variation);

			if (isEditMode) {
				if (variation) {
					const updatedVariantIndex = variation.findIndex((o) => o.variantId === values.variationId);
					nextVariation[updatedVariantIndex] = response.data.result.variant;
					setVariation(nextVariation);
				}
				setVariantEditTab(false);
				onHandleRequestSuccess(t("variantEditSuccess"));
			}

			if (isCreateMode) {
				if (variation) {
					nextVariation.push(response.data.result.variant);
					setVariation(nextVariation);
				}
				setIsCreateVariationOpen(false);
				onHandleRequestSuccess(t("variantCreateSuccess"));
			}
		}
	};

	//prettier-ignore
	const getVariations = (item) => async () => {
		const isExpandedCategoryTab = categoryTab === item.categoryId;
		let response = null;

		if(isExpandedCategoryTab) {
			return setCategoryTab(null);
		}

		setVariation(null);
		setCategoryEditTab(null);
		setIsCreateCategoryOpen(false);
		setIsCreateVariationOpen(false);

		try {
			const payload = { length: 1, page: 0, itemLength: 20, groupId: item.groupId, categoryId: item.categoryId };
			response = await api.get.categories(payload);
		} catch (error) {
			onHandleRequestError(error);
		}

		if (response) {
			const newVariation = [];
			const categories = response.data.result.categoryViews.filter((o) => {
				const sameGroup = item.groupId === o.category.groupId;
				const sameCategory = item.categoryId === o.category.categoryId;
				return sameGroup && sameCategory && o.variations && !!o.variations.length
			});

			if(categories[0]) newVariation.push(...categories[0].variations);
			setCategoryTab(item.categoryId);
			setVariation(newVariation);
		}
	};

	//prettier-ignore
	const onHandleVisibleCreateCategory = useCallback((boolean) => () => {
		setVariation(null);
		setCategoryTab(null);
		setCategoryEditTab(null);
		setIsCreateVariationOpen(false);
		setIsCreateCategoryOpen(boolean);
	}, []);

	//prettier-ignore
	const onHandleVisibleCreateVariation = useCallback((boolean) => () => {
		setCategoryEditTab(false);
		setIsCreateCategoryOpen(false);
		setIsCreateVariationOpen(boolean);
	}, []);

	const onChangePagination = (event) => {
		filterBy.current.page = event.target.value;
		getGroups();
	};

	return (
		<div className="page-groups">
			<div className="groups">
				<h1 className="groups__title">{t("title")}</h1>

				<div className="groups__header">
					<p className="groups__description">{t("showing", { total: groups?.length })}</p>

					{!isEmpty && (
						<button className="groups__new-cta" onClick={groupCreateModalRef.current?.onHandleShow}>
							<IoAddOutline className="groups__icon" />
							<p className="groups__label">{t("newCta")}</p>
						</button>
					)}
				</div>

				<div className="groups__body">
					{isEmpty && <AppEmptyData data={groups} />}
					{groups?.groupViews.map((item, i) => {
						const group = item.group;
						const categories = item.categories;
						const groupId = group.groupId;
						const isGroupActive = groupTab === groupId;
						const isEditGroupActive = groupEditTab === groupId;
						const groupIsInActive = group.groupStatus === CONSTANSTS.STATUS.INACTIVE;

						return (
							<div key={i} className="accordion">
								<div className="accordion__wrapper">
									<ActivateIcon className={getActivateIconClassNames({ inactive: groupIsInActive })} onClick={() => groupStatusModalRef.current?.onHandleShow(group)} />
									<AppGroupTab type="group" title={group.groupTitle} isActive={isGroupActive} onHandleEdit={onHandleAccordionEditTab(group)} onHandleToggle={onHandleAccordionTab(group)} />
								</div>

								{isEditGroupActive && (
									<div className="accordion__form accordion__form--group">
										<AppGroupEditForm initialValues={group} onHandleDismiss={() => resetTabs()} onHandleConfirm={onHandleGroupConfirmUpdate} />
									</div>
								)}

								{isGroupActive && (
									<div className="accordion__categories">
										{categories.map((category, j) => {
											const categoryId = category.categoryId;
											const categoryStatus = category.categoryStatus;
											const isCategoryActive = categoryTab === categoryId;
											const isEditCategoryActive = categoryEditTab === categoryId;

											return (
												<Fragment key={j}>
													{/* prettier-ignore */}
													<AppGroupTab type="category" status={categoryStatus} title={`•  ${category.categoryTitle}`} isActive={isCategoryActive} onHandleEdit={onHandleAccordionEditTab(category)} onHandleToggle={getVariations(category)} />

													<div className="accordion__form accordion__form--category">
														{isEditCategoryActive && <AppCategoryForm type="category" initialValues={category} onHandleDismiss={() => resetTabs("category")} onHandleConfirm={onHandleCategoryConfirmUpdate} />}
													</div>

													{isCategoryActive && (
														<div className="accordion__variation">
															{variation?.map?.((variant, k) => {
																const variantStatus = variant.variantStatus;
																const isEditVariantActive = variantEditTab === variant.variantId;
																return (
																	<Fragment key={k}>
																		<AppGroupTab type="variation" status={variantStatus} title={`-  ${variant.variantTitle}`} onHandleEdit={onHandleAccordionEditTab(variant)} />
																		<div className="accordion__form accordion__form--category">
																			{isEditVariantActive && (
																				<AppCategoryForm type="category" initialValues={variant} onHandleDismiss={() => resetTabs("variation")} onHandleConfirm={onHandleVariationConfirmUpdate} />
																			)}
																		</div>
																	</Fragment>
																);
															})}
															{/* prettier-ignore */}
															<CreateButton type="variation" label={t("newSubCategoryCta")} active={isCreateVariationOpen} onHandleClick={onHandleVisibleCreateVariation(true)} onHandleDismiss={() => resetTabs("variation")} onHandleConfirm={onHandleVariationConfirmUpdate} />
														</div>
													)}
												</Fragment>
											);
										})}
										{/* prettier-ignore */}
										<CreateButton type="category" label={t("newCategoryCta")} active={isCreateCategoryOpen} onHandleClick={onHandleVisibleCreateCategory(true)} onHandleDismiss={() => resetTabs("category")} onHandleConfirm={onHandleCategoryConfirmUpdate} />
									</div>
								)}
							</div>
						);
					})}
				</div>
			</div>
			{!isEmpty && <AppPagination total={groups?.totalPage} page={groups?.page} onChangePagination={onChangePagination} />}
			<AppGroupCreateModal ref={groupCreateModalRef} onHandleConfirm={onHandleGroupConfirmCreate} />
			<AppGroupStatusModal ref={groupStatusModalRef} onHandleConfirm={onHandleUpdateGroupStatus} />
		</div>
	);
};

export default PageGroups;
