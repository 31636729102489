import React, { useEffect, useRef, useState, useMemo } from "react";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IoSearchOutline, IoAddOutline } from "react-icons/io5";

import api from "services/api";
import pathnames from "routes/pathnames";
import CONSTANSTS from "common/constansts";
import AppEmptyData from "components/app-empty-data";
import AppTable, { TableStatus, TableEditButton } from "components/app-table";
import { onHandleRequestError, onHandleGetIndex } from "common/utilities";

const PageBanners = (props) => {
	const { t } = useTranslation("banners");
	const profile = useSelector((state) => state.auth.profile);
	const navigate = useNavigate();
	const [banners, setBanners] = useState(null);
	const filterBy = useRef({ length: 10, page: 0, order: "desc", sortBy: null, status: null, bannerTitle: null });
	const isEmpty = !banners?.bannerResponseList?.length || !banners;
	const isEmptySearch = !banners?.bannerResponseList?.length && filterBy.current.bannerTitle;
	const isSuperAdmin = CONSTANSTS.ROLE.SUPER_ADMIN === profile?.role;

	useEffect(() => {
		getBanners();
	}, []);

	useEffect(() => {
		return () => props.onHandleCancelRequest();
	}, [props]);

	const getBanners = async () => {
		let response = null;

		try {
			const payload = filterBy.current;
			response = await api.get.banners(payload);
		} catch (error) {
			onHandleRequestError(error);
		}

		if (response) {
			setBanners(response.data.result);
		}
	};

	const columns = useMemo(
		() => [
			{
				Header: "No",
				id: "index",
				accessor: (_row, i) => onHandleGetIndex(i, filterBy.current.page),
			},
			{
				Header: t("table.name"),
				width: "75%",
				accessor: "title",
				setFilter: (value) => {
					filterBy.current.page = 0;
					filterBy.current.order = value;
					filterBy.current.sortBy = "title";
					getBanners();
				},
			},
			{
				Header: t("table.status"),
				accessor: "bannerStatus",
				filterOptions: CONSTANSTS.FILTER_BY.STATUS,
				Cell: ({ cell: { value } }) => <TableStatus status={value} />,
				setFilter: (value) => {
					filterBy.current.page = 0;
					filterBy.current.status = value;
					getBanners();
				},
			},
			{
				Header: "",
				accessor: "t-action",
				Cell: ({ row }) => {
					const banner = row.original;
					return (
						<TableEditButton
							onClick={() => {
								navigate(pathnames.banner, { state: { ...banner, mode: CONSTANSTS.MODE.EDIT } });
							}}
						/>
					);
				},
			},
		],
		[t, navigate]
	);

	const onChangePagination = (event) => {
		filterBy.current.page = event.target.value;
		getBanners();
	};

	const onNavigateCreateBanner = () => {
		navigate(pathnames.banner, { state: { mode: CONSTANSTS.MODE.CREATE } });
	};

	const onSearchBanner = (event) => {
		const value = event.target.value;
		filterBy.current = { length: 10, page: 0, status: null, bannerTitle: value };
		getBanners();
	};

	const onHandleSearchBanner = debounce(onSearchBanner, 2000);

	return (
		<div className="page-banners">
			<div className="banners">
				<h1 className="banners__title">{t("title")}</h1>

				<div className="banners__header">
					<p className="banners__description">{t("showing", { total: banners?.bannerResponseList?.length })} </p>

					<div className="banners__search">
						<IoSearchOutline />
						<input size="22" name="search" placeholder={t("search")} onChange={onHandleSearchBanner} />
						{isSuperAdmin && (
							<button className="banners__new-cta" onClick={onNavigateCreateBanner}>
								<IoAddOutline className="banners__icon" />
								<p className="banners__label">{t("newCta")}</p>
							</button>
						)}
					</div>
				</div>

				<div className="banners__body">
					{(isEmpty || isEmptySearch) && <AppEmptyData data={banners?.bannerResponseList} search={isEmptySearch} />}
					{!isEmpty && <AppTable columns={columns} data={banners.bannerResponseList} total={banners.totalPage} page={banners.page} onChangePagination={onChangePagination} />}
				</div>
			</div>
		</div>
	);
};

export default PageBanners;
