import React, { useState, useCallback, memo } from "react";
import { Input as Field } from "reactstrap";
import { IoIosEye, IoIosEyeOff } from "react-icons/io";

const AppInput = ({ note, touched, error, icon, type, ...props }) => {
	const [passwordVisible, setPasswordVisible] = useState(false);
	const disabled = props.disabled;
	const isPasswordType = type === "password";
	const inputType = isPasswordType && passwordVisible ? "text" : type;
	const invalid = touched && error;

	//prettier-ignore
	const onHandleBlur = useCallback((event) => {
		event.currentTarget.value = event.currentTarget.value.trim();
		props.onChange(event);
	}, [props]);

	const onHandleTogglePasswordVisible = useCallback(() => {
		setPasswordVisible(!passwordVisible);
	}, [passwordVisible]);

	return (
		<div className="app-input">
			<div className={getWrapperClassNames({ invalid, disabled })}>
				{icon && <div className="app-input__icon">{icon}</div>}

				<Field {...props} className="app-input__input" type={inputType} onBlur={onHandleBlur} autoComplete="off" />

				{isPasswordType && (
					<div className="app-input__toggle-icon" onClick={onHandleTogglePasswordVisible}>
						{passwordVisible ? <IoIosEyeOff /> : <IoIosEye />}
					</div>
				)}
			</div>

			{invalid && <p className="app-input__text app-input__text--error">{error}</p>}

			{note && <p className="app-input__text">{note}</p>}
		</div>
	);
};

const getWrapperClassNames = ({ invalid, disabled }) => {
	const classNames = ["app-input__container"];

	if (disabled) classNames.push("app-input__container--disabled");

	if (invalid) classNames.push("app-input__container--error");

	return classNames.join(" ");
};

export default memo(AppInput);
