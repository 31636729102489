import React, { memo } from "react";
import { IoCloseOutline } from "react-icons/io5";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

const AppLightBoxImage = ({ isOpen, source = "", onHandleDismiss }) => {
	const isObject = source instanceof Object;
	let imageURL = null;

	if (isObject && source?.size) {
		imageURL = URL.createObjectURL(source);
	} else if (isObject && source?.name) {
		imageURL = source?.name;
	} else {
		imageURL = source;
	}

	return (
		<Modal className="app-lightbox-image" contentClassName="lightbox-image" centered isOpen={isOpen} toggle={onHandleDismiss}>
			<ModalHeader
				className="lightbox-image__header"
				close={
					<button type="button" className="lightbox-image__close-cta" onClick={onHandleDismiss}>
						<IoCloseOutline className="lightbox-image__close-icon" />
					</button>
				}
				toggle={onHandleDismiss}
			></ModalHeader>
			<ModalBody className="lightbox-image__body">
				<img src={imageURL} alt="profile" />
			</ModalBody>
		</Modal>
	);
};

export default memo(AppLightBoxImage);
