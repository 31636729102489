import React, { useMemo } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import api from "services/api";
import CONSTANSTS from "common/constansts";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import { updateProfile } from "redux/slices/auth-slice";
import { onHandleRequestError, onHandleRequestSuccess } from "common/utilities";

const getTabItemClassNames = ({ activeTab }) => {
	const classNames = ["helpdesk__item"];

	if (activeTab) classNames.push("helpdesk__item--active");

	return classNames.join(" ");
};

const PageHelpdesk = () => {
	const { t } = useTranslation("helpdesk");
	const dispatch = useDispatch();
	const { profile } = useSelector((state) => state.auth);
	//prettier-ignore
	const validationSchema = useMemo(() => Yup.object().shape({
		email: Yup.string().email().required(t("common:required")),
	}), [t]);
	const initialValues = useMemo(() => {
		const values = {
			email: profile.helpdeskEmail || "",
		};
		return values;
	}, [profile]);
	const formik = useFormik({
		enableReinitialize: true,
		initialValues,
		validationSchema,
		onSubmit: (values) => {
			onHandleUpdateProfile(values);
		},
	});

	const onHandleUpdateProfile = async (values) => {
		let response = null;

		try {
			const payload = { helpdeskEmail: values.email, superAdminId: profile.superAdminId };
			response = await api.post.updateHelpdesk(payload);
		} catch (error) {
			onHandleRequestError(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			dispatch(updateProfile({ ...profile, helpdeskEmail: values.email }));
			onHandleRequestSuccess(t("editSuccess"));
		}
	};

	const onHandleResetForm = () => {
		formik.resetForm({ values: initialValues });
	};

	return (
		<div className="page-helpdesk">
			<div className="helpdesk">
				<ul className="helpdesk__list">
					{CONSTANSTS.TABS.SETTINGS.map((item, i) => {
						const activeTab = i === 1;
						return (
							<li key={i} className={getTabItemClassNames({ activeTab })}>
								<Link to={item.path}>{item.title}</Link>
							</li>
						);
					})}
				</ul>

				<h1 className="helpdesk__title">{t("title")}</h1>
				<p className="helpdesk__description">{t("description")}</p>

				<form autoComplete="off" onSubmit={formik.handleSubmit}>
					<div className="helpdesk__body">
						<div className="helpdesk__content">
							<div className="helpdesk__field">
								<p className="helpdesk__label">{t("field.0.label")}</p>
								<AppInput type="text" name="email" placeholder={t("field.0.placeholder")} value={formik.values.email} error={formik.errors.email} touched={formik.touched.email} onChange={formik.handleChange} />
							</div>
						</div>
					</div>
					<div className="helpdesk__button-container">
						<div className="helpdesk__button-wrapper">
							<AppButton label={t("cancel")} type="reset" cancel onClick={onHandleResetForm} />
							<AppButton label={t("update")} type="submit" loading={formik.isSubmitting} />
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default PageHelpdesk;
