import React, { memo, useRef, useCallback, useMemo } from "react";
import { compose } from "redux";
import { useTranslation } from "react-i18next";

import CONSTANSTS from "common/constansts";
import withLightboxModal from "contexts/with-lightbox-modal";

const AppUploadInput = ({ onChange, accept, name, value, touched, error, uploadType, placeholder, disabled, ...props }) => {
	const { t } = useTranslation("components");
	const uploadRef = useRef();
	const invalid = touched && error;
	const uploadLabel = useMemo(() => {
		let label = "";

		switch (uploadType) {
			case CONSTANSTS.UPLOAD.PDF:
				if (!value) {
					label = t("uploadInput.pdfUpload");
				} else {
					label = t("uploadInput.pdfRemove");
				}
				break;
			case CONSTANSTS.UPLOAD.IMAGE:
				if (!value) {
					label = t("uploadInput.pictureUpload");
				} else {
					label = t("uploadInput.pictureRemove");
				}
				break;
			default:
				break;
		}

		return label;
	}, [t, uploadType, value]);

	// prettier-ignore
	const onHandleSetImage = useCallback((event) => {
        const file = event.currentTarget.files[0];
        if (file) onChange(name, file);
		uploadRef.current.value = "";
    }, [name, onChange]);

	const onHandleUploadImage = useCallback(() => {
		if (value) {
			const isResponseImage = !value.size;

			if (isResponseImage) {
				if (uploadType === CONSTANSTS.UPLOAD.PDF) onChange("removePdf", value.name);
				if (uploadType === CONSTANSTS.UPLOAD.IMAGE) onChange("removeImage", value.name);
			}

			return onChange(name, "");
		}
		uploadRef.current.click();
	}, [value, name, uploadType, onChange]);

	const onHandleDisplayFile = (value) => {
		if (uploadType === CONSTANSTS.UPLOAD.IMAGE) props.onHandleOpenLightbox(value);
		if (uploadType === CONSTANSTS.UPLOAD.PDF) props.onHandleDownloadPDF(value);
	};

	return (
		<div className="app-upload-input">
			<div className="upload-input">
				<input hidden type="file" name="file" ref={uploadRef} accept={accept} onChange={onHandleSetImage} />

				<div className="upload-input__container">
					<div className="upload-input__content">
						{value && (
							<button type="button" className="upload-input__cta" onClick={() => onHandleDisplayFile(value)}>
								<p className="upload-input__text upload-input__text--name">{value.name}</p>
							</button>
						)}

						{disabled && !value && <p className="upload-input__text">N/A</p>}

						{!disabled && (
							<button type="button" className={getUploadCtaClassNames({ hasFile: value })} onClick={onHandleUploadImage}>
								<p className="upload-input__text">{uploadLabel}</p>
							</button>
						)}

						{!disabled && !value && <p className="upload-input__text upload-input__text--note">{placeholder}</p>}
					</div>

					{invalid && <p className="upload-input__text upload-input__text--error">{error}</p>}
				</div>
			</div>
		</div>
	);
};

const getUploadCtaClassNames = ({ hasFile }) => {
	const classNames = ["upload-input__cta"];

	if (hasFile) classNames.push("upload-input__cta--remove");

	return classNames.join(" ");
};

export default compose(memo, withLightboxModal)(AppUploadInput);
