import React, { useRef, useMemo, useCallback } from "react";
import * as Yup from "yup";
import { compose } from "redux";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import api from "services/api";
import CONSTANSTS from "common/constansts";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import asianCountries from "common/asian-countries";
import AppRadioInput from "components/app-radio-input";
import AppMobileInput from "components/app-mobile-input";
import withLightboxModal from "contexts/with-lightbox-modal";
import AppPasswordForm from "components/pages/page-settings/app-password-form";
import { onHandleRequestError, onHandleRequestSuccess } from "common/utilities";
import { updateProfile } from "redux/slices/auth-slice";

const getTabItemClassNames = ({ activeTab }) => {
	const classNames = ["settings__item"];

	if (activeTab) classNames.push("settings__item--active");

	return classNames.join(" ");
};

const PageSettings = (props) => {
	const { t } = useTranslation("settings");
	const uploadImageRef = useRef();
	const dispatch = useDispatch();
	const { profile } = useSelector((state) => state.auth);
	const isAdmin = CONSTANSTS.ROLE.ADMIN === profile?.role;
	const isSuperAdmin = CONSTANSTS.ROLE.SUPER_ADMIN === profile?.role;
	//prettier-ignore
	const validationSchema = useMemo(() => Yup.object().shape({
		image: Yup.mixed()
			.test(CONSTANSTS.UPLOAD.ERRORS[0].type, CONSTANSTS.UPLOAD.ERRORS[0].error, (value) => (value ? value?.size <= CONSTANSTS.UPLOAD.SIZES_LIMIT : true))
			.test(CONSTANSTS.UPLOAD.ERRORS[1].type, CONSTANSTS.UPLOAD.ERRORS[1].error, (value) => (value ? CONSTANSTS.UPLOAD.IMAGES_TYPE.includes(value?.type) : true)),
		name: Yup.string().required(t("common:required")),
		jobRole: Yup.string().required(t("common:required")),
		phoneNumber: Yup.string().required(t("common:required")),
		storeName: Yup.string().when("role", { is: (value) => value === CONSTANSTS.ROLE.ADMIN, then: Yup.string().required(t("common:required")) }),
	}), [t]);
	const initialValues = useMemo(() => {
		const values = {
			adminId: "",
			image: "",
			name: "",
			role: "",
			email: "",
			jobRole: "",
			phoneNumber: "",
			dialCode: asianCountries[0],
			storeName: "",
		};

		if (profile) {
			if (profile.adminId) values.adminId = profile.adminId;
			if (profile.superAdminId) values.adminId = profile.superAdminId;
			if (profile.name) values.name = profile.name;
			if (profile.role) values.role = profile.role;
			if (profile.emailAddress) values.email = profile.emailAddress;
			if (profile.jobRole) values.jobRole = profile.jobRole;
			if (profile.phoneNumber) values.phoneNumber = profile.phoneNumber;
			if (profile.dialCode) values.dialCode = asianCountries.filter((o) => o.dialCode === profile.dialCode)[0];
			if (profile.storeName) values.storeName = profile.storeName;
		}

		return values;
	}, [profile]);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues,
		validationSchema,
		onSubmit: (values) => {
			onHandleUpdateProfile(values);
		},
	});

	//prettier-ignore
	const profileImage = useMemo(() => (formik.values.image ? URL.createObjectURL(formik.values.image) : profile?.profilePic), [profile, formik.values.image]);

	const onHandleUpdateProfile = async (values) => {
		let response = null;

		try {
			const formData = new FormData();
			formData.append("name", values.name);
			formData.append("jobRole", values.jobRole);
			formData.append("phoneNumber", values.phoneNumber);
			formData.append("dialCode", values.dialCode.dialCode);

			if (values.image) formData.append("profilePic", values.image);

			if (isAdmin) {
				formData.append("adminId", values.adminId);
				formData.append("storeName", values.storeName);
				response = await api.post.updateProfile(formData);
			}
			if (isSuperAdmin) {
				response = await api.post.updateSuperProfile(formData);
			}
		} catch (error) {
			onHandleRequestError(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			let profile = null;
			const adminProfile = response.data.result.admin;
			const superProfile = response.data.result.superAdmin;

			if (adminProfile) profile = adminProfile;

			if (superProfile) profile = superProfile;

			if (profile) dispatch(updateProfile(profile));

			onHandleRequestSuccess(t("editSuccess"));
		}
	};

	const form = useMemo(() => {
		const fields = [
			{
				name: "adminId",
				label: t("field.0.label"),
				placeholder: t("field.0.placeholder"),
				disabled: true,
				type: "text",
			},
			{
				name: "name",
				label: t("field.1.label"),
				placeholder: t("field.1.placeholder"),
				disabled: false,
				type: "text",
			},
			{
				name: "email",
				label: t("field.2.label"),
				placeholder: t("field.2.placeholder"),
				disabled: true,
				type: "text",
			},
			{
				name: "jobRole",
				label: t("field.3.label"),
				placeholder: t("field.3.placeholder"),
				disabled: false,
				type: "text",
			},
			{
				name: "phoneNumber",
				label: t("field.4.label"),
				placeholder: t("field.4.placeholder"),
				disabled: false,
				type: "mobile",
			},
		];

		if (isAdmin) {
			const storeField = {
				name: "storeName",
				label: t("field.5.label"),
				placeholder: t("field.5.placeholder"),
				disabled: false,
				type: "text",
			};
			fields.push(storeField);
		}

		return fields;
	}, [t, isAdmin]);

	//prettier-ignore
	const onHandleSetImage = useCallback((event) => {
        const file =  event.currentTarget.files[0];

        if(file) formik.setFieldValue("image", file);
        
    }, [formik]);

	const onHandleToggleProfileActions = () => {
		document.querySelector(".settings__actions").classList.toggle("settings__actions--active");
	};

	const onHandleUploadImage = () => {
		uploadImageRef.current.click();
	};

	const onHandleResetForm = () => {
		formik.resetForm({ values: initialValues });
	};

	return (
		<div className="page-settings">
			<div className="settings">
				{isSuperAdmin && (
					<ul className="settings__list">
						{CONSTANSTS.TABS.SETTINGS.map((item, i) => {
							const activeTab = i === 0;

							return (
								<li key={i} className={getTabItemClassNames({ activeTab })}>
									<Link to={item.path}>{item.title}</Link>
								</li>
							);
						})}
					</ul>
				)}

				<h1 className="settings__title">{t("title")}</h1>

				<form autoComplete="off" onSubmit={formik.handleSubmit}>
					<div className="settings__body">
						<div className="settings__profile">
							<input hidden type="file" name="image" ref={uploadImageRef} accept="image/png, image/jpeg" onChange={onHandleSetImage} />
							{/* prettier-ignore */}
							<div tabIndex="0" className="settings__profile-image" onFocus={onHandleToggleProfileActions} onBlur={onHandleToggleProfileActions} style={{ backgroundImage: `url(${profileImage})` }}>
                                <ul className="settings__actions">
                                    <li className="settings__action-item" onClick={() => props.onHandleOpenLightbox(profileImage)}>
                                        {t("uploadCta.view")}
                                    </li>
                                    <li className="settings__action-item" onClick={onHandleUploadImage}>
										{t("uploadCta.update")}
                                    </li>
                                </ul>
                            </div>
							<p className="settings__name">{profile?.name}</p>
						</div>

						<p className="settings_error">{formik.errors.image}</p>

						<div className="settings__content">
							{form.map(({ label, ...res }, i) => {
								const value = formik.values[res.name];
								const error = formik.errors[res.name];
								const touched = formik.touched[res.name];
								const isRadioField = res.type === "radio";
								const isMobileField = res.type === "mobile";

								if (isMobileField) {
									return (
										<div key={i} className="settings__field">
											<p className="settings__label">{label}</p>
											<AppMobileInput
												{...res}
												value={value}
												error={error}
												touched={touched}
												onChange={formik.handleChange}
												dialCode={formik.values.dialCode}
												onSelectCountryCode={(value) => formik.setFieldValue("dialCode", value)}
											/>
										</div>
									);
								}

								if (isRadioField) {
									return (
										<div key={i} className="settings__field">
											<p className="settings__label">{label}</p>
											<AppRadioInput {...res} value={value} onChange={formik.setFieldValue} />
										</div>
									);
								}

								return (
									<div key={i} className="settings__field">
										<p className="settings__label">{label}</p>
										<AppInput {...res} value={value} error={error} touched={touched} onChange={formik.handleChange} />
									</div>
								);
							})}
						</div>
					</div>
					<div className="settings__button-container">
						<div className="settings__button-wrapper">
							<AppButton label={t("cancel")} type="reset" cancel onClick={onHandleResetForm} />
							<AppButton label={t("update")} type="submit" loading={formik.isSubmitting} />
						</div>
					</div>
				</form>

				<AppPasswordForm />
			</div>
		</div>
	);
};

export default compose(withLightboxModal)(PageSettings);
