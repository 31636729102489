import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	profile: null,
	notification: null,
};

export const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		updateProfile: (state, action) => {
			return {
				...state,
				profile: action.payload,
			};
		},
		updateNotification: (state, action) => {
			return {
				...state,
				notification: action.payload,
			};
		},
		resetProfile: () => initialState,
	},
});

export const { updateProfile, updateNotification, resetProfile } = authSlice.actions;

export default authSlice.reducer;
