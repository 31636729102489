import CONSTANSTS from "common/constansts";
const debug = CONSTANSTS.IS_DEVELOPMENT_MODE;

export const registerServiceWorker = () => {
	if ("serviceWorker" in navigator) {
		navigator.serviceWorker
			.register("../../firebase-messaging-sw.js")
			.then(function (registration) {
				if (debug) console.log("[SW]: SCOPE: ", registration.scope);
				return registration.scope;
			})
			.catch(function (err) {
				if (debug) console.log("[SW]: Error: ", err);
				return err;
			});
	}
};
