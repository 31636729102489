import React, { useMemo, useEffect, useCallback, useState, useRef } from "react";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IoSearchOutline, IoAddOutline } from "react-icons/io5";

import api from "services/api";
import pathnames from "routes/pathnames";
import CONSTANSTS from "common/constansts";
import AppEmptyData from "components/app-empty-data";
import AppTable, { TableStatus, TableEditButton } from "components/app-table";
import { onHandleRequestError, onHandleGetIndex } from "common/utilities";

const PageUsers = (props) => {
	const { t } = useTranslation("users");
	const navigate = useNavigate();
	const filterBy = useRef({ length: 10, page: 0, order: "desc", sortBy: null, valueEntered: null, status: null });
	const [users, setUsers] = useState(null);
	const isEmpty = !users?.adminDTOS?.length || !users;
	const isEmptySearch = !users?.adminDTOS?.length && filterBy.current.valueEntered;

	useEffect(() => {
		getUsers();
	}, []);

	useEffect(() => {
		return () => props.onHandleCancelRequest();
	}, [props]);

	const getUsers = async () => {
		let response = null;

		try {
			const payload = filterBy.current;
			response = await api.get.users(payload);
		} catch (error) {
			onHandleRequestError(error);
		}

		if (response) {
			setUsers(response.data.result);
		}
	};

	const onHandleSortTable = useCallback((order, key) => {
		filterBy.current.order = 0;
		filterBy.current.order = order;
		filterBy.current.sortBy = key;
		getUsers();
	}, []);

	const columns = useMemo(
		() => [
			{
				Header: "No",
				id: "index",
				accessor: (_row, i) => onHandleGetIndex(i, filterBy.current.page),
			},
			{
				Header: t("table.id"),
				accessor: "adminId",
				setFilter: (value) => {
					onHandleSortTable(value, "adminId");
				},
			},
			{
				Header: t("table.name"),
				accessor: "name",
				setFilter: (value) => {
					onHandleSortTable(value, "name");
				},
			},
			{
				Header: t("table.email"),
				accessor: "emailAddress",
				setFilter: (value) => {
					onHandleSortTable(value, "emailAddress");
				},
			},
			{
				Header: t("table.phoneNumber"),
				accessor: "phoneNumber",
				Cell: ({ cell: { value, row } }) => `+${row.original.dialCode} ${value}`,
			},
			{
				Header: t("table.store"),
				accessor: "storeName",
				setFilter: (value) => {
					onHandleSortTable(value, "storeName");
				},
			},
			{
				Header: t("table.status"),
				accessor: "status",
				filterOptions: CONSTANSTS.FILTER_BY.STATUS,
				Cell: ({ cell: { value } }) => <TableStatus status={value} />,
				setFilter: (value) => {
					filterBy.current.page = 0;
					filterBy.current.status = value;
					getUsers();
				},
			},
			{
				Header: "",
				accessor: "t-action",
				Cell: ({ row }) => {
					const user = row.original;
					return (
						<TableEditButton
							onClick={() => {
								navigate(pathnames.user, { state: { ...user, mode: CONSTANSTS.MODE.EDIT } });
							}}
						/>
					);
				},
			},
		],
		[t, navigate, onHandleSortTable]
	);

	const onChangePagination = (event) => {
		filterBy.current.page = event.target.value;
		getUsers();
	};

	const onNavigateCreateAdmin = () => {
		navigate(pathnames.user, { state: { mode: CONSTANSTS.MODE.CREATE } });
	};

	const onSearchUser = (event) => {
		const value = event.target.value;
		filterBy.current = { length: 10, page: 0, order: "desc", sortBy: null, valueEntered: value, status: null };
		getUsers();
	};

	const onHandleSearchUser = debounce(onSearchUser, 2000);

	return (
		<div className="page-users">
			<div className="users">
				<h1 className="users__title">{t("title")}</h1>

				<div className="users__header">
					<p className="users__description">{t("showing", { total: users?.adminDTOS?.length })}</p>

					<div className="users__search">
						<IoSearchOutline />
						<input size="32" name="search" placeholder={t("search")} onChange={onHandleSearchUser} />
						<button className="users__new-cta" onClick={onNavigateCreateAdmin}>
							<IoAddOutline className="users__icon" />
							<p className="users__label">{t("newCta")}</p>
						</button>
					</div>
				</div>

				<div className="users__body">
					{(isEmpty || isEmptySearch) && <AppEmptyData data={users?.adminDTOS} search={isEmptySearch} />}
					{!isEmpty && <AppTable columns={columns} data={users.adminDTOS} total={users.totalPage} page={users.page} onChangePagination={onChangePagination} />}
				</div>
			</div>
		</div>
	);
};

export default PageUsers;
