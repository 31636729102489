import React, { memo, useImperativeHandle, useMemo, useState, useEffect, useCallback, forwardRef } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import api from "services/api";
import CONSTANSTS from "common/constansts";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppDropdown from "components/app-dropdown";
import AppDateInput from "components/app-date-input";
import { onHandleRequestError } from "common/utilities";
import { ReactComponent as EyeExaminationIcon } from "assets/images/pages/page-customers/eye-examination-icon.svg";

const getLabelClassNames = ({ important }) => {
	const classNames = ["eye-examination-modal__label"];

	if (important) classNames.push("eye-examination-modal__label--important");

	return classNames.join(" ");
};

const initialValues = {
	outlet: "",
	examinationDate: "",
	rx: "",
	appointment: "",
	examinationBy: "",
};

const AppEyeExaminationCreateModal = forwardRef(({ onHandleConfirm }, ref) => {
	const { t } = useTranslation("components");
	const [visible, setVisible] = useState(false);
	const [outlets, setOutlets] = useState([]);
	//prettier-ignore
	const validationSchema = useMemo(() => Yup.object().shape({
		outlet: Yup.object().required(t("common:required")),
		examinationDate: Yup.date().required(t("common:required")),
	}), [t]);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues,
		validationSchema,
		onSubmit: (values, arg) => {
			arg.resetForm();
			onHandleDismiss();
			onHandleConfirm(values, enableSubmitButton);
		},
	});

	const enableSubmitButton = useCallback(() => {
		formik.setSubmitting(false);
	}, [formik]);

	useEffect(() => {
		getOutlets();
	}, []);

	const getOutlets = async () => {
		const payload = { length: 50, page: 0, status: CONSTANSTS.STATUS.ACTIVE, order: "desc" };

		let response = null;

		try {
			response = await api.get.outlets(payload);
		} catch (error) {
			onHandleRequestError(error);
		}

		if (response) {
			const nextOutlets = response.data.result.storeResponseList;
			const dropdownOutlets = nextOutlets.map((o) => ({ label: o?.storeCode + " " + o.storeName, value: o.storeId }));
			setOutlets(dropdownOutlets);
		}
	};

	const form = useMemo(() => {
		const fields = [
			{
				name: "outlet",
				label: t("eyeExaminationForm.field.0.label"),
				placeholder: t("eyeExaminationForm.field.0.placeholder"),
				important: true,
				options: outlets,
				disabled: false,
				type: "select",
			},
			{
				name: "examinationDate",
				label: t("eyeExaminationForm.field.1.label"),
				placeholder: t("eyeExaminationForm.field.1.placeholder"),
				important: true,
				maxDate: new Date(),
				disabled: false,
				type: "date",
			},
			{
				name: "rx",
				label: t("eyeExaminationForm.field.2.label"),
				placeholder: t("eyeExaminationForm.field.2.placeholder"),
				disabled: false,
				type: "text",
			},
			{
				name: "appointment",
				label: t("eyeExaminationForm.field.3.label"),
				placeholder: t("eyeExaminationForm.field.3.placeholder"),
				options: CONSTANSTS.OPTIONS.APPOINTMENTS,
				disabled: false,
				type: "select",
			},
			{
				name: "examinationBy",
				label: t("eyeExaminationForm.field.4.label"),
				placeholder: t("eyeExaminationForm.field.4.placeholder"),
				disabled: false,
				type: "text",
			},
		];
		return fields;
	}, [t, outlets]);

	useImperativeHandle(ref, () => ({
		onHandleDismiss: onHandleDismiss,
		onHandleShow: onHandleShow,
	}));

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	const onHandleShow = useCallback(() => {
		setVisible(true);
	}, []);

	return (
		<Modal className="app-eye-examination-create-modal" contentClassName="eye-examination-modal" centered size="lg" isOpen={visible} toggle={onHandleDismiss}>
			<form autoComplete="off" onSubmit={formik.handleSubmit}>
				<ModalHeader className="eye-examination-modal__header" toggle={onHandleDismiss}></ModalHeader>
				<ModalBody className="eye-examination-modal__body">
					<div className="eye-examination-modal__body-header">
						<EyeExaminationIcon className="eye-examination-modal__icon" />
						<h1 className="eye-examination-modal__title">{t("eyeExaminationForm.title")}</h1>
					</div>

					{form.map(({ label, important, ...res }, i) => {
						const value = formik.values[res.name];
						const error = formik.errors[res.name];
						const touched = formik.touched[res.name];
						const isDateField = res.type === "date";
						const isDropdownField = res.type === "select";

						if (isDropdownField) {
							return (
								<div key={i} className="eye-examination-modal__field">
									<p className={getLabelClassNames({ important })}>{label}</p>
									<AppDropdown {...res} value={value} error={error} touched={touched} data={res.options} onChange={formik.setFieldValue} />
								</div>
							);
						}

						if (isDateField) {
							return (
								<div key={i} className="eye-examination-modal__field">
									<p className={getLabelClassNames({ important })}>{label}</p>
									<AppDateInput {...res} value={value} error={error} touched={touched} onChange={formik.setFieldValue} />
								</div>
							);
						}

						return (
							<div key={i} className="eye-examination-modal__field">
								<p className="eye-examination-modal__label">{label}</p>
								<AppInput {...res} value={value} error={error} touched={touched} onChange={formik.handleChange} />
							</div>
						);
					})}
				</ModalBody>
				<ModalFooter className="eye-examination-modal__footer">
					<AppButton type="button" label={t("eyeExaminationForm.cancel")} cancel onClick={onHandleDismiss} />
					<AppButton type="submit" label={t("eyeExaminationForm.create")} loading={formik.isSubmitting} />
				</ModalFooter>
			</form>
		</Modal>
	);
});

export default memo(AppEyeExaminationCreateModal);
