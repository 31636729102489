import { memo, createContext, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";

import pathnames from "routes/pathnames";
import CONSTANSTS from "common/constansts";
import useIsMount from "hooks/use-is-mount";
import { sanitizeError } from "common/utilities";
import AppBlockedModal from "components/app-blocked-modal";
import { updateErrorStatus, reinitializeStatus } from "redux/slices/empty-state-slice";

const sharedStyles = "margin: 5px 0; padding: 8px; border-radius: 20px; color: #FFFFFF;";
const initStyles = `${sharedStyles} background: #673AB7;`;
const reqDataStyles = `${sharedStyles} background: #F8EA8C;`;
const reqParamsStyles = `${sharedStyles} background: #ff9514;`;
const respSuccessStyles = `${sharedStyles} background: #32ac97;`;
const errorStyles = `${sharedStyles} background: #F79489;`;
const debug = CONSTANSTS.IS_DEVELOPMENT_MODE;

const interceptorDebug = (title, styles, url = "", data = "") => {
	if (!debug) return;

	console.log(`%c ${title}`, styles);
	console.log(url);
	console.log(data);
	console.log("");
};

export const AxiosContext = createContext();

const cancelToken = axios.CancelToken;
let requestInterceptor = null;
let responseInterceptor = null;
let axiosCancel = null;

const InterceptorProvider = ({ children }) => {
	const blockedModalRef = useRef();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const isMount = useIsMount();

	const onHandleForceLogout = useCallback(() => {
		try {
			const logoutAction = { type: "logout" };
			dispatch(logoutAction);
			localStorage.clear();

			navigate(pathnames.signIn);
		} catch (error) {
			console.log(error);
		}
	}, [navigate, dispatch]);

	const onHandleCancelRequest = () => {
		if (axiosCancel) axiosCancel("Cancelled Request 👋");
	};

	const setAxiosCancel = (cancel) => {
		axiosCancel = cancel;
	};

	//prettier-ignore
	const setEmptyStateError = useCallback((error) => {
		const requestCancelled = axios.isCancel(error);

		if (requestCancelled) return;

		dispatch(updateErrorStatus());
	}, [dispatch]);

	useEffect(() => {
		if (isMount) {
			interceptorDebug("Init Axios Interceptor! 🎉", initStyles);

			//prettier-ignore
			requestInterceptor = axios.interceptors.request.use((config) => {
					switch(config.method) {
						case "get":
							interceptorDebug(`GET REQUESTING 🚀 ${config.url}`, reqParamsStyles, config.params);
							break;
						default:
							interceptorDebug(`POST REQUESTING 🚀 ${config.url}`, reqDataStyles, config.data);
							break;
					}
					
					dispatch(reinitializeStatus());
					
					config.cancelToken = new cancelToken(setAxiosCancel);
										
					return config;
				},
				(error) => {
					interceptorDebug("REQUESTING ERROR 👎", errorStyles, sanitizeError(error));

					setEmptyStateError(error);
					
					return Promise.reject(error);
				}
			);

			//prettier-ignore
			responseInterceptor = axios.interceptors.response.use((response) => {
					interceptorDebug(`RESPONSE SUCCESS: 🌟 ${response.config.url}`, respSuccessStyles, response.data);
					return response;
				},
				async (error) => {
					interceptorDebug("RESPONSE ERROR 🥲", errorStyles, sanitizeError(error));

					setEmptyStateError(error);

					const statusCode = error?.response?.status;
					
					switch (statusCode) {
						case 403:
							onHandleForceLogout();
							return Promise.reject(error);
						case 999:
							onHandleForceLogout();
							blockedModalRef.current.onHandleShow();
							return Promise.reject(error);
						default:
							return Promise.reject(error);
					}
				}
			);
		}
	}, [isMount, dispatch, setEmptyStateError, onHandleForceLogout]);

	useEffect(() => {
		return () => {
			interceptorDebug("Ejected Axios Interceptor! 👋", initStyles);
			axios.interceptors.request.eject(requestInterceptor);
			axios.interceptors.response.eject(responseInterceptor);
		};
	}, []);

	return (
		<AxiosContext.Provider value={{ onHandleCancelRequest }}>
			<AppBlockedModal ref={blockedModalRef} />
			{children}
		</AxiosContext.Provider>
	);
};

export default memo(InterceptorProvider);
