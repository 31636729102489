import React, { memo, useMemo, useImperativeHandle, useState, useEffect, useCallback, forwardRef } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import api from "services/api";
import CONSTANSTS from "common/constansts";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppDropdown from "components/app-dropdown";
import AppDateInput from "components/app-date-input";
import { onHandleRequestError } from "common/utilities";
import { ReactComponent as PurchaseHistoryIcon } from "assets/images/pages/page-customers/purchase-history-icon.svg";

const getLabelClassNames = ({ important }) => {
	const classNames = ["purchase-history-modal__label"];

	if (important) classNames.push("purchase-history-modal__label--important");

	return classNames.join(" ");
};

const initialValues = {
	outlet: "",
	purchaseDate: "",
	numberOfPurchase: "",
	prescriptionRange: "",
	index: "",
	lensPrescribed: "",
	lensAccessories: "",
	lensType: "",
	clPrescribed: "",
	clType: "",
	clMaterial: "",
};

const AppPurchaseHistoryCreateModal = forwardRef(({ onHandleConfirm }, ref) => {
	const { t } = useTranslation("components");
	const [outlets, setOutlets] = useState([]);
	const [visible, setVisible] = useState(false);

	//prettier-ignore
	const validationSchema = useMemo(() => Yup.object().shape({
		outlet: Yup.object().required(t("common:required")),
		purchaseDate: Yup.date().required(t("common:required")),
	}), [t]);
	const formik = useFormik({
		enableReinitialize: true,
		initialValues,
		validationSchema,
		onSubmit: (values, arg) => {
			arg.resetForm();
			onHandleDismiss();
			onHandleConfirm(values, enableSubmitButton);
		},
	});

	const enableSubmitButton = useCallback(() => {
		formik.setSubmitting(false);
	}, [formik]);

	useEffect(() => {
		getOutlets();
	}, []);

	const getOutlets = async () => {
		const payload = { length: 50, page: 0, status: CONSTANSTS.STATUS.ACTIVE, order: "desc" };

		let response = null;

		try {
			response = await api.get.outlets(payload);
		} catch (error) {
			onHandleRequestError(error);
		}

		if (response) {
			const nextOutlets = response.data.result.storeResponseList;
			const dropdownOutlets = nextOutlets.map((o) => ({ label: o?.storeCode + " " + o.storeName, value: o.storeId }));
			setOutlets(dropdownOutlets);
		}
	};

	const form = useMemo(() => {
		const fields = [
			{
				name: "outlet",
				label: t("purchaseHistoryForm.field.0.label"),
				placeholder: t("purchaseHistoryForm.field.0.placeholder"),
				important: true,
				options: outlets,
				disabled: false,
				type: "select",
			},
			{
				name: "purchaseDate",
				label: t("purchaseHistoryForm.field.1.label"),
				placeholder: t("purchaseHistoryForm.field.1.placeholder"),
				important: true,
				maxDate: new Date(),
				disabled: false,
				type: "date",
			},
			{
				name: "numberOfPurchase",
				label: t("purchaseHistoryForm.field.2.label"),
				placeholder: t("purchaseHistoryForm.field.2.placeholder"),
				disabled: false,
				type: "number",
			},
			{
				name: "prescriptionRange",
				label: t("purchaseHistoryForm.field.3.label"),
				placeholder: t("purchaseHistoryForm.field.3.placeholder"),
				options: CONSTANSTS.OPTIONS.PRESCRIPTION_RANGE,
				disabled: false,
				type: "select",
			},
			{
				name: "index",
				label: t("purchaseHistoryForm.field.4.label"),
				placeholder: t("purchaseHistoryForm.field.4.placeholder"),
				options: CONSTANSTS.OPTIONS.INDEX,
				disabled: false,
				type: "select",
			},
			{
				name: "lensPrescribed",
				label: t("purchaseHistoryForm.field.5.label"),
				placeholder: t("purchaseHistoryForm.field.5.placeholder"),
				disabled: false,
				type: "text",
			},
			{
				name: "lensAccessories",
				label: t("purchaseHistoryForm.field.6.label"),
				placeholder: t("purchaseHistoryForm.field.6.placeholder"),
				options: CONSTANSTS.OPTIONS.LENS_ACCESSORIES,
				disabled: false,
				type: "select",
			},
			{
				name: "lensType",
				label: t("purchaseHistoryForm.field.7.label"),
				placeholder: t("purchaseHistoryForm.field.7.placeholder"),
				options: CONSTANSTS.OPTIONS.LENS_TYPE,
				disabled: false,
				type: "select",
			},
			{
				name: "clPrescribed",
				label: t("purchaseHistoryForm.field.8.label"),
				placeholder: t("purchaseHistoryForm.field.8.placeholder"),
				disabled: false,
				type: "text",
			},
			{
				name: "clType",
				label: t("purchaseHistoryForm.field.9.label"),
				placeholder: t("purchaseHistoryForm.field.9.placeholder"),
				options: CONSTANSTS.OPTIONS.CL_TYPE,
				disabled: false,
				type: "select",
			},
			{
				name: "clMaterial",
				label: t("purchaseHistoryForm.field.10.label"),
				placeholder: t("purchaseHistoryForm.field.10.placeholder"),
				options: CONSTANSTS.OPTIONS.CL_MATERIAL,
				disabled: false,
				type: "select",
			},
		];
		return fields;
	}, [t, outlets]);

	useImperativeHandle(ref, () => ({
		onHandleDismiss: onHandleDismiss,
		onHandleShow: onHandleShow,
	}));

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	const onHandleShow = useCallback(() => {
		setVisible(true);
	}, []);

	return (
		<Modal className="app-purchase-history-create-modal" contentClassName="purchase-history-modal" centered size="lg" isOpen={visible} toggle={onHandleDismiss}>
			<form autoComplete="off" onSubmit={formik.handleSubmit}>
				<ModalHeader className="purchase-history-modal__header" toggle={onHandleDismiss}></ModalHeader>
				<ModalBody className="purchase-history-modal__body">
					<div className="purchase-history-modal__body-header">
						<PurchaseHistoryIcon className="purchase-history-modal__icon" />
						<h1 className="purchase-history-modal__title">{t("purchaseHistoryForm.title")}</h1>
					</div>

					{form.map(({ label, important, ...res }, i) => {
						const value = formik.values[res.name];
						const error = formik.errors[res.name];
						const touched = formik.touched[res.name];
						const isDateField = res.type === "date";
						const isDropdownField = res.type === "select";

						if (isDropdownField) {
							return (
								<div key={i} className="purchase-history-modal__field">
									<p className={getLabelClassNames({ important })}>{label}</p>
									<AppDropdown {...res} value={value} error={error} touched={touched} data={res.options} onChange={formik.setFieldValue} />
								</div>
							);
						}

						if (isDateField) {
							return (
								<div key={i} className="purchase-history-modal__field">
									<p className={getLabelClassNames({ important })}>{label}</p>
									<AppDateInput {...res} value={value} error={error} touched={touched} onChange={formik.setFieldValue} />
								</div>
							);
						}

						return (
							<div key={i} className="purchase-history-modal__field">
								<p className="purchase-history-modal__label">{label}</p>
								<AppInput {...res} value={value} error={error} touched={touched} onChange={formik.handleChange} />
							</div>
						);
					})}
				</ModalBody>
				<ModalFooter className="purchase-history-modal__footer">
					<AppButton type="button" label={t("purchaseHistoryForm.cancel")} cancel onClick={onHandleDismiss} />
					<AppButton type="submit" label={t("purchaseHistoryForm.create")} loading={formik.isSubmitting} />
				</ModalFooter>
			</form>
		</Modal>
	);
});

export default memo(AppPurchaseHistoryCreateModal);
