import axios from "services/axios";
import CONSTANSTS from "common/constansts";

const api = {
	get: {
		profile: () => axios.get(CONSTANSTS.ENDPOINT_PATH.PROFILE),
		users: (payload) => axios.get(CONSTANSTS.ENDPOINT_PATH.USERS, payload),
		outlets: (payload) => axios.get(CONSTANSTS.ENDPOINT_PATH.OUTLETS, payload),
		faqs: () => axios.get(CONSTANSTS.ENDPOINT_PATH.FAQS),
		banners: (payload) => axios.get(CONSTANSTS.ENDPOINT_PATH.BANNERS, payload),
		rewards: (payload) => axios.get(CONSTANSTS.ENDPOINT_PATH.REWARDS, payload),
		customers: (payload) => axios.get(CONSTANSTS.ENDPOINT_PATH.CUSTOMERS, payload),
		customer: (payload) => axios.get(`${CONSTANSTS.ENDPOINT_PATH.CUSTOMER}${payload}`),
		customerPurchaseHistory: (payload) => axios.get(CONSTANSTS.ENDPOINT_PATH.CUSTOMER_PURCHASE_HISTORY, payload),
		customerEyeExaminationHistory: (payload) => axios.get(CONSTANSTS.ENDPOINT_PATH.CUSTOMER_EYE_EXAMINATION_HISTORY, payload),
		customerPointsLog: (payload) => axios.get(CONSTANSTS.ENDPOINT_PATH.CUSTOMER_POINTSLOG, payload),
		customerVouchers: (payload) => axios.get(CONSTANSTS.ENDPOINT_PATH.CUSTOMER_VOUCHERS, payload),
		groups: (payload) => axios.get(CONSTANSTS.ENDPOINT_PATH.GROUPS, payload),
		categoriesAndVariation: (payload) => axios.get(`${CONSTANSTS.ENDPOINT_PATH.CATEGORIES_VARIATION}${payload.groupId}`, payload),
		categories: (payload) => axios.get(CONSTANSTS.ENDPOINT_PATH.CATEGORIES, payload),
		variations: (payload) => axios.get(CONSTANSTS.ENDPOINT_PATH.VARIATION, payload),
		products: (payload) => axios.get(CONSTANSTS.ENDPOINT_PATH.PRODUCTS, payload),
		announcements: (payload) => axios.get(CONSTANSTS.ENDPOINT_PATH.ANNOUNCEMENTS, payload),
		notifications: (payload) => axios.get(CONSTANSTS.ENDPOINT_PATH.NOTIFICATIONS, payload),
		notificationAlert: () => axios.get(CONSTANSTS.ENDPOINT_PATH.NOTIFICATION_ALERT),
	},
	post: {
		signIn: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.SIGN_IN, payload),
		forgetPassword: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.FORGET_PASSWORD, payload),
		verifyUserOtp: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.VERIFY_USER_OTP, payload),
		resetPassword: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.RESET_PASSWORD, payload),
		updateProfile: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.UPDATE_PROFILE, payload),
		updateCustomer: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.UPDATE_CUSTOMER, payload),
		createCustomerPurchaseHistory: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.CREATE_CUSTOMER_PURCHASE_HISTORY, payload),
		updateCustomerPurchaseHistory: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.UPDATE_CUSTOMER_PURCHASE_HISTORY, payload),
		createCustomerEyeExamination: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.CREATE_CUSTOMER_EYE_EXAMINATION_HISTORY, payload),
		updateCustomerEyeExamination: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.UPDATE_CUSTOMER_EYE_EXAMINATION_HISTORY, payload),
		updateCustomerPoints: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.UPDATE_CUSTOMER_POINTS, payload),
		createAdmin: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.CREATE_ADMIN, payload),
		updateAdminProfile: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.UPDATE_ADMIN_PROFILE, payload),
		updateSuperProfile: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.UPDATE_SUPER_PROFILE, payload),
		resetSuperPassword: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.RESET_SUPER_PASSWORD, payload),
		updateHelpdesk: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.UPDATE_HELPDESK, payload),
		createOutlet: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.CREATE_OUTLET, payload),
		updateOutlet: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.UPDATE_OUTLET, payload),
		createFaq: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.CREATE_FAQ, payload),
		updateFaq: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.UPDATE_FAQ, payload),
		removeFaq: (payload) => axios.post(`${CONSTANSTS.ENDPOINT_PATH.REMOVE_FAQ}${payload}`),
		createBanner: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.CREATE_BANNER, payload),
		updateBanner: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.UPDATE_BANNER, payload),
		createReward: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.CREATE_REWARD, payload),
		updateReward: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.UPDATE_REWARD, payload),
		createGroup: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.CREATE_GROUP, payload),
		updateGroup: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.UPDATE_GROUP, payload),
		createCategory: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.CREATE_CATEGORY, payload),
		updateCategory: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.UPDATE_CATEGORY, payload),
		createVariant: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.CREATE_VARIANT, payload),
		updateVariant: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.UPDATE_VARIANT, payload),
		createAnnouncement: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.CREATE_ANNOUNCEMENT, payload),
		updateAnnouncement: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.UPDATE_ANNOUNCEMENT, payload),
		createProduct: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.CREATE_PRODUCT, payload),
		updateProduct: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.UPDATE_PRODUCT, payload),
		pushNotification: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.PUSH_NOTIFICATIONS, payload),
		removePushNotification: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.REMOVE_PUSH_NOTIFICATIONS, payload),
	},
	patch: {
		readNotification: (id) => axios.patch(CONSTANSTS.ENDPOINT_PATH.NOTIFICATION_EVENT.replace("{notificationId}", id)),
		updateGroupStatus: (payload) => axios.patch(`${CONSTANSTS.ENDPOINT_PATH.UPDATE_GROUP_STATUS}${payload.status}`.replace("{groupId}", payload.groupId)),
	},
};

export default api;
