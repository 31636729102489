import React, { memo, useImperativeHandle, forwardRef, useRef, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import AppButton from "components/app-button";
import { ReactComponent as FaqIcon } from "assets/images/pages/page-faqs/faq-icon.svg";

const AppFaqRemoveModal = forwardRef(({ onHandleConfirm }, ref) => {
	const { t } = useTranslation("components");
	const faqId = useRef();
	const [visible, setVisible] = useState(false);

	useImperativeHandle(ref, () => ({
		onHandleDismiss: onHandleDismiss,
		onHandleShow: onHandleShow,
	}));

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	const onHandleShow = useCallback((id) => {
		faqId.current = id;
		setVisible(true);
	}, []);

	const onHandleSubmit = useCallback(() => {
		onHandleDismiss();
		onHandleConfirm(faqId.current);
	}, [onHandleDismiss, onHandleConfirm]);

	return (
		<Modal size="lg" className="app-faq-remove-modal" contentClassName="faq-remove-modal" centered isOpen={visible} toggle={onHandleDismiss}>
			<ModalHeader className="faq-remove-modal__header" toggle={onHandleDismiss}></ModalHeader>
			<ModalBody className="faq-remove-modal__body">
				<FaqIcon className="faq-remove-modal__icon" />

				<h1 className="faq-remove-modal__title">{t("faqRemoveModal.title")}</h1>
				<p className="faq-remove-modal__description">{t("faqRemoveModal.description")}</p>
			</ModalBody>
			<ModalFooter className="faq-remove-modal__footer">
				<AppButton type="button" label={t("faqRemoveModal.cancel")} cancel onClick={onHandleDismiss} />
				<AppButton type="button" label={t("faqRemoveModal.cta")} onClick={onHandleSubmit} />
			</ModalFooter>
		</Modal>
	);
});

export default memo(AppFaqRemoveModal);
