import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	active: false,
	error: false,
	message: "",
};

export const alertSlice = createSlice({
	name: "alert",
	initialState,
	reducers: {
		updateAlert: (state, action) => {
			return {
				...state,
				active: true,
				error: action.payload.error,
				message: action.payload.message,
			};
		},
		resetAlert: () => initialState,
	},
});

export const { updateAlert, resetAlert } = alertSlice.actions;

export default alertSlice.reducer;
