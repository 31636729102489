import React, { useRef, useEffect, useCallback, useState, useMemo } from "react";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IoSearchOutline, IoAddOutline } from "react-icons/io5";

import api from "services/api";
import pathnames from "routes/pathnames";
import CONSTANSTS from "common/constansts";
import AppEmptyData from "components/app-empty-data";
import AppTable, { TableStatus, TableEditButton } from "components/app-table";
import { onHandleRequestError, onHandleGetIndex } from "common/utilities";

const PageLoyaltyPrograms = (props) => {
	const { t } = useTranslation("loyaltyPrograms");
	const profile = useSelector((state) => state.auth.profile);
	const navigate = useNavigate();
	const [rewards, setRewards] = useState(null);
	const filterBy = useRef({ length: 10, page: 0, order: "desc", sortBy: null, status: null, valueEntered: null });
	const isEmpty = !rewards?.rewards?.length || !rewards;
	const isEmptySearch = !rewards?.rewards?.length && filterBy.current.valueEntered;
	const isSuperAdmin = CONSTANSTS.ROLE.SUPER_ADMIN === profile?.role;

	useEffect(() => {
		getRewards();
	}, []);

	useEffect(() => {
		return () => props.onHandleCancelRequest();
	}, [props]);

	const getRewards = async () => {
		let response = null;

		try {
			const payload = filterBy.current;
			response = await api.get.rewards(payload);
		} catch (error) {
			onHandleRequestError(error);
		}

		if (response) {
			setRewards(response.data.result);
		}
	};

	const onHandleSortTable = useCallback((order, key) => {
		filterBy.current.order = 0;
		filterBy.current.order = order;
		filterBy.current.sortBy = key;
		getRewards();
	}, []);

	//prettier-ignore
	const columns = useMemo(() => [
		{
			Header: "No",
			id: "index",
			accessor: (_row, i) => onHandleGetIndex(i, filterBy.current.page),
		},
		{
			Header: t("table.code"),
			accessor: "rewardsCode",
			setFilter: (value) => {
				onHandleSortTable(value, "rewardsCode");
			},
		},
		{
			Header: t("table.name"),
			accessor: "rewardsName",
			setFilter: (value) => {
				onHandleSortTable(value, "rewardsName");
			},
		},
		{
			Header: t("table.createdDate"),
			accessor: "createdAt",
			setFilter: (value) => {
				onHandleSortTable(value, "createdAt");
			},
		},
		{
			Header: t("table.redemptionPoint"),
			accessor: "redeemPoints",
			setFilter: (value) => {
				onHandleSortTable(value, "redeemPoints");
			},
		},
		{
			Header: t("table.status"),
			accessor: "status",
			filterOptions: CONSTANSTS.FILTER_BY.STATUS,
			Cell: ({ cell: { value } }) => <TableStatus status={value}/>,
			setFilter: (value) => {
				filterBy.current.page = 0;
				filterBy.current.status = value;
				getRewards();
			},
		},
		{
			Header: "",
			accessor: "t-action",
			Cell: ({ row }) => {
				const rewards = row.original;
				return (
					<TableEditButton
						onClick={() => {
							navigate(pathnames.loyaltyProgram, { state: { ...rewards, mode: CONSTANSTS.MODE.EDIT } });
						}}
					/>
				);
			},
		},
	], [t, navigate, onHandleSortTable]);

	const onNavigateCreateReward = () => {
		navigate(pathnames.loyaltyProgram, { state: { mode: CONSTANSTS.MODE.CREATE } });
	};

	const onChangePagination = (event) => {
		filterBy.current.page = event.target.value;
		getRewards();
	};

	const onSearchReward = (event) => {
		const value = event.target.value;
		filterBy.current = { length: 10, page: 0, order: "desc", sortBy: null, status: null, valueEntered: value };
		getRewards();
	};

	const onHandleSearchReward = debounce(onSearchReward, 2000);

	return (
		<div className="page-loyalty-programs">
			<div className="loyalty-programs">
				<h1 className="loyalty-programs__title">{t("title")}</h1>

				<div className="loyalty-programs__header">
					<p className="loyalty-programs__description">{t("showing", { total: rewards?.rewards?.length })}</p>

					<div className="loyalty-programs__search">
						<IoSearchOutline />
						<input size="32" name="search" placeholder={t("search")} onChange={onHandleSearchReward} />
						{isSuperAdmin && (
							<button className="loyalty-programs__new-cta" onClick={onNavigateCreateReward}>
								<IoAddOutline className="loyalty-programs__icon" />
								<p className="loyalty-programs__label">{t("newCta")}</p>
							</button>
						)}
					</div>
				</div>

				<div className="loyalty-programs__body">
					{(isEmpty || isEmptySearch) && <AppEmptyData data={rewards?.rewards} search={isEmptySearch} />}
					{!isEmpty && <AppTable columns={columns} data={rewards.rewards} total={rewards.totalPage} page={rewards.page} onChangePagination={onChangePagination} />}
				</div>
			</div>
		</div>
	);
};

export default PageLoyaltyPrograms;
