import React, { useMemo, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { IoIosMail, IoIosLock } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";

import api from "services/api";
import pathnames from "routes/pathnames";
import CONSTANSTS from "common/constansts";
import useIsMount from "hooks/use-is-mount";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import { updateProfile } from "redux/slices/auth-slice";
import { trimStrings } from "common/utilities";
import logo from "assets/images/menara-logo.svg";
import heroImage from "assets/images/hero-image.svg";

const initialValues = {
	email: process.env.REACT_APP_LOGIN_EMAIL,
	password: process.env.REACT_APP_LOGIN_PASSWORD,
};

const PageSignIn = () => {
	const isMount = useIsMount();
	const { t } = useTranslation("signIn");
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { profile } = useSelector((state) => state.auth);
	//prettier-ignore
	const validationSchema = useMemo(() => Yup.object().shape({
		email: Yup.string().required(t("common:required")).email(t("common:required")),
		password: Yup.string().required(t("common:required")),
	}), [t]);
	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: () => {
			onHandleSignIn();
		},
	});

	useEffect(() => {
		if (isMount && profile) {
			navigate(pathnames.customers);
		}
	}, [isMount, navigate, profile]);

	const onHandleSignIn = async () => {
		let response = null;

		try {
			const payload = trimStrings(formik.values);
			response = await api.post.signIn(payload);
		} catch (error) {
			formik.setFieldError("email", t("error"));
			formik.setFieldError("password", t("error"));
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			let profile = null;
			const token = response.data.result.token;
			const adminProfile = response.data.result.admin;
			const superProfile = response.data.result.superAdmin;

			if (adminProfile) profile = adminProfile;

			if (superProfile) profile = superProfile;

			localStorage.setItem(CONSTANSTS.LOCAL_STORAGE.STORAGE_TOKEN, token);

			if (profile) dispatch(updateProfile(profile));
			navigate(pathnames.customers);
		}
	};

	return (
		<div className="app-page page-sign-in">
			<div className="sign-in">
				<div className="sign-in__container">
					<div className="sign-in__hero-image">
						<img src={heroImage} alt="" />
					</div>
				</div>
				<div className="sign-in__container">
					<div className="sign-in__logo">
						<Link to={pathnames.signIn}>
							<img src={logo} alt="" />
						</Link>
					</div>
					<h1 className="sign-in__title">{t("title")}</h1>
					<p className="sign-in__sub-title">{t("subTitle")}</p>

					<form autoComplete="off" onSubmit={formik.handleSubmit}>
						<AppInput type="text" name="email" placeholder={t("field.0.placeholder")} icon={<IoIosMail />} error={formik.errors.email} touched={formik.touched.email} onChange={formik.handleChange} />
						<AppInput type="password" name="password" placeholder={t("field.1.placeholder")} icon={<IoIosLock />} error={formik.errors.password} touched={formik.touched.password} onChange={formik.handleChange} />

						<Link className="sign-in__link" to={pathnames.forgotPassword}>
							{t("forgotPassword")}
						</Link>

						<AppButton type="submit" label={t("cta")} loading={formik.isSubmitting} />
					</form>
				</div>
			</div>
		</div>
	);
};

export default PageSignIn;
