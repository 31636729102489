import React, { useMemo, useState, useRef, useEffect, useCallback } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { IoAddOutline } from "react-icons/io5";
import { Link, useParams, useNavigate } from "react-router-dom";

import api from "services/api";
import pathnames from "routes/pathnames";
import CONSTANSTS from "common/constansts";
import useIsMount from "hooks/use-is-mount";
import AppEmptyData from "components/app-empty-data";
import AppBreadCrumb from "components/app-bread-crumb";
import AppTable, { TableToggleButton, onDimissSelectedTable } from "components/app-table";
import AppEyeExaminationForm from "components/pages/page-customers/app-eye-examination-form";
import AppEyeExaminationCreateModal from "components/pages/page-customers/app-eye-examination-create-modal";
import { onHandleRequestError, onHandleRequestSuccess, onHandleGetIndex } from "common/utilities";

const getTabItemClassNames = ({ activeTab }) => {
	const classNames = ["eye-examination__item"];

	if (activeTab) classNames.push("eye-examination__item--active");

	return classNames.join(" ");
};

const PageEyeExamination = (props) => {
	const { t } = useTranslation("eyeExamination");
	const params = useParams();
	const isMount = useIsMount();
	const navigate = useNavigate();
	const eyeExaminationCreateModalRef = useRef();
	const [eyeExaminationDetails, setEyeExaminationDetails] = useState(null);
	const [eyeExaminationHistory, setEyeExaminationHistory] = useState(null);
	const isEmpty = !eyeExaminationHistory?.eyeExaminationHistoryList?.length || !eyeExaminationHistory;
	const filterBy = useRef({ length: 10, page: 0, order: "desc", sortBy: null, customerId: params.customerId });
	//prettier-ignore
	const breadCrumbData = useMemo(() => ({
		default: [{ label: t("breadCrumb.0.label"), path: pathnames.customers }, { label: t("breadCrumb.1.label") }],
	}), [t]);
	useEffect(() => {
		return () => props.onHandleCancelRequest();
	}, [props]);

	const getEyeExaminationHistory = useCallback(async () => {
		let response = null;

		try {
			const payload = filterBy.current;
			response = await api.get.customerEyeExaminationHistory(payload);
		} catch (error) {
			onHandleRequestError(error);
			navigate(pathnames.customers);
		}

		if (response) {
			setEyeExaminationHistory(response.data.result);
		}
	}, [navigate]);

	useEffect(() => {
		if (isMount) getEyeExaminationHistory();
	}, [isMount, getEyeExaminationHistory]);

	//prettier-ignore
	const onHandleSortTable = useCallback((order, key) => {
		filterBy.current.page = 0;
		filterBy.current.order = order;
		filterBy.current.sortBy = key;
		getEyeExaminationHistory();
	}, [getEyeExaminationHistory]);

	//prettier-ignore
	const columns = useMemo(() => [
		{
			Header: "No",
			id: "index",
			accessor: (_row, i) => onHandleGetIndex(i, filterBy.current.page),
		},
		{
			Header: t("table.examinationDate"),
			accessor: "examinationDate",
			Cell: ({ cell: { value } }) => moment(value).format(CONSTANSTS.DATE_FORMAT.DDMMYYYY),
			setFilter: (value) => {
				onHandleSortTable(value, "examinationDate");
			},
		},
		{
			Header: t("table.outlet"),
			accessor: "outletName",
			setFilter: (value) => {
				onHandleSortTable(value, "outletName");
			},
		},
		{
			Header: "",
			accessor: "t-action",
			Cell: ({ row }) => {
				const customerEyeExamination = row.original;
				return <TableToggleButton onClick={() => { setEyeExaminationDetails(customerEyeExamination) }} />;
			},
		},
	], [t, onHandleSortTable]);

	const onChangePagination = (event) => {
		filterBy.current.page = event.target.value;
		getEyeExaminationHistory();
	};

	const getTabPath = (path) => {
		const nextPath = path + params.customerId;
		return nextPath;
	};

	const onHandleUpdateEyeExamination = async (values, callback) => {
		const isEditMode = eyeExaminationDetails?.eyeExaminationHistoryId;
		const isCreateMode = !isEditMode;
		let response = null;

		try {
			const payload = {
				customerId: params.customerId,
				outletId: values.outlet.value,
				examinationBy: values.examinationBy,
				examinationDate: values.examinationDate.toISOString(),
				rx: values.rx,
			};

			if (values.appointment) payload.caseAppointment = values.appointment.value;

			if (isEditMode) {
				payload.eyeExamHistoryId = eyeExaminationDetails.eyeExaminationHistoryId;
				response = await api.post.updateCustomerEyeExamination(payload);
			}

			if (isCreateMode) response = await api.post.createCustomerEyeExamination(payload);
		} catch (error) {
			onHandleRequestError(error);
		} finally {
			callback();
		}

		if (response) {
			if (isEditMode) onHandleRequestSuccess(t("editSuccess"));

			if (isCreateMode) {
				onHandleRequestSuccess(t("createSuccess"));
			}

			getEyeExaminationHistory();
		}
	};

	const onHandleDismissUpdateEyeExamination = () => {
		onDimissSelectedTable();
		setEyeExaminationDetails(null);
	};

	//prettier-ignore
	const onHandleVisibleCreateModal = useCallback(() => {
		onHandleDismissUpdateEyeExamination(null);
		eyeExaminationCreateModalRef.current?.onHandleShow()
	}, []);

	return (
		<div className="page-eye-examination">
			<div className="eye-examination">
				<AppBreadCrumb data={breadCrumbData} />

				<ul className="eye-examination__list">
					{CONSTANSTS.TABS.CUSTOMERS.map((item, i) => {
						const activeTab = i === 2;

						return (
							<li key={i} className={getTabItemClassNames({ activeTab })}>
								<Link to={getTabPath(item.path)}>{item.title}</Link>
							</li>
						);
					})}
				</ul>

				<h1 className="eye-examination__title">{t("title")}</h1>

				<div className="eye-examination__header">
					<p className="eye-examination__description">{t("showing", { total: eyeExaminationHistory?.eyeExaminationHistoryList?.length })}</p>
					<button className="eye-examination__new-cta" onClick={onHandleVisibleCreateModal}>
						<IoAddOutline className="eye-examination__icon" />
						<p className="eye-examination__label">{t("newCta")}</p>
					</button>
				</div>

				<div className="eye-examination__body">
					{isEmpty && <AppEmptyData data={eyeExaminationHistory?.eyeExaminationHistoryList} />}
					{!isEmpty && <AppTable columns={columns} data={eyeExaminationHistory.eyeExaminationHistoryList} total={eyeExaminationHistory?.totalPage} onChangePagination={onChangePagination} />}
				</div>

				{eyeExaminationDetails && (
					<div className="eye-examination__footer">
						<h1 className="eye-examination__title">{t("formTitle")}</h1>
						<AppEyeExaminationForm eyeExamination={eyeExaminationDetails} onHandleDismiss={onHandleDismissUpdateEyeExamination} onHandleConfirm={onHandleUpdateEyeExamination} />
					</div>
				)}
				<AppEyeExaminationCreateModal ref={eyeExaminationCreateModalRef} onHandleConfirm={onHandleUpdateEyeExamination} />
			</div>
		</div>
	);
};

export default PageEyeExamination;
