import pathnames from "routes/pathnames";
import CONSTANSTS from "common/constansts";
import { ReactComponent as customerIcon } from "assets/images/components/app-side-nav/customer-icon.svg";
import { ReactComponent as groupsIcon } from "assets/images/components/app-side-nav/groups.svg";
import { ReactComponent as loyaltyProgramIcon } from "assets/images/components/app-side-nav/loyalty-program-icon.svg";
import { ReactComponent as productsIcon } from "assets/images/components/app-side-nav/products-icon.svg";
import { ReactComponent as outletsIcon } from "assets/images/components/app-side-nav/outlets-icon.svg";
import { ReactComponent as bannersIcon } from "assets/images/components/app-side-nav/banner-icon.svg";
import { ReactComponent as announcementsIcon } from "assets/images/components/app-side-nav/announcements-icon.svg";
import { ReactComponent as faqsIcon } from "assets/images/components/app-side-nav/faqs-icon.svg";
import { ReactComponent as settingsIcon } from "assets/images/components/app-side-nav/settings-icon.svg";

const SIDE_NAV_DATA = [
	{
		label: "Customers",
		to: pathnames.customers,
		icon: customerIcon,
		restrict: null,
		relations: [pathnames.customer, pathnames.eyeExamination, pathnames.purchaseTransaction, pathnames.pointsHistory, pathnames.vouchers],
		childrens: null,
	},
	{
		label: "Loyalty Program",
		to: pathnames.loyaltyPrograms,
		icon: loyaltyProgramIcon,
		restrict: null,
		relations: [pathnames.loyaltyProgram],
		childrens: null,
	},
	{
		label: "Group & Category",
		to: pathnames.groups,
		icon: groupsIcon,
		restrict: CONSTANSTS.ROLE.SUPER_ADMIN,
		relations: [pathnames.categories],
		childrens: null,
	},
	{
		label: "Products",
		to: pathnames.products,
		icon: productsIcon,
		restrict: null,
		relations: [pathnames.product],
		childrens: null,
	},
	{
		label: "Banner",
		to: pathnames.banners,
		icon: bannersIcon,
		restrict: null,
		relations: [pathnames.banner],
		childrens: null,
	},
	{
		label: "Outlet",
		to: pathnames.outlets,
		icon: outletsIcon,
		restrict: null,
		relations: [pathnames.outlets],
		childrens: null,
	},
	{
		label: "FAQ",
		to: pathnames.faqs,
		icon: faqsIcon,
		restrict: null,
		relations: [],
		childrens: null,
	},
	{
		label: "Announcement",
		to: pathnames.announcements,
		icon: announcementsIcon,
		restrict: CONSTANSTS.ROLE.SUPER_ADMIN,
		relations: [pathnames.announcement],
		childrens: null,
	},
	{
		label: "Settings",
		to: null,
		icon: settingsIcon,
		restrict: null,
		relations: [],
		childrens: [
			{
				label: "Profile & General",
				to: pathnames.settings,
				restrict: null,
				relations: [pathnames.settings],
			},
			{
				label: "Admin List",
				to: pathnames.users,
				restrict: CONSTANSTS.ROLE.SUPER_ADMIN,
				relations: [pathnames.users, pathnames.user],
			},
		],
	},
];

export default SIDE_NAV_DATA;
