import React, { Fragment, memo, useEffect, useRef, useCallback } from "react";
import { compose } from "redux";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import CONSTANSTS from "common/constansts";
import useIsMount from "hooks/use-is-mount";
import AppLogoutModal from "components/app-logout-modal";
import withNotifications from "contexts/with-notification";
import AppNotificationModal from "components/app-notification-modal";
import bellIcon from "assets/images/components/app-nav-header/bell-icon.svg";
import logoutIcon from "assets/images/components/app-nav-header/logout-icon.svg";

const getLanguagueClassName = ({ active }) => {
	const classNames = [""];

	if (active) classNames.push("nav-header__language-active");

	return classNames.join(" ");
};

const AppNavHeader = ({ title, ...props }) => {
	const { i18n } = useTranslation();
	const notificationRef = useRef();
	const logoutRef = useRef();
	const isMount = useIsMount();
	const profile = useSelector((state) => state.auth.profile);
	const profileImageStyle = { backgroundImage: `url(${profile?.profilePic})` };
	const isBm = i18n.language === "bm";
	const isEn = i18n.language === "en";

	useEffect(() => {
		if (isMount) props.onRequestNotificationPermission();
	}, [isMount, props]);

	//prettier-ignore
	const onHandleVisibleLogoutModal = useCallback(() => {
		logoutRef.current?.onHandleShow();
	}, []);

	//prettier-ignore
	const onHandleChangeLanguage = useCallback((lng) => () => {
		localStorage.setItem(CONSTANSTS.LOCAL_STORAGE.USER_LANGUAGE, lng);
		i18n.changeLanguage(lng);
	}, [i18n]);

	return (
		<Fragment>
			<nav className="app-nav-header">
				<div className="nav-header">
					<h1 className="nav-header__title">{title}</h1>
					<ul className="nav-header__list">
						<li className="nav-header__item">
							<div className="nav-header__profile" style={profileImageStyle} />
							<p className="nav-header__name">{profile?.name}</p>
						</li>
						<li className="nav-header__item">
							<div className="nav-header__actions">
								<button className="nav-header__notification" onClick={notificationRef.current?.onHandleShow}>
									{props.notificationAlert && <div className="nav-header__alert" />}
									<img src={bellIcon} alt="notification" />
								</button>
								<button onClick={onHandleVisibleLogoutModal}>
									<img src={logoutIcon} alt="logout" />
								</button>
							</div>
						</li>
						<li className="nav-header__item">
							<div className="nav-header__switch-language">
								<p className="nav-header__languages">
									<span className={getLanguagueClassName({ active: isBm })} onClick={onHandleChangeLanguage("bm")}>
										BM
									</span>
									<span>/</span>
									<span className={getLanguagueClassName({ active: isEn })} onClick={onHandleChangeLanguage("en")}>
										EN
									</span>
								</p>
							</div>
						</li>
					</ul>
				</div>
			</nav>
			<AppNotificationModal ref={notificationRef} />
			<AppLogoutModal ref={logoutRef} />
		</Fragment>
	);
};

export default compose(memo, withNotifications)(AppNavHeader);
