import React, { useMemo, useState, useRef, useEffect, useCallback } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Link, useParams, useNavigate } from "react-router-dom";

import api from "services/api";
import pathnames from "routes/pathnames";
import CONSTANSTS from "common/constansts";
import useIsMount from "hooks/use-is-mount";
import { onHandleRequestError } from "common/utilities";
import AppEmptyData from "components/app-empty-data";
import AppBreadCrumb from "components/app-bread-crumb";
import AppTable, { TablePoints } from "components/app-table";

const getTabItemClassNames = ({ activeTab }) => {
	const classNames = ["points-history__item"];

	if (activeTab) classNames.push("points-history__item--active");

	return classNames.join(" ");
};

const PagePointsHistory = (props) => {
	const params = useParams();
	const isMount = useIsMount();
	const navigate = useNavigate();
	const { t } = useTranslation("pointsHistory");
	const [pointsLog, setPointsLog] = useState(null);
	const isEmpty = !pointsLog?.pointsLog?.length || !pointsLog;
	const filterBy = useRef({ length: 10, page: 0, customerId: params.customerId });
	//prettier-ignore
	const breadCrumbData = useMemo(() => ({
		default: [{ label: t("breadCrumb.0.label"), path: pathnames.customers }, { label: t("breadCrumb.1.label") }],
	}), [t]);
	const getPointsLog = useCallback(async () => {
		let response = null;

		try {
			const payload = filterBy.current;
			response = await api.get.customerPointsLog(payload);
		} catch (error) {
			onHandleRequestError(error);
			navigate(pathnames.customers);
		}

		if (response) {
			setPointsLog(response.data.result);
		}
	}, [navigate]);

	useEffect(() => {
		if (isMount) getPointsLog();
	}, [isMount, getPointsLog]);

	useEffect(() => {
		return () => props.onHandleCancelRequest();
	}, [props]);

	useEffect(() => {
		if (!isEmpty && pointsLog) {
			const totalPoinstText = document.querySelector(".points-history__text");

			if (totalPoinstText) {
				totalPoinstText.innerText = t("table.totalPoints", { points: pointsLog.customerPoints });
			} else {
				const table = document.getElementById("fn-app-table");
				const totalPoints = document.createElement("p");
				totalPoints.classList = "points-history__text";
				totalPoints.innerText = t("table.totalPoints", { points: pointsLog.customerPoints });
				table.children[0].after(totalPoints);
			}
		}
	}, [t, isEmpty, pointsLog]);

	//prettier-ignore
	const columns = useMemo(() => [
		{
			Header: t("table.date"),
			accessor: "createdAt",
			Cell: ({ cell: { value } }) => moment(value).format(CONSTANSTS.DATE_FORMAT.YYYYMMDDHH_MMA),
		},
		{
			Header: t("table.transaction"),
			accessor: "redeemAction",
			Cell: ({ cell: { value } }) => <TablePoints points={value}/>,
		},
		{
			Header: t("table.invoiceNumber"),
			accessor: "invoiceNumber",
			Cell: ({ cell: { value } }) => value || "N/A",
		},
		{
			Header: t("table.updatedBy"),
			accessor: "updatedBy",
			Cell: ({ cell: { value } }) => value || "N/A",
		},
	], [t]);

	const onChangePagination = (event) => {
		filterBy.current.page = event.target.value;
		getPointsLog();
	};

	const getTabPath = (path) => {
		const nextPath = path + params.customerId;
		return nextPath;
	};

	return (
		<div className="page-points-history">
			<div className="points-history">
				<AppBreadCrumb data={breadCrumbData} />

				<ul className="points-history__list">
					{CONSTANSTS.TABS.CUSTOMERS.map((item, i) => {
						const activeTab = i === 3;

						return (
							<li key={i} className={getTabItemClassNames({ activeTab })}>
								<Link to={getTabPath(item.path)}>{item.title}</Link>
							</li>
						);
					})}
				</ul>

				<h1 className="points-history__title">{t("title")}</h1>

				<div className="points-history__header">
					<p className="points-history__description">{t("showing", { total: pointsLog?.pointsLog?.length })}</p>
				</div>

				<div className="points-history__body">
					{isEmpty && <AppEmptyData data={pointsLog?.pointsLog} />}
					{!isEmpty && <AppTable columns={columns} data={pointsLog.pointsLog} total={pointsLog?.totalPage} onChangePagination={onChangePagination} />}
				</div>
			</div>
		</div>
	);
};

export default PagePointsHistory;
