import React, { useCallback, memo } from "react";
import { useTranslation } from "react-i18next";
import { Input as Field } from "reactstrap";

const AppTextarea = ({ note, touched, error, icon, ...props }) => {
	const { t } = useTranslation("components");
	const disabled = props.disabled;
	const maxLength = props.maxLength;
	const invalid = touched && error;

	//prettier-ignore
	const onHandleBlur = useCallback((event) => {
		event.currentTarget.value = event.currentTarget.value.trim();
		props.onChange(event);
	}, [props]);

	return (
		<div className="app-textarea">
			<div className={getWrapperClassNames({ invalid, disabled })}>
				{icon && <div className="app-textarea__icon">{icon}</div>}

				<Field {...props} type="textarea" className="app-textarea__input" onBlur={onHandleBlur} autoComplete="off" />

				{maxLength && <p className="app-textarea__text app-textarea__text--max">{t("textarea.max", { currentChar: props.value?.length, max: maxLength })}</p>}
			</div>

			{invalid && <p className="app-textarea__text app-textarea__text--error">{error}</p>}

			{note && <p className="app-textarea__text">{note}</p>}
		</div>
	);
};

const getWrapperClassNames = ({ invalid, disabled }) => {
	const classNames = ["app-textarea__container"];

	if (disabled) classNames.push("app-textarea__container--disabled");

	if (invalid) classNames.push("app-textarea__container--error");

	return classNames.join(" ");
};

export default memo(AppTextarea);
