import React, { memo, forwardRef, useState, useImperativeHandle, useMemo, useCallback } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import { ReactComponent as GroupIcon } from "assets/images/pages/page-groups/group-icon.svg";

const initialValues = {
	groupTitle: "",
};

const AppGroupCreateModal = forwardRef(({ isOpen, onHandleConfirm }, ref) => {
	const { t } = useTranslation("components");
	const [visible, setVisible] = useState(false);

	//prettier-ignore
	const validationSchema = useMemo(() => Yup.object().shape({
		groupTitle: Yup.string().required(t("common:required")),
	}), [t]);

	const formik = useFormik({
		enableReinitialize: isOpen,
		initialValues,
		validationSchema,
		onSubmit: (values) => {
			onHandleDismiss();
			onHandleConfirm(values, enableSubmitButton);
		},
	});

	const enableSubmitButton = useCallback(() => {
		formik.setSubmitting(false);
	}, [formik]);

	useImperativeHandle(ref, () => ({
		onHandleDismiss: onHandleDismiss,
		onHandleShow: onHandleShow,
	}));

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	const onHandleShow = useCallback(() => {
		setVisible(true);
	}, []);

	return (
		<Modal className="app-group-create-modal" contentClassName="group-create-modal" centered size="lg" isOpen={visible} toggle={onHandleDismiss}>
			<form autoComplete="off" onSubmit={formik.handleSubmit}>
				<ModalHeader className="group-create-modal__header" toggle={onHandleDismiss}></ModalHeader>
				<ModalBody className="group-create-modal__body">
					<div className="group-create-modal__body-header">
						<GroupIcon className="group-create-modal__icon" />
						<h1 className="group-create-modal__title">{t("groupForm.title")}</h1>
					</div>

					<div className="group-create-modal__field">
						<p className="group-create-modal__label">{t("groupForm.field.0.label")}</p>
						<AppInput type="text" name="groupTitle" placeholder={t("groupForm.field.0.placeholder")} error={formik.errors.groupTitle} touched={formik.touched.groupTitle} onChange={formik.handleChange} />
					</div>
				</ModalBody>
				<ModalFooter className="group-create-modal__footer">
					<AppButton type="button" label={t("groupForm.cancel")} cancel onClick={onHandleDismiss} />
					<AppButton type="submit" label={t("groupForm.create")} loading={formik.isSubmitting} />
				</ModalFooter>
			</form>
		</Modal>
	);
});

export default memo(AppGroupCreateModal);
