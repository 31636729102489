import React, { useMemo, useState, useRef, useCallback, useEffect } from "react";
import { debounce } from "lodash";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";

import api from "services/api";
import pathnames from "routes/pathnames";
import CONSTANSTS from "common/constansts";
import AppEmptyData from "components/app-empty-data";
import AppTable, { TableStatus, TableEditButton } from "components/app-table";
import { onHandleRequestError, onHandleGetIndex } from "common/utilities";

const PageCustomers = (props) => {
	const filterBy = useRef({ length: 10, page: 0, order: "desc", sortBy: null, accountStatus: null, valueEntered: null });
	const { t } = useTranslation("customers");
	const navigate = useNavigate();
	const [customers, setCustomers] = useState(null);
	const isEmpty = !customers?.customerProfileDTOS?.length || !customers;
	const isEmptySearch = !customers?.customerProfileDTOS?.length && filterBy.current.valueEntered;

	useEffect(() => {
		getCustomers();
	}, []);

	useEffect(() => {
		return () => props.onHandleCancelRequest();
	}, [props]);

	const getCustomers = async () => {
		let response = null;

		try {
			const payload = filterBy.current;
			response = await api.get.customers(payload);
		} catch (error) {
			onHandleRequestError(error);
		}

		if (response) {
			setCustomers(response.data.result);
		}
	};

	const onHandleSortTable = useCallback((order, key) => {
		filterBy.current.page = 0;
		filterBy.current.order = order;
		filterBy.current.sortBy = key;
		getCustomers();
	}, []);

	//prettier-ignore
	const columns = useMemo(() => [
			{
				Header: "No",
				id: "index",
				accessor: (_row, i) => onHandleGetIndex(i, filterBy.current.page),
			},
			{
				Header: t("table.id"),
				accessor: "customerId",
				setFilter: (value) => {
					onHandleSortTable(value, "customerId");
				},
			},
			{
				Header: t("table.name"),
				accessor: "fullName",
				setFilter: (value) => {
					onHandleSortTable(value, "fullName");
				},
			},
			{
				Header: t("table.email"),
				accessor: "emailAddress",
				setFilter: (value) => {
					onHandleSortTable(value, "emailAddress");
				},
			},
			{
				Header: t("table.PhoneNumber"),
				accessor: "phoneNum",
				Cell: ({ cell: { value, row } }) => `+${row.original.dialCode} ${value}`,
			},
			{
				Header: t("table.points"),
				accessor: "points",
				setFilter: (value) => {
					onHandleSortTable(value, "points");
				},
			},
			{
				Header: t("table.status"),
				accessor: "accountStatus",
				filterOptions: CONSTANSTS.FILTER_BY.CUSTOMER_STATUS,
				Cell: ({ cell: { value } }) => <TableStatus status={value}/>,
				setFilter: (value) => {
					filterBy.current.page = 0;
					filterBy.current.accountStatus = value;
					getCustomers();
				},
			},
			{
				Header: "",
				accessor: "t-action",
				Cell: ({ row }) => {
					const customer = row.original;
					return (
						<TableEditButton
							onClick={() => {
								navigate(pathnames.customer + customer.customerId, { state: { ...customer } });
							}}
						/>
					);
				},
			},
		],
		[t, navigate, onHandleSortTable]
	);

	const onChangePagination = (event) => {
		filterBy.current.page = event.target.value;
		getCustomers();
	};

	const onSearchCustomer = (event) => {
		const value = event.target.value;
		filterBy.current = { length: 10, page: 0, order: "desc", sortBy: null, accountStatus: null, valueEntered: value };
		getCustomers();
	};

	const onHandleSearchCustomer = debounce(onSearchCustomer, 2000);

	return (
		<div className="page-customers">
			<div className="customers">
				<h1 className="customers__title">{t("title")}</h1>

				<div className="customers__header">
					<p className="customers__description">{t("showing", { total: customers?.customerProfileDTOS?.length })}</p>

					<div className="customers__search">
						<IoSearchOutline />
						<input size="50" name="search" placeholder={t("search")} onChange={onHandleSearchCustomer} />
					</div>
				</div>

				<div className="customers__body">
					{(isEmpty || isEmptySearch) && <AppEmptyData data={customers?.customerProfileDTOS} search={isEmptySearch} />}
					{!isEmpty && <AppTable columns={columns} data={customers.customerProfileDTOS} total={customers.totalPage} page={customers.page} onChangePagination={onChangePagination} />}
				</div>
			</div>
		</div>
	);
};

export default PageCustomers;
