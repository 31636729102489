import React, { useState, useCallback, useEffect, useMemo, useRef } from "react";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { IoSearchOutline, IoAddOutline } from "react-icons/io5";

import api from "services/api";
import pathnames from "routes/pathnames";
import CONSTANSTS from "common/constansts";
import useIsMount from "hooks/use-is-mount";
import AppEmptyData from "components/app-empty-data";
import AppTable, { TableStatus, TableEditButton } from "components/app-table";
import { onHandleRequestError, onHandleGetIndex } from "common/utilities";

const getTabItemClassNames = ({ activeTab }) => {
	const classNames = ["products__item"];

	if (activeTab) classNames.push("products__item--active");

	return classNames.join(" ");
};

const PageProducts = (props) => {
	const { t } = useTranslation("products");
	const profile = useSelector((state) => state.auth.profile);
	const navigate = useNavigate();
	const { state } = useLocation();
	const isMount = useIsMount();
	const [tabs, setTabs] = useState(null);
	const [currentTab, setCurrentTab] = useState(null);
	const [products, setProducts] = useState(null);
	const filterBy = useRef({ length: 10, page: 0, order: "desc", sortBy: null, status: null, groupId: null, valueEntered: null });
	const isEmpty = !products?.productDTOList?.length || !products;
	const isEmptySearch = !products?.productDTOList?.length && filterBy.current.valueEntered;
	const isSuperAdmin = CONSTANSTS.ROLE.SUPER_ADMIN === profile?.role;

	const onHandleChangeTab = useCallback((groupId) => {
		filterBy.current = { length: 10, page: 0, order: "desc", sortBy: null, status: null, groupId, valueEntered: null };
		setCurrentTab(groupId);
		getProducts();
	}, []);

	const getGroups = useCallback(async () => {
		let response = null;

		try {
			const payload = { page: 0, status: CONSTANSTS.STATUS.ACTIVE };
			response = await api.get.groups(payload);
		} catch (error) {
			onHandleRequestError(error);
		}

		if (response) {
			const groupViews = response.data.result.groupViews.map((o) => o.group);
			const groupId = state?.groupId || groupViews[0]?.groupId;
			onHandleChangeTab(groupId);
			setTabs(groupViews);
		}
	}, [state, onHandleChangeTab]);

	useEffect(() => {
		if (isMount) {
			getGroups();
		}
	}, [isMount, getGroups]);

	useEffect(() => {
		return () => props.onHandleCancelRequest();
	}, [props]);

	const getProducts = async () => {
		let response = null;

		setProducts(null);

		try {
			const payload = filterBy.current;
			response = await api.get.products(payload);
		} catch (error) {
			onHandleRequestError(error);
		}

		if (response) {
			const nextProducts = response.data.result;
			setProducts(nextProducts);
		}
	};

	const onHandleSortTable = useCallback((order, key) => {
		filterBy.current.page = 0;
		filterBy.current.order = order;
		filterBy.current.sortBy = key;
		getProducts();
	}, []);

	const columns = useMemo(
		() => [
			{
				Header: "No",
				id: "index",
				accessor: (_row, i) => onHandleGetIndex(i, filterBy.current.page),
			},
			{
				Header: t("table.sku"),
				accessor: "productSku",
				Cell: ({ cell: { value } }) => value || "N/A",
				setFilter: (value) => {
					onHandleSortTable(value, "productSku");
				},
			},
			{
				Header: t("table.name"),
				accessor: "productTitle",
				setFilter: (value) => {
					onHandleSortTable(value, "productTitle");
				},
			},
			{
				Header: t("table.category"),
				accessor: "categoryName",
				Cell: ({ cell: { value } }) => value || "N/A",
				setFilter: (value) => {
					onHandleSortTable(value, "categoryName");
				},
			},
			{
				Header: t("table.subcategory"),
				accessor: "variationName",
				Cell: ({ cell: { value } }) => value || "N/A",
				setFilter: (value) => {
					onHandleSortTable(value, "variationName");
				},
			},
			{
				Header: t("table.status"),
				accessor: "productStatus",
				filterOptions: CONSTANSTS.FILTER_BY.STATUS,
				Cell: ({ cell: { value } }) => <TableStatus status={value} />,
				setFilter: (value) => {
					filterBy.current.page = 0;
					filterBy.current.status = value;
					getProducts();
				},
			},
			{
				Header: "",
				accessor: "t-action",
				Cell: ({ row }) => {
					const product = row.original;
					return (
						<TableEditButton
							onClick={() => {
								navigate(pathnames.product, { state: { ...product, mode: CONSTANSTS.MODE.EDIT } });
							}}
						/>
					);
				},
			},
		],
		[t, navigate, onHandleSortTable]
	);

	const onNavigateCreateProduct = () => {
		const requiredCategory = tabs.filter((o) => o.groupId === currentTab)?.[0]?.requiredCategory;
		navigate(pathnames.product, { state: { groupId: currentTab, requiredCategory, mode: CONSTANSTS.MODE.CREATE } });
	};

	const onChangePagination = (event) => {
		filterBy.current.page = event.target.value;
		getProducts();
	};

	const onSearchProduct = (event) => {
		const value = event.target.value;
		filterBy.current = { length: 10, page: 0, order: "desc", sortBy: null, status: null, groupId: currentTab, valueEntered: value };
		getProducts();
	};

	const onHandleSearchProduct = debounce(onSearchProduct, 2000);

	return (
		<div className="page-products">
			<div className="products">
				<h1 className="products__title">{t("title")}</h1>

				<ul className="products__list">
					{tabs?.map?.((item, i) => {
						const activeTab = item.groupId === currentTab;
						return (
							<li key={i} className={getTabItemClassNames({ activeTab })} onClick={() => onHandleChangeTab(item.groupId)}>
								{item.groupTitle}
							</li>
						);
					})}
				</ul>

				<div className="products__header">
					<p className="products__description">{t("showing", { total: products?.productDTOList?.length })}</p>

					<div className="products__search">
						<IoSearchOutline />
						<input size="22" name="search" placeholder={t("search")} onChange={onHandleSearchProduct} />
						{isSuperAdmin && (
							<button className="products__new-cta" onClick={onNavigateCreateProduct} disabled={!tabs}>
								<IoAddOutline className="products__icon" />
								<p className="products__label">{t("newCta")}</p>
							</button>
						)}
					</div>
				</div>
				<div className="products__body">
					{(isEmpty || isEmptySearch) && <AppEmptyData data={products?.productDTOList} search={isEmptySearch} />}
					{!isEmpty && <AppTable columns={columns} data={products.productDTOList} total={products.totalPage} page={products.page} onChangePagination={onChangePagination} />}
				</div>
			</div>
		</div>
	);
};

export default PageProducts;
