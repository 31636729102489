import React, { memo, useCallback, useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { IoArrowBack, IoArrowForward } from "react-icons/io5";
import { BsDot, BsFillCaretDownFill, BsFillCaretUpFill } from "react-icons/bs";

import SIDE_NAV_DATA from "common/side-nav-data";
import packageJson from "../../package.json";

const getSideNavItemClassNames = ({ navActive, sideNavSubItemActive }) => {
	const classNames = ["side-nav__item"];

	if (navActive) classNames.push("side-nav__item--active");

	if (sideNavSubItemActive) classNames.push("side-nav__item--sub-active");

	return classNames.join(" ");
};

const getSideNavSubListClassNames = ({ navActive, sideNavIsExpanded, navSubListActive }) => {
	const classNames = ["side-nav__sub-list"];

	if ((navActive && sideNavIsExpanded) || (navSubListActive && sideNavIsExpanded)) classNames.push("side-nav__sub-list--expand");

	return classNames.join(" ");
};

const getSideNavClassNames = (active) => {
	const classNames = ["app-side-nav"];

	if (active !== null) {
		if (active) {
			classNames.push("app-side-nav--expand");
		} else {
			classNames.push("app-side-nav--shrink");
		}
	}

	return classNames.join(" ");
};

const getSideNavListClassNames = (active) => {
	const classNames = ["side-nav__list"];

	if (active) {
		classNames.push("side-nav__list--expand");
	} else {
		classNames.push("side-nav__list--shrink");
	}

	return classNames.join(" ");
};

const AppSideNav = (props) => {
	const sideNavRef = useRef();
	const sideNavItem = useRef();
	const location = useLocation();
	const { profile } = useSelector((state) => state.auth);
	const [sideNavExpanded, setSideNavExpanded] = useState(null);
	const [currentActiveNav, setCurrentActiveNav] = useState(null);
	const [selectedSubListNav, setSelectedSubListNav] = useState(null);
	const sideNavIsExpanded = sideNavExpanded || sideNavExpanded === null;

	useEffect(() => {
		SIDE_NAV_DATA.forEach((o, i) => {
			const currentPage = o.to === location.pathname;
			let currentRelations = null;

			if (o.relations) currentRelations = o.relations.some((k) => location.pathname.includes(k));

			if (o.childrens) {
				o.childrens?.forEach((k) => {
					const currentSubPage = k.to === location.pathname;
					const subRelations = k?.relations?.includes(location.pathname);

					if (currentSubPage || subRelations) {
						props.setHeaderTitle(o.label);
						setCurrentActiveNav(i);
					}
				});
			}

			if (currentPage || currentRelations) {
				props.setHeaderTitle(o.label);
				setCurrentActiveNav(i);
			}
		});
	}, [location, props]);

	const onHandleToggleSideNav = useCallback(() => {
		const sideNavStatus = sideNavIsExpanded ? false : true;
		setSideNavExpanded(sideNavStatus);
	}, [sideNavIsExpanded]);

	const onHandleToggleSubNav = useCallback(
		(selectedIndex) => {
			const pickedActiveNav = selectedIndex === currentActiveNav;
			const pickedActiveSubNav = selectedSubListNav === selectedIndex;
			const nextIndex = pickedActiveSubNav && sideNavIsExpanded ? null : selectedIndex;

			if (!sideNavIsExpanded) setSideNavExpanded(true);

			if (!pickedActiveNav) setSelectedSubListNav(nextIndex);
		},
		[currentActiveNav, selectedSubListNav, sideNavIsExpanded]
	);

	return (
		<div className={getSideNavClassNames(sideNavExpanded)} ref={sideNavRef}>
			<div className="side-nav">
				<div className="side-nav__slide-cta" onClick={onHandleToggleSideNav}>
					<div className="side-nav__slide-cta-container">{sideNavIsExpanded ? <IoArrowBack /> : <IoArrowForward />}</div>
				</div>
				<ul className={getSideNavListClassNames(sideNavIsExpanded)} ref={sideNavItem}>
					{SIDE_NAV_DATA.map((o, i) => {
						const Icon = o.icon;
						const navActive = location.pathname === o.to || currentActiveNav === i;
						const navSubListActive = selectedSubListNav === i;
						const caretIconShow = navActive || navSubListActive;
						const isRestrictedRoute = o.restrict && o.restrict !== profile?.role;

						if (!o.childrens) {
							if (isRestrictedRoute) return null;

							return (
								<Link to={o.to} key={i} className="side-nav__link">
									<li className={getSideNavItemClassNames({ navActive })}>
										<Icon className="side-nav__icon" />
										<p className="side-nav__text">{o.label}</p>
									</li>
								</Link>
							);
						}

						return (
							<div className="side-nav__sub" key={i}>
								<li className={getSideNavItemClassNames({ navActive })} onClick={() => onHandleToggleSubNav(i)}>
									<Icon className="side-nav__icon" />
									<p className="side-nav__text">
										{o.label} {caretIconShow ? <BsFillCaretUpFill size={12} /> : <BsFillCaretDownFill size={12} />}
									</p>
								</li>
								<ul className={getSideNavSubListClassNames({ navSubListActive, navActive, sideNavIsExpanded })}>
									{o.childrens.map((j, k) => {
										const currentPage = location.pathname === j.to;
										const relations = j?.relations?.includes(location.pathname);
										const sideNavSubItemActive = currentPage || relations;
										const isRestrictedRoute = j.restrict && j.restrict !== profile?.role;

										if (isRestrictedRoute) return null;

										return (
											<Link to={j.to} key={k} className="side-nav__link">
												<li className={getSideNavItemClassNames({ sideNavSubItemActive })}>
													<BsDot />
													<p className="side-nav__text">{j.label}</p>
												</li>
											</Link>
										);
									})}
								</ul>
							</div>
						);
					})}
				</ul>
				<p className="side-nav__version">Version ({packageJson.version})</p>
			</div>
		</div>
	);
};

export default memo(AppSideNav);
