import React, { useRef, useMemo, useEffect, useCallback } from "react";
import * as Yup from "yup";
import { compose } from "redux";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import api from "services/api";
import pathnames from "routes/pathnames";
import CONSTANSTS from "common/constansts";
import useIsMount from "hooks/use-is-mount";
import asianCountries from "common/asian-countries";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppRadioInput from "components/app-radio-input";
import AppBreadCrumb from "components/app-bread-crumb";
import AppMobileInput from "components/app-mobile-input";
import withLightboxModal from "contexts/with-lightbox-modal";
import { onHandleRequestError, onHandleRequestSuccess } from "common/utilities";

const PageUser = (props) => {
	const { t } = useTranslation("user");
	const isMount = useIsMount();
	const uploadImageRef = useRef();
	const navigate = useNavigate();
	const { state } = useLocation();
	const { profile } = useSelector((state) => state.auth);
	const isSuperAdmin = CONSTANSTS.ROLE.SUPER_ADMIN === profile?.role;
	const isCreateMode = state?.mode === CONSTANSTS.MODE.CREATE;
	const isEditMode = state?.mode === CONSTANSTS.MODE.EDIT;
	const submitButtonLabel = isCreateMode ? t("create") : t("update");
	//prettier-ignore
	const breadCrumbData = useMemo(() => ({
		default: { label: t("breadCrumb.0.label"), path: pathnames.users },
		create: { label: t("breadCrumb.1.label") },
		edit: { label: t("breadCrumb.2.label") },
	}), [t]);
	//prettier-ignore
	const validationSchema = useMemo(() => Yup.object().shape({
		image: Yup.mixed()
			.test(CONSTANSTS.UPLOAD.ERRORS[0].type, CONSTANSTS.UPLOAD.ERRORS[0].error, (value) => (value ? value?.size <= CONSTANSTS.UPLOAD.SIZES_LIMIT : true))
			.test(CONSTANSTS.UPLOAD.ERRORS[1].type, CONSTANSTS.UPLOAD.ERRORS[1].error, (value) => (value ? CONSTANSTS.UPLOAD.IMAGES_TYPE.includes(value?.type) : true)),
		name: Yup.string().required(t("common:required")),
		email: Yup.string().required(t("common:required")).email(t("common:required")),
		jobRole: Yup.string().required(t("common:required")),
		phoneNumber: Yup.string().required(t("common:required")),
		storeName: Yup.string().required(t("common:required")),
	}), [t]);

	const initialValues = useMemo(() => {
		const values = {
			id: "",
			image: "",
			name: "",
			email: "",
			jobRole: "",
			phoneNumber: "",
			dialCode: asianCountries[0],
			storeName: "",
			status: CONSTANSTS.STATUS.ACTIVE,
		};

		if (state) {
			if (state.adminId) values.adminId = state.adminId;
			if (state.name) values.name = state.name;
			if (state.emailAddress) values.email = state.emailAddress;
			if (state.jobRole) values.jobRole = state.jobRole;
			if (state.phoneNumber) values.phoneNumber = state.phoneNumber;
			if (state.dialCode) values.dialCode = asianCountries.filter((o) => o.dialCode === state.dialCode)[0];
			if (state.storeName) values.storeName = state.storeName;
			if (state.status) values.status = state.status;
		}

		return values;
	}, [state]);
	const formik = useFormik({
		enableReinitialize: true,
		initialValues,
		validationSchema,
		onSubmit: (values) => {
			onHandleUpdateUser(values);
		},
	});

	const profileImage = useMemo(() => (formik.values.image ? URL.createObjectURL(formik.values.image) : state?.profilePic), [state, formik.values.image]);

	useEffect(() => {
		if ((isMount && !isSuperAdmin) || !state) navigate(pathnames.users);
	}, [isMount, navigate, isSuperAdmin, state]);

	const onHandleUpdateUser = async (values) => {
		let response = null;

		try {
			const formData = new FormData();
			formData.append("name", values.name);
			formData.append("status", values.status);
			formData.append("jobRole", values.jobRole);
			formData.append("storeName", values.storeName);
			formData.append("phoneNumber", values.phoneNumber);
			formData.append("dialCode", values.dialCode.dialCode);

			if (values.image) formData.append("profilePic", values.image);

			if (isEditMode) {
				formData.append("adminId", values.adminId);
				response = await api.post.updateAdminProfile(formData);
			}

			if (isCreateMode) {
				formData.append("emailAddress", values.email);
				formData.append("role", CONSTANSTS.ROLE.ADMIN);
				response = await api.post.createAdmin(formData);
			}
		} catch (error) {
			onHandleRequestError(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			if (isEditMode) onHandleRequestSuccess(t("editSuccess"));

			if (isCreateMode) onHandleRequestSuccess(t("createSuccess"));

			navigate(pathnames.users);
		}
	};

	const form = useMemo(() => {
		const fields = [
			{
				name: "adminId",
				label: t("field.0.label"),
				placeholder: t("field.0.placeholder"),
				disabled: true,
				type: "text",
			},
			{
				name: "name",
				label: t("field.1.label"),
				placeholder: t("field.1.placeholder"),
				disabled: false,
				type: "text",
			},
			{
				name: "email",
				label: t("field.2.label"),
				placeholder: t("field.2.placeholder"),
				disabled: isEditMode,
				type: "text",
			},
			{
				name: "jobRole",
				label: t("field.3.label"),
				placeholder: t("field.3.placeholder"),
				disabled: false,
				type: "text",
			},
			{
				name: "phoneNumber",
				label: t("field.4.label"),
				placeholder: t("field.4.placeholder"),
				disabled: false,
				type: "mobile",
			},
			{
				name: "storeName",
				label: t("field.5.label"),
				placeholder: t("field.5.placeholder"),
				disabled: false,
				type: "text",
			},
			{
				name: "status",
				label: t("field.6.label"),
				placeholder: t("field.6.placeholder"),
				type: "radio",
				options: CONSTANSTS.OPTIONS.STATUS,
				disabled: false,
			},
		];

		return fields;
	}, [t, isEditMode]);

	//prettier-ignore
	const onHandleSetImage = useCallback((event) => {
		const file = event.currentTarget.files[0];

		if (file) formik.setFieldValue("image", file);
	}, [formik]);

	const onHandleToggleProfileActions = () => {
		document.querySelector(".user__actions").classList.toggle("user__actions--active");
	};

	const onHandleUploadImage = () => {
		uploadImageRef.current.click();
	};

	const onHandleResetForm = () => {
		navigate(pathnames.users);
	};

	return (
		<div className="page-user">
			<div className="user">
				<AppBreadCrumb data={breadCrumbData} mode={state?.mode} />
				<h1 className="user__title">{t("title")}</h1>

				<form autoComplete="off" onSubmit={formik.handleSubmit}>
					<div className="user__body">
						<div className="user__profile">
							{/* prettier-ignore */}
							<input hidden type="file" name="image" ref={uploadImageRef} accept={CONSTANSTS.UPLOAD.IMAGES_TYPE} onChange={onHandleSetImage} />
							{/* prettier-ignore */}
							<div tabIndex="0" className="user__profile-image" onFocus={onHandleToggleProfileActions} onBlur={onHandleToggleProfileActions} style={{ backgroundImage: `url(${profileImage})` }}>
                                <ul className="user__actions">
                                    <li className="user__action-item" onClick={() => props.onHandleOpenLightbox(profileImage)}>
                                        {t("uploadCta.view")}
                                    </li>
                                    <li className="user__action-item" onClick={onHandleUploadImage}>
										{t("uploadCta.update")}
                                    </li>
                                </ul>
                            </div>
							<p className="user__name">{state?.name}</p>
						</div>

						<p className="user__error">{formik.errors.image}</p>

						<div className="user__content">
							{form.map(({ label, ...res }, i) => {
								const value = formik.values[res.name];
								const error = formik.errors[res.name];
								const touched = formik.touched[res.name];
								const isRadioField = res.type === "radio";
								const isMobileField = res.type === "mobile";

								if (isMobileField) {
									return (
										<div key={i} className="user__field">
											<p className="user__label">{label}</p>
											<AppMobileInput
												{...res}
												value={value}
												error={error}
												touched={touched}
												onChange={formik.handleChange}
												dialCode={formik.values.dialCode}
												onSelectCountryCode={(value) => formik.setFieldValue("dialCode", value)}
											/>
										</div>
									);
								}

								if (isRadioField) {
									return (
										<div key={i} className="user__field">
											<p className="user__label">{label}</p>
											<AppRadioInput {...res} value={value} onChange={formik.setFieldValue} />
										</div>
									);
								}

								return (
									<div key={i} className="user__field">
										<p className="user__label">{label}</p>
										<AppInput {...res} value={value} error={error} touched={touched} onChange={formik.handleChange} />
									</div>
								);
							})}
						</div>
					</div>
					<div className="user__button-container">
						<div className="user__button-wrapper">
							<AppButton label={t("cancel")} type="reset" cancel onClick={onHandleResetForm} />
							<AppButton label={submitButtonLabel} type="submit" loading={formik.isSubmitting} />
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default compose(withLightboxModal)(PageUser);
