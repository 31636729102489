import React, { useImperativeHandle, forwardRef, memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import AppButton from "components/app-button";
import { ReactComponent as BlockedIcon } from "assets/images/components/app-blocked-modal/blocked-icon.svg";

const AppBlockedModal = forwardRef((props, ref) => {
	const [visible, setVisible] = useState(false);
	const { t } = useTranslation("components");

	useImperativeHandle(ref, () => ({
		onHandleDismiss: onHandleDismiss,
		onHandleShow: onHandleShow,
	}));

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	const onHandleShow = useCallback(() => {
		setVisible(true);
	}, []);

	return (
		<Modal size="lg" className="app-blocked-modal" contentClassName="blocked-modal" centered isOpen={visible} toggle={onHandleDismiss}>
			<ModalHeader className="blocked-modal__header" toggle={onHandleDismiss}></ModalHeader>
			<ModalBody className="blocked-modal__body">
				<BlockedIcon />
				<h1 className="blocked-modal__title">{t("blockedModal.title")}</h1>
				<p className="blocked-modal__description">{t("blockedModal.description")}</p>
			</ModalBody>
			<ModalFooter className="blocked-modal__footer">
				<AppButton type="button" label={t("blockedModal.cta")} onClick={onHandleDismiss} />
			</ModalFooter>
		</Modal>
	);
});

export default memo(AppBlockedModal);
