import React, { Fragment, useRef, useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { IoAddOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { BsFillCaretDownFill } from "react-icons/bs";

import api from "services/api";
import CONSTANSTS from "common/constansts";
import AppEmptyData from "components/app-empty-data";
import AppFaqEditForm from "components/pages/page-faqs/app-faq-edit-form";
import AppFaqRemoveModal from "components/pages/page-faqs/app-faq-remove-modal";
import AppFaqCreateModal from "components/pages/page-faqs/app-faq-create-modal";
import { ReactComponent as EditIcon } from "assets/images/edit-icon.svg";
import { ReactComponent as RemoveIcon } from "assets/images/remove-icon.svg";
import { onHandleRequestError, onHandleRequestSuccess } from "common/utilities";

const PageFaqs = (props) => {
	const { t } = useTranslation("faqs");
	const removeFaqModalRef = useRef();
	const createFaqModalRef = useRef();
	const profile = useSelector((state) => state.auth.profile);
	const [faqs, setFaqs] = useState(null);
	const [currentTab, setCurrentTab] = useState(null);
	const [currentEditTab, setCurrentEditTab] = useState(null);
	const isEmpty = !faqs?.length || !faqs;
	const isSuperAdmin = CONSTANSTS.ROLE.SUPER_ADMIN === profile?.role;

	useEffect(() => {
		getFaqs();
	}, []);

	useEffect(() => {
		return () => props.onHandleCancelRequest();
	}, [props]);

	const getFaqs = async () => {
		let response = null;

		try {
			response = await api.get.faqs();
		} catch (error) {
			onHandleRequestError(error);
		}

		if (response) {
			const faqList = response.data.result.faqList;
			setFaqs(faqList);
		}
	};

	const onHandleAccordionTab = (item) => (event) => {
		const activeClassName = "accordion--active";
		const accordion = Array.from(document.querySelectorAll(".accordion"));
		accordion.forEach((element) => element.classList.remove(activeClassName));

		const parent = event.currentTarget.parentNode.parentNode.parentNode.parentNode;
		parent.classList.toggle(activeClassName);
		setCurrentEditTab(null);
		setCurrentTab(item);
	};

	const onHandleAccordionEditTab = (item) => (event) => {
		const activeClassName = "accordion--editable";
		const accordion = Array.from(document.querySelectorAll(".accordion"));
		accordion.forEach((element) => element.classList.remove(activeClassName));

		const parent = event.currentTarget.parentNode.parentNode.parentNode.parentNode;
		parent.classList.toggle(activeClassName);
		setCurrentTab(null);
		setCurrentEditTab(item);
	};

	const onHandleDimissEditForm = () => {
		const activeClassNames = ["accordion--active", "accordion--editable"];
		const accordion = Array.from(document.querySelectorAll(".accordion"));
		accordion.forEach((element) => {
			element.classList.remove(activeClassNames[0]);
			element.classList.remove(activeClassNames[1]);
		});
		setCurrentTab(null);
	};

	//prettier-ignore
	const onHandleConfirmUpdate = useCallback(async (values, callback) => {
		let response = null;

		try {
			response = await api.post.updateFaq(values);
		} catch (error) {
			onHandleRequestError(error);
		} finally {
			callback();
		}

		if (response) {
			getFaqs();
			onHandleDimissEditForm();
			onHandleRequestSuccess(t("editSuccess"));
		}
	}, [t]);

	//prettier-ignore
	const onHandleConfirmCreate = useCallback(async (values, callback) => {
		let response = null;

		try {
			response = await api.post.createFaq(values);
		} catch (error) {
			onHandleRequestError(error);
		} finally {
			callback();
		}

		if (response) {
			getFaqs();
			onHandleRequestSuccess(t("createSuccess"));
		}
	}, [t]);

	//prettier-ignore
	const onHandleConfirmRemove = useCallback(async (faqId) => {
		let response = null;

		try {
			response = await api.post.removeFaq(faqId);
		} catch (error) {
			onHandleRequestError(error);
		}

		if (response) {
			getFaqs();
			onHandleRequestSuccess(t("removeSuccess"));
		}
	}, [t]);

	return (
		<div className="page-faqs">
			<div className="faqs">
				<h1 className="faqs__title">{t("title")}</h1>

				<div className="faqs__header">
					<p className="faqs__description">{t("showing", { total: faqs?.length })} </p>

					{isSuperAdmin && (
						<button className="faqs__new-cta" onClick={createFaqModalRef.current?.onHandleShow}>
							<IoAddOutline className="faqs__icon" />
							<p className="faqs__label">{t("newCta")}</p>
						</button>
					)}
				</div>

				<div className="faqs__body">
					{isEmpty && <AppEmptyData data={faqs} />}
					{faqs?.map((item, i) => {
						const id = item.faqId;
						const isActive = currentTab?.faqId === id;
						const isEditActive = currentEditTab?.faqId === id;

						return (
							<div key={i} className="accordion">
								<div className="accordion__wrapper">
									<div className="accordion__tab">
										<p className="accordion__title">{item.faqTitle}</p>

										<div className="accordion__action">
											{isSuperAdmin && (
												<Fragment>
													<button className="accordion__action-cta" onClick={onHandleAccordionEditTab(item)}>
														<EditIcon className="accordion__icon accordion__icon--edit" />
													</button>
													<button className="accordion__action-cta" onClick={() => removeFaqModalRef.current.onHandleShow(id)}>
														<RemoveIcon className="accordion__icon accordion__icon--remove" />
													</button>
												</Fragment>
											)}
											<button className="accordion__action-cta" onClick={onHandleAccordionTab(item)}>
												<BsFillCaretDownFill className="accordion__icon accordion__icon--toggle" />
											</button>
										</div>
									</div>
								</div>
								{isEditActive && (
									<div className="accordion__form">
										<AppFaqEditForm initialValues={item} onHandleDismiss={onHandleDimissEditForm} onHandleConfirm={onHandleConfirmUpdate} />
									</div>
								)}
								{isActive && <div className="accordion__description">{item.faqDescription}</div>}
							</div>
						);
					})}
				</div>
			</div>
			<AppFaqCreateModal ref={createFaqModalRef} onHandleConfirm={onHandleConfirmCreate} />
			<AppFaqRemoveModal ref={removeFaqModalRef} onHandleConfirm={onHandleConfirmRemove} />
		</div>
	);
};

export default PageFaqs;
