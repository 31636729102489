import React, { useRef, useMemo, useEffect } from "react";
import * as Yup from "yup";
import moment from "moment";
import cloneDeep from "lodash";
import { compose } from "redux";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import api from "services/api";
import pathnames from "routes/pathnames";
import CONSTANSTS from "common/constansts";
import useIsMount from "hooks/use-is-mount";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppDropdown from "components/app-dropdown";
import AppTimeInput from "components/app-time-input";
import AppRadioInput from "components/app-radio-input";
import AppBreadCrumb from "components/app-bread-crumb";
import withLightboxModal from "contexts/with-lightbox-modal";
import { getTimeStops, onHandleRequestError, onHandleRequestSuccess } from "common/utilities";

const maximumImages = 5;

const PageOutlet = (props) => {
	const isMount = useIsMount();
	const navigate = useNavigate();
	const { state } = useLocation();
	const uploadImageRef = useRef({});
	const { t } = useTranslation("outlet");
	const profile = useSelector((state) => state.auth.profile);
	const isCreateMode = state?.mode === CONSTANSTS.MODE.CREATE;
	const isEditMode = state?.mode === CONSTANSTS.MODE.EDIT;
	const isSuperAdmin = CONSTANSTS.ROLE.SUPER_ADMIN === profile?.role;
	const isAdmin = CONSTANSTS.ROLE.ADMIN === profile?.role;
	const submitButtonLabel = isCreateMode ? t("create") : t("update");
	//prettier-ignore
	const breadCrumbData = useMemo(() => ({
		default: { label: t("breadCrumb.0.label"), path: pathnames.outlets },
		create: { label: t("breadCrumb.1.label") },
		edit: { label: t("breadCrumb.2.label") },
	}), [t]);
	//prettier-ignore
	const validationSchema = useMemo(() => Yup.object().shape({
		storeCode: Yup.string().required(t("common:required")),
		name: Yup.string().required(t("common:required")),
		website: Yup.string().url().required(t("common:required")).typeError(t("common:required")),
		addressOne: Yup.string().required(t("common:required")),
		addressTwo: Yup.string().required(t("common:required")),
		postcode: Yup.string().required(t("common:required")),
		city: Yup.string().required(t("common:required")),
		state: Yup.object().required(t("common:required")).typeError(t("common:required")),
		country: Yup.object().required(t("common:required")).typeError(t("common:required")),
		operationTimes: Yup.object({
			start: Yup.object().required(t("common:required")).typeError(t("common:required")),
			end: Yup.object().required(t("common:required")).typeError(t("common:required")),
		}),
		image: Yup.mixed()
			.required(t("common:required"))
			.test(CONSTANSTS.UPLOAD.ERRORS[0].type, CONSTANSTS.UPLOAD.ERRORS[0].error, (value) => {
				let isValid = true;

				if (value) {
					value.forEach((file) => {
						if (file?.size >= CONSTANSTS.UPLOAD.SIZES_LIMIT) isValid = false;
					});
				}

				return isValid;
			})
			.test(CONSTANSTS.UPLOAD.ERRORS[1].type, CONSTANSTS.UPLOAD.ERRORS[1].error, (value) => {
				let isValid = true;

				if (value) {
					value.forEach((file) => {
						if (!CONSTANSTS.UPLOAD.IMAGES_TYPE.includes(file?.type)) isValid = false;
					});
				}

				return isValid;
			})
			.test(CONSTANSTS.UPLOAD.ERRORS[2].type, CONSTANSTS.UPLOAD.ERRORS[2].error, (value) => {
				let isValid = true;

				if (value) isValid = value.length <= maximumImages;

				return isValid;
			}),
		phoneNumber: Yup.string().required(t("common:required")),
		personInCharge: Yup.string().required(t("common:required")),
	}), [t]);

	const initialValues = useMemo(() => {
		const values = {
			storeCode: "",
			name: "",
			website: "",
			addressOne: "",
			addressTwo: "",
			postcode: "",
			city: "",
			state: "",
			country: CONSTANSTS?.COUNTRIES[0],
			operationTimes: "",
			image: "",
			phoneNumber: "",
			personInCharge: "",
			status: CONSTANSTS.STATUS.ACTIVE,
		};

		if (state) {
			if (state.storeCode) values.storeCode = state.storeCode;
			if (state.storeName) values.name = state.storeName;
			if (state.websiteUrl) values.website = state.websiteUrl;
			if (state.storeAddressLineOne) values.addressOne = state.storeAddressLineOne;
			if (state.storeAddressLineTwo) values.addressTwo = state.storeAddressLineTwo;
			if (state.postCode) values.postcode = state.postCode;
			if (state.city) values.city = state.city;
			if (state.state) values.state = CONSTANSTS.OPTIONS.STATES.filter((item) => item.value === state?.state)[0];
			if (state.storePhoneNumber) values.phoneNumber = state.storePhoneNumber;
			if (state.storePersonInCharge) values.personInCharge = state.storePersonInCharge;
			if (state.storeStatus) values.status = state.storeStatus;
			if (state.operationStartHours && state.operationEndHours) {
				const operationStartHours = moment(state?.operationStartHours).format(CONSTANSTS.DATE_FORMAT.HHMM);
				const operationEndHours = moment(state?.operationEndHours).format(CONSTANSTS.DATE_FORMAT.HHMM);
				const stateOperationHours = getTimeStops(operationStartHours, operationEndHours);
				values.operationTimes = { start: stateOperationHours[0], end: stateOperationHours[stateOperationHours.length - 1] };
			}
			if (state.images) {
				let nextImages = [];
				state.images.forEach((i) => {
					nextImages.push({ name: i, type: CONSTANSTS.UPLOAD.IMAGES_TYPE[0], size: 0 });
				});
				values.image = nextImages;
			}
		}

		return values;
	}, [state]);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues,
		validationSchema,
		onSubmit: (values) => {
			onHandleUpdateOutlet(values);
		},
	});

	useEffect(() => {
		if (!state) navigate(pathnames.users);
	}, [navigate, state]);

	useEffect(() => {
		if (isMount && !state) navigate(pathnames.outlets);
	}, [isMount, navigate, state]);

	const onHandleUpdateOutlet = async (values) => {
		let response = null;

		try {
			const formData = new FormData();
			formData.append("storeCode", values.storeCode);
			formData.append("storeName", values.name);
			formData.append("websiteUrl", values.website);
			formData.append("addressLineOne", values.addressOne);
			formData.append("addressLineTwo", values.addressTwo);
			formData.append("postCode", values.postcode);
			formData.append("city", values.city);
			formData.append("state", values.state.value);
			formData.append("country", "malaysia");
			formData.append("operationStartHours", values.operationTimes.start.value);
			formData.append("operationEndHours", values.operationTimes.end.value);
			formData.append("storePhoneNumber", values.phoneNumber);
			formData.append("storePersonInCharge", values.personInCharge);
			formData.append("storeStatus", values.status);
			const newImages = values?.image?.filter((i) => i.size);

			if (values.deleteImages) values.deleteImages.forEach((i) => formData.append("deleteImages", i));

			if (newImages.length) newImages.forEach((i) => formData.append("images", i));

			if (isEditMode) {
				formData.append("storeId", state.storeId);
				response = await api.post.updateOutlet(formData);
			}

			if (isCreateMode) response = await api.post.createOutlet(formData);
		} catch (error) {
			onHandleRequestError(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			if (isEditMode) onHandleRequestSuccess("Outlet has been updated.");

			if (isCreateMode) onHandleRequestSuccess("Outlet has been created.");

			navigate(pathnames.outlets);
		}
	};

	const form = useMemo(() => {
		const fields = [
			{
				name: "storeCode",
				label: t("field.0.label"),
				placeholder: t("field.0.placeholder"),
				disabled: isAdmin,
				type: "text",
			},
			{
				name: "name",
				label: t("field.1.label"),
				placeholder: t("field.1.placeholder"),
				disabled: isAdmin,
				type: "text",
			},
			{
				name: "website",
				label: t("field.2.label"),
				placeholder: t("field.2.placeholder"),
				disabled: isAdmin,
				type: "text",
			},
			{
				name: "addressOne",
				label: t("field.3.label"),
				placeholder: t("field.3.placeholder"),
				disabled: isAdmin,
				type: "text",
			},
			{
				name: "addressTwo",
				label: t("field.4.label"),
				placeholder: t("field.4.placeholder"),
				disabled: isAdmin,
				type: "text",
			},
			{
				name: "postcode",
				label: t("field.5.label"),
				placeholder: t("field.5.placeholder"),
				disabled: isAdmin,
				type: "text",
			},
			{
				name: "city",
				label: t("field.6.label"),
				placeholder: t("field.6.placeholder"),
				disabled: isAdmin,
				type: "text",
			},
			{
				name: "state",
				label: t("field.7.label"),
				placeholder: t("field.7.placeholder"),
				options: CONSTANSTS.OPTIONS.STATES,
				disabled: isAdmin,
				type: "select",
			},
			{
				name: "country",
				label: t("field.8.label"),
				placeholder: t("field.8.placeholder"),
				options: CONSTANSTS.OPTIONS.STATES,
				disabled: true,
				type: "select",
			},
			{
				name: "operationTimes",
				label: t("field.9.label"),
				placeholder: t("field.9.placeholder"),
				disabled: isAdmin,
				type: "time",
			},
			{
				name: "image",
				label: t("field.10.label"),
				placeholder: t("field.10.placeholder"),
				disabled: isAdmin,
				type: "file",
			},
			{
				name: "phoneNumber",
				label: t("field.11.label"),
				placeholder: t("field.11.placeholder"),
				disabled: isAdmin,
				type: "text",
			},
			{
				name: "personInCharge",
				label: t("field.12.label"),
				placeholder: t("field.12.placeholder"),
				disabled: isAdmin,
				type: "text",
			},
			{
				name: "status",
				label: t("field.13.label"),
				placeholder: t("field.13.placeholder"),
				type: "radio",
				options: CONSTANSTS.OPTIONS.STATUS,
				disabled: isAdmin,
			},
		];

		return fields;
	}, [t, isAdmin]);

	const onHandleResetForm = () => {
		navigate(pathnames.outlets);
	};

	const onHandleSetImage = (event) => {
		const prevFile = formik.values.image;
		let file = Array.from(event.currentTarget.files);

		if (prevFile) file.unshift(...prevFile);

		if (file) formik.setFieldValue("image", file);
	};

	const onHandleRemoveImage = (imageIndex) => {
		const prevDeleteImages = cloneDeep(formik.values.deleteImages);
		const files = formik.values.image.filter((o, i) => i !== imageIndex);
		let deleteImages = formik.values.image.filter((o, i) => i === imageIndex).map((i) => i.name);

		if (deleteImages.length) formik.setFieldValue("deleteImages", deleteImages);

		if (prevDeleteImages) deleteImages.push(...prevDeleteImages);

		formik.setFieldValue("image", files);

		uploadImageRef.current.value = "";
	};

	const onHandleUploadImage = () => {
		uploadImageRef.current.click();
	};

	return (
		<div className="page-outlet">
			<div className="outlet">
				<AppBreadCrumb data={breadCrumbData} mode={state?.mode} />
				<h1 className="outlet__title">{t("title")}</h1>

				<form autoComplete="off" onSubmit={formik.handleSubmit}>
					<div className="outlet__body">
						<div className="outlet__content">
							{form.map(({ label, ...res }, i) => {
								const value = formik.values[res.name];
								const error = formik.errors[res.name];
								const touched = formik.touched[res.name];
								const isRadioField = res.type === "radio";
								const isUploadField = res.type === "file";
								const isDropdownField = res.type === "select";
								const isTimeField = res.type === "time";

								if (isUploadField) {
									const files = value;
									const isMaximumImages = files?.length < maximumImages;

									return (
										<div key={i} className="outlet__field">
											<p className="outlet__label">{label}</p>
											{/* prettier-ignore */}
											<input hidden type="file" name={res.name} ref={uploadImageRef} multiple="multiple" accept={CONSTANSTS.UPLOAD.IMAGES_TYPE} onChange={onHandleSetImage} />

											<div className="outlet__upload-container">
												{files && !!files.length && (
													<div className="outlet__upload-content">
														{files.map((item, j) => {
															// prettier-ignore
															return (
                                                                <div className="outlet__view-container" key={j}>
                                                                    <button type="button" className="outlet__view-cta" onClick={() => props.onHandleOpenLightbox(item)}>
                                                                        <p className="outlet__text outlet__text--name">{item.name}</p>
                                                                    </button>
                                                                    {isSuperAdmin && <button type="button" className="outlet__delete-cta" onClick={() => onHandleRemoveImage(j)}/>}
                                                                </div>
                                                            );
														})}
													</div>
												)}

												{isMaximumImages && isSuperAdmin && (
													<div className="outlet__upload-wrapper">
														<div className="outlet__upload-cta-container">
															<button type="button" className="outlet__upload-cta" onClick={onHandleUploadImage}>
																<p className="outlet__text outlet__text--upload">{t("uploadPicture")}</p>
															</button>

															{/* prettier-ignore */}
															<p className="outlet__text">{t("field.9.placeholder")}</p>
														</div>
													</div>
												)}

												{error && touched && <p className="outlet__text outlet__text--error">{error}</p>}
											</div>
										</div>
									);
								}

								if (isRadioField) {
									return (
										<div key={i} className="outlet__field">
											<p className="outlet__label">{label}</p>
											<AppRadioInput {...res} value={value} onChange={formik.setFieldValue} />
										</div>
									);
								}

								if (isTimeField) {
									return (
										<div key={i} className="outlet__field">
											<p className="outlet__label">{label}</p>
											<AppTimeInput {...res} value={value} error={error} touched={touched} onChange={formik.setFieldValue} />
										</div>
									);
								}

								if (isDropdownField) {
									return (
										<div key={i} className="outlet__field">
											<p className="outlet__label">{label}</p>
											<AppDropdown {...res} value={value} error={error} touched={touched} data={res.options} onChange={formik.setFieldValue} />
										</div>
									);
								}

								return (
									<div key={i} className="outlet__field">
										<p className="outlet__label">{label}</p>
										<AppInput {...res} value={value} error={error} touched={touched} onChange={formik.handleChange} />
									</div>
								);
							})}
						</div>
					</div>
					{isSuperAdmin && (
						<div className="outlet__button-container">
							<div className="outlet__button-wrapper">
								<AppButton label={t("cancel")} type="reset" cancel onClick={onHandleResetForm} />
								<AppButton label={submitButtonLabel} type="submit" loading={formik.isSubmitting} />
							</div>
						</div>
					)}
				</form>
			</div>
		</div>
	);
};

export default compose(withLightboxModal)(PageOutlet);
