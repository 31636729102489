import React, { useMemo } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { IoIosMail } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import api from "services/api";
import pathnames from "routes/pathnames";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import { onHandleRequestError } from "common/utilities";
import logo from "assets/images/menara-logo.svg";
import heroImage from "assets/images/hero-image.svg";

const initialValues = {
	email: "",
};

const PageForgotPassword = () => {
	const { t } = useTranslation("forgotPassword");
	const navigate = useNavigate();
	//prettier-ignore
	const validationSchema = useMemo(() => Yup.object().shape({
		email: Yup.string().required(t("common:required")).email(t("common:errors.email")),
	}), [t]);
	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: () => {
			onHandleForgotPassword();
		},
	});

	const onHandleForgotPassword = async () => {
		let response = null;

		try {
			response = await api.post.forgetPassword(formik.values);
		} catch (error) {
			onHandleRequestError(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			navigate(pathnames.forgotPasswordEmailSent);
		}
	};

	return (
		<div className="app-page page-forgot-password">
			<div className="forgot-password">
				<div className="forgot-password__container">
					<div className="forgot-password__hero-image">
						<img src={heroImage} alt="" />
					</div>
				</div>
				<div className="forgot-password__container">
					<div className="forgot-password__logo">
						<Link to={pathnames.signIn}>
							<img src={logo} alt="" />
						</Link>
					</div>
					<h1 className="forgot-password__title">{t("title")}</h1>
					<p className="forgot-password__sub-title">{t("subTitle")}</p>
					<p className="forgot-password__description">{t("description")}</p>

					<form autoComplete="off" onSubmit={formik.handleSubmit}>
						<AppInput type="text" name="email" placeholder={t("field.0.placeholder")} icon={<IoIosMail />} error={formik.errors.email} touched={formik.touched.email} onChange={formik.handleChange} />

						<AppButton type="submit" label={t("cta")} loading={formik.isSubmitting} />
					</form>
				</div>
			</div>
		</div>
	);
};

export default PageForgotPassword;
