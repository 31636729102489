import React, { memo, useRef, useMemo, useCallback } from "react";
import * as Yup from "yup";
import { compose } from "redux";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import CONSTANSTS from "common/constansts";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppRadioInput from "components/app-radio-input";
import withLightboxModal from "contexts/with-lightbox-modal";

const getUploadLabelClassNames = ({ hasFile }) => {
	const classNames = ["category-form__text"];

	if (!hasFile) classNames.push("category-form__text--upload");

	if (hasFile) classNames.push("category-form__text--remove");

	return classNames.join(" ");
};

const AppCategoryForm = ({ initialValues, type, onHandleDismiss, onHandleConfirm, ...props }) => {
	const { t } = useTranslation("components");
	const uploadImageRef = useRef();
	//prettier-ignore
	const uploadContent = useMemo(() => ({
		image: {
			upload: t("categoryForm.uploadPicture"),
			remove: t("categoryForm.removePicture"),
			text: t("categoryForm.field.1.placeholder"),
			accept: CONSTANSTS.UPLOAD.IMAGES_TYPE,
		},
	}), [t]);
	//prettier-ignore
	const formContent = useMemo(() => ({
		category: {
			title: t("categoryForm.field.0.label"),
			image: t("categoryForm.field.1.label"),
		},
		variation: {
			title: t("categoryForm.field.2.label"),
			image: t("categoryForm.field.1.label"),
		},
	}), [t]);
	//prettier-ignore
	const validationSchema = useMemo(() => Yup.object().shape({
		title: Yup.string().required(t("common:required")),
		image: Yup.mixed()
			.required(t("common:required"))
			.test(CONSTANSTS.UPLOAD.ERRORS[0].type, CONSTANSTS.UPLOAD.ERRORS[0].error, (value) => value?.size <= CONSTANSTS.UPLOAD.SIZES_LIMIT)
			.test(CONSTANSTS.UPLOAD.ERRORS[1].type, CONSTANSTS.UPLOAD.ERRORS[1].error, (value) => CONSTANSTS.UPLOAD.IMAGES_TYPE.includes(value?.type)),
	}), [t]);
	const initValues = useMemo(() => {
		const values = {
			title: "",
			image: "",
			groupId: "",
			categoryId: "",
			variationId: "",
			status: CONSTANSTS.STATUS.ACTIVE,
		};
		if (initialValues) {
			if (initialValues.categoryTitle) values.title = initialValues.categoryTitle;
			if (initialValues.categoryImage) values.image = { name: initialValues.categoryImage, type: CONSTANSTS.UPLOAD.IMAGES_TYPE[0], size: 0 };
			if (initialValues.variantTitle) values.title = initialValues.variantTitle;
			if (initialValues.variantImage) values.image = { name: initialValues.variantImage, type: CONSTANSTS.UPLOAD.IMAGES_TYPE[0], size: 0 };

			if (initialValues.groupId) values.groupId = initialValues.groupId;
			if (initialValues.categoryId) values.categoryId = initialValues.categoryId;
			if (initialValues.variantId) values.variationId = initialValues.variantId;

			if (initialValues.variantStatus) values.status = initialValues.variantStatus;
			if (initialValues.categoryStatus) values.status = initialValues.categoryStatus;
		}

		return values;
	}, [initialValues]);
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: initValues,
		validationSchema,
		onSubmit: (values) => {
			onHandleConfirm(values, enableSubmitButton);
		},
	});
	const errors = formik.errors;
	const touched = formik.touched;
	const values = formik.values;
	const imageHasError = errors.image && touched.image;
	const submitButtonLabel = initialValues ? t("categoryForm.update") : t("categoryForm.create");

	const enableSubmitButton = useCallback(() => {
		formik.setSubmitting(false);
	}, [formik]);

	const onHandleReset = () => {
		formik.resetForm();
		onHandleDismiss();
	};

	//prettier-ignore
	const onHandleSetImage = useCallback((event) => {
		const file = event.currentTarget.files[0]

		if (file) formik.setFieldValue("image", file)
	}, [formik])

	const onHandleUploadImage = useCallback(() => {
		const bannerImage = formik.values.image;

		if (bannerImage) {
			uploadImageRef.current.value = "";
			return formik.setFieldValue("image", "");
		}

		uploadImageRef.current.click();
	}, [formik]);

	return (
		<div className="app-category-form">
			<form className="category-form" autoComplete="off" onSubmit={formik.handleSubmit}>
				<div className="category-form__field">
					<p className="category-form__label">{formContent[type].title}</p>
					<AppInput type="text" name="title" placeholder={formContent[type].title} value={values.title} error={errors.title} touched={touched.title} onChange={formik.handleChange} />
				</div>
				<div className="category-form__field">
					<p className="category-form__label">{formContent[type].image}</p>
					<input hidden type="file" name="image" ref={uploadImageRef} accept={uploadContent.image.accept} onChange={onHandleSetImage} />

					<div className="category-form__upload-container">
						<div className="category-form__upload-content">
							{values.image && (
								<button type="button" className="category-form__view-cta" onClick={() => props.onHandleOpenLightbox(values.image)}>
									<p className="category-form__text category-form__text--name">{values.image.name}</p>
								</button>
							)}

							<button type="button" className="category-form__upload-cta" onClick={onHandleUploadImage}>
								<p className={getUploadLabelClassNames({ hasFile: values.image })}>{values.image ? uploadContent.image.remove : uploadContent.image.upload}</p>
							</button>

							{!values.image && <p className="category-form__text">{uploadContent.image.text}</p>}
						</div>

						{imageHasError && <p className="category-form__text category-form__text--error">{errors.image}</p>}
					</div>
				</div>
				<div className="category-form__field">
					<p className="category-form__label">{t("categoryForm.field.3.label")}</p>
					<AppRadioInput type="radio" name="status" options={CONSTANSTS.OPTIONS.STATUS} value={values.status} onChange={formik.setFieldValue} />
				</div>
				<div className="category-form__button-container">
					<AppButton type="reset" label={t("categoryForm.cancel")} cancel onClick={onHandleReset} />
					<AppButton type="submit" label={submitButtonLabel} loading={formik.isSubmitting} />
				</div>
			</form>
		</div>
	);
};

export default compose(memo, withLightboxModal)(AppCategoryForm);
