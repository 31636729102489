import React, { memo, useState } from "react";
import { cloneDeep } from "lodash";
import { IoRemove } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

import { getTimeStops } from "common/utilities";

const isOpenDefault = { start: false, end: false };
const AppTimeInput = ({ data = [], value, name, touched, error, disabled, note, ...props }) => {
	const { t } = useTranslation("components");
	const [isOpen, setIsOpen] = useState(isOpenDefault);
	const [timeSlot, setTimeSlot] = useState({ start: null, end: null });
	const timeSlots = getTimeStops();
	const startTimeLabel = value?.start?.label || t("time.start");
	const endTimeLabel = value?.end?.label || t("time.end");
	const selectedStart = !!value?.start?.label;
	const selectedEnd = !!value?.end?.label;
	const startTimeInvalid = touched && error?.start;
	const endTimeInvalid = touched && error?.end;

	const onHandleToggleDropdown = (event) => {
		const type = event.target.dataset.type;

		if (type) {
			setIsOpen((prev) => ({ ...prev, [type]: !prev[type] }));
		} else {
			setIsOpen(isOpenDefault);
		}
	};

	const onHandleSelectItem = (item) => (event) => {
		const type = event.target.dataset.type;
		const nextTimeSlot = cloneDeep(timeSlot);

		nextTimeSlot[type] = item;
		props.onChange(name, nextTimeSlot);
		setTimeSlot((prev) => ({ ...prev, [type]: item }));
	};

	return (
		<div className="app-time-input">
			<div className="time-input">
				{/* prettier-ignore */}
				<Dropdown className={getWrapperClassNames({ invalid: startTimeInvalid, disabled })} isOpen={isOpen.start} disabled={disabled} toggle={onHandleToggleDropdown}>
                    <DropdownToggle caret className={getToggleClassNames({ selected: selectedStart })} data-type="start">{startTimeLabel}</DropdownToggle>
                        <DropdownMenu className="time-input__list">
                            {timeSlots.map((item, i) => {
                                return (
                                    <DropdownItem key={i} className="time-input__item" data-type="start" onClick={onHandleSelectItem(item)}>
                                        {item.label}
                                    </DropdownItem>
                                );
                            })}
                        </DropdownMenu>
                </Dropdown>

				<IoRemove size={15} className="time-input__divider" />

				{/* prettier-ignore */}
				<Dropdown className={getWrapperClassNames({ invalid: endTimeInvalid, disabled })} isOpen={isOpen.end} disabled={disabled} toggle={onHandleToggleDropdown}>
                    <DropdownToggle caret className={getToggleClassNames({ selected: selectedEnd })} data-type="end">{endTimeLabel}</DropdownToggle>
                        <DropdownMenu className="time-input__list">
                            {timeSlots.map((item, i) => {
                                return (
                                    <DropdownItem key={i} className="time-input__item" data-type="end" onClick={onHandleSelectItem(item)}>
                                        {item.label}
                                    </DropdownItem>
                                );
                            })}
                        </DropdownMenu>
                </Dropdown>
			</div>

			{startTimeInvalid && endTimeInvalid && (
				<div className="time-input__error-wrapper">
					<p className="time-input__text time-input__text--error">{error?.start}</p>
					<p className="time-input__text time-input__text--error">{error?.end}</p>
				</div>
			)}
			{note && <p className="time-input__text">{note}</p>}
		</div>
	);
};

const getWrapperClassNames = ({ invalid, disabled }) => {
	const classNames = ["time-input__container"];

	if (disabled) classNames.push("time-input__container--disabled");

	if (invalid) classNames.push("time-input__container--error");

	return classNames.join(" ");
};

const getToggleClassNames = ({ selected }) => {
	const classNames = ["time-input__toggle"];

	if (selected) classNames.push("time-input__toggle--active");

	return classNames.join(" ");
};

export default memo(AppTimeInput);
