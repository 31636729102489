const asianCountries = [
    { name: "Malaysia", iso2: "my", dialCode: "60", flag: require("../assets/images/components/app-mobile-input/flags/my.png") },
    { name: "Singapore", iso2: "sg", dialCode: "65", flag: require("../assets/images/components/app-mobile-input/flags/sg.png") },
    { name: "Thailand (ไทย)", iso2: "th", dialCode: "66", flag: require("../assets/images/components/app-mobile-input/flags/th.png") },
    { name: "Afghanistan (‫افغانستان‬‎)", iso2: "af", dialCode: "93", flag: require("../assets/images/components/app-mobile-input/flags/af.png") },
    { name: "Armenia (Հայաստան)", iso2: "am", dialCode: "374", flag: require("../assets/images/components/app-mobile-input/flags/am.png") },
    { name: "Azerbaijan (Azərbaycan)", iso2: "az", dialCode: "994", flag: require("../assets/images/components/app-mobile-input/flags/az.png") },
    { name: "Bahrain (‫البحرين‬‎)", iso2: "bh", dialCode: "973", flag: require("../assets/images/components/app-mobile-input/flags/bh.png") },
    { name: "Bangladesh (বাংলাদেশ)", iso2: "bd", dialCode: "880", flag: require("../assets/images/components/app-mobile-input/flags/bd.png") },
    { name: "Bhutan (འབྲུག)", iso2: "bt", dialCode: "975", flag: require("../assets/images/components/app-mobile-input/flags/bt.png") },
    { name: "British Indian Ocean Territory", iso2: "io", dialCode: "246", flag: require("../assets/images/components/app-mobile-input/flags/io.png") },
    { name: "Brunei", iso2: "bn", dialCode: "673", flag: require("../assets/images/components/app-mobile-input/flags/bn.png") },
    { name: "Cambodia (កម្ពុជា)", iso2: "kh", dialCode: "855", flag: require("../assets/images/components/app-mobile-input/flags/kh.png") },
    { name: "China (中国)", iso2: "cn", dialCode: "86", flag: require("../assets/images/components/app-mobile-input/flags/cn.png") },
    { name: "Egypt (‫مصر‬‎)", iso2: "eg", dialCode: "20", flag: require("../assets/images/components/app-mobile-input/flags/eg.png") },
    { name: "Georgia (საქართველო)", iso2: "ge", dialCode: "995", flag: require("../assets/images/components/app-mobile-input/flags/ge.png") },
    { name: "Hong Kong (香港)", iso2: "hk", dialCode: "852", flag: require("../assets/images/components/app-mobile-input/flags/hk.png") },
    { name: "India (भारत)", iso2: "in", dialCode: "91", flag: require("../assets/images/components/app-mobile-input/flags/in.png") },
    { name: "Indonesia", iso2: "id", dialCode: "62", flag: require("../assets/images/components/app-mobile-input/flags/id.png") },
    { name: "Iran (‫ایران‬‎)", iso2: "ir", dialCode: "98", flag: require("../assets/images/components/app-mobile-input/flags/ir.png") },
    { name: "Iraq (‫العراق‬‎)", iso2: "iq", dialCode: "964", flag: require("../assets/images/components/app-mobile-input/flags/iq.png") },
    { name: "Israel (‫ישראל‬‎)", iso2: "il", dialCode: "972", flag: require("../assets/images/components/app-mobile-input/flags/il.png") },
    { name: "Japan (日本)", iso2: "jp", dialCode: "81", flag: require("../assets/images/components/app-mobile-input/flags/jp.png") },
    { name: "Jordan (‫الأردن‬‎)", iso2: "jo", dialCode: "962", flag: require("../assets/images/components/app-mobile-input/flags/jo.png") },
    { name: "North Korea (조선 민주주의 인민 공화국)", iso2: "kp", dialCode: "850", flag: require("../assets/images/components/app-mobile-input/flags/kp.png") },
    { name: "South Korea (대한민국)", iso2: "kr", dialCode: "82", flag: require("../assets/images/components/app-mobile-input/flags/kr.png") },
    { name: "Kuwait (‫الكويت‬‎)", iso2: "kw", dialCode: "965", flag: require("../assets/images/components/app-mobile-input/flags/kw.png") },
    { name: "Kyrgyzstan (Кыргызстан)", iso2: "kg", dialCode: "996", flag: require("../assets/images/components/app-mobile-input/flags/kg.png") },
    { name: "Laos (ລາວ)", iso2: "la", dialCode: "856", flag: require("../assets/images/components/app-mobile-input/flags/la.png") },
    { name: "Lebanon (‫لبنان‬‎)", iso2: "lb", dialCode: "961", flag: require("../assets/images/components/app-mobile-input/flags/lb.png") },
    { name: "Macau (澳門)", iso2: "mo", dialCode: "853", flag: require("../assets/images/components/app-mobile-input/flags/mo.png") },
    { name: "Maldives", iso2: "mv", dialCode: "960", flag: require("../assets/images/components/app-mobile-input/flags/mv.png") },
    { name: "Mongolia (Монгол)", iso2: "mn", dialCode: "976", flag: require("../assets/images/components/app-mobile-input/flags/mn.png") },
    { name: "Myanmar (Burma)", iso2: "mm", dialCode: "95", flag: require("../assets/images/components/app-mobile-input/flags/mm.png") },
    { name: "Nepal (नेपाल)", iso2: "np", dialCode: "977", flag: require("../assets/images/components/app-mobile-input/flags/np.png") },
    { name: "Oman (‫عُمان‬‎)", iso2: "om", dialCode: "968", flag: require("../assets/images/components/app-mobile-input/flags/om.png") },
    { name: "Pakistan (‫پاکستان‬‎)", iso2: "pk", dialCode: "92", flag: require("../assets/images/components/app-mobile-input/flags/pk.png") },
    { name: "Palestine (‫فلسطين‬‎)", iso2: "ps", dialCode: "970", flag: require("../assets/images/components/app-mobile-input/flags/ps.png") },
    { name: "Philippines", iso2: "ph", dialCode: "63", flag: require("../assets/images/components/app-mobile-input/flags/ph.png") },
    { name: "Qatar (‫قطر‬‎)", iso2: "qa", dialCode: "974", flag: require("../assets/images/components/app-mobile-input/flags/qa.png") },
    { name: "Russia (Россия)", iso2: "ru", dialCode: "7", flag: require("../assets/images/components/app-mobile-input/flags/ru.png") },
    { name: "Saudi Arabia (‫المملكة العربية السعودية‬‎)", iso2: "sa", dialCode: "966", flag: require("../assets/images/components/app-mobile-input/flags/sa.png") },
    { name: "Sri Lanka (ශ්‍රී ලංකාව)", iso2: "lk", dialCode: "94", flag: require("../assets/images/components/app-mobile-input/flags/lk.png") },
    { name: "Syria (‫سوريا‬‎)", iso2: "sy", dialCode: "963", flag: require("../assets/images/components/app-mobile-input/flags/sy.png") },
    { name: "Taiwan (台灣)", iso2: "tw", dialCode: "886", flag: require("../assets/images/components/app-mobile-input/flags/tw.png") },
    { name: "Tajikistan", iso2: "tj", dialCode: "992", flag: require("../assets/images/components/app-mobile-input/flags/tj.png") },
    { name: "Timor-Leste", iso2: "tl", dialCode: "670", flag: require("../assets/images/components/app-mobile-input/flags/tl.png") },
    { name: "Turkmenistan", iso2: "tm", dialCode: "993", flag: require("../assets/images/components/app-mobile-input/flags/tm.png") },
    { name: "United Arab Emirates (‫الإمارات العربية المتحدة‬‎)", iso2: "ae", dialCode: "971", flag: require("../assets/images/components/app-mobile-input/flags/ae.png") },
    { name: "Uzbekistan (Oʻzbekiston)", iso2: "uz", dialCode: "998", flag: require("../assets/images/components/app-mobile-input/flags/uz.png") },
    { name: "Vietnam (Việt Nam)", iso2: "vn", dialCode: "84", flag: require("../assets/images/components/app-mobile-input/flags/vn.png") },
    { name: "Yemen (‫اليمن‬‎)", iso2: "ye", dialCode: "967", flag: require("../assets/images/components/app-mobile-input/flags/ye.png") },
];
export default asianCountries;
