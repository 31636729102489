import React, { memo, Fragment } from "react";
import { IoChevronForward } from "react-icons/io5";
import { Link } from "react-router-dom";
import { getBreadCrumbList } from "common/utilities";

const BreadCrumbItem = ({ item }) => {
	if (item.path) {
		return (
			<Link className="bread-crumb__link" to={item.path}>
				<p className="bread-crumb__item">{item.label}</p>
			</Link>
		);
	}

	return <p className="bread-crumb__item">{item.label}</p>;
};

const AppBreadCrumb = ({ data, mode }) => {
	const breadCrumbData = getBreadCrumbList(data, mode);

	return (
		<div className="app-bread-crumb">
			<div className="bread-crumb">
				{breadCrumbData?.map((item, i) => {
					const isLastItem = i + 1 === breadCrumbData?.length;

					return (
						<Fragment key={i}>
							<BreadCrumbItem item={item} />
							{!isLastItem && <IoChevronForward className="bread-crumb__icon" />}
						</Fragment>
					);
				})}
			</div>
		</div>
	);
};

export default memo(AppBreadCrumb);
