import * as axs from "axios";
import CONSTANSTS from "common/constansts";

const axios = {
	get: async (url, params) => {
		const endPointUrl = getEndPointUrl(url);
		const headers = await getHeaders();

		return axs.get(endPointUrl, { params, ...headers });
	},
	post: async (url, body) => {
		const endPointUrl = getEndPointUrl(url);
		const headers = await getHeaders();

		return axs.post(endPointUrl, body, headers);
	},
	patch: async (url, body) => {
		const endPointUrl = getEndPointUrl(url);
		const headers = await getHeaders();

		return axs.patch(endPointUrl, body, headers);
	},
};

const getHeaders = async () => {
	let headers = { "Content-Type": "application/json" };

	const token = localStorage.getItem(CONSTANSTS.LOCAL_STORAGE.STORAGE_TOKEN);

	if (token) {
		headers = Object.assign({}, headers, {
			Authorization: "Bearer " + token,
		});
	}

	return { headers };
};

const getEndPointUrl = (path) => {
	const endpoint = process.env.REACT_APP_API_URL + path;
	return endpoint;
};

export default axios;
