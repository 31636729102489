import React, { memo, useMemo, useCallback } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import AppInput from "components/app-input";
import AppButton from "components/app-button";

const AppGroupEditForm = ({ initialValues, onHandleDismiss, onHandleConfirm }) => {
	const { t } = useTranslation("components");
	//prettier-ignore
	const validationSchema = useMemo(() => Yup.object().shape({
		groupTitle: Yup.string().required(t("common:required")),
	}), [t]);
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			groupId: initialValues.groupId,
			groupTitle: initialValues.groupTitle,
		},
		validationSchema,
		onSubmit: (values) => {
			onHandleConfirm(values, enableSubmitButton);
		},
	});

	const enableSubmitButton = useCallback(() => {
		formik.setSubmitting(false);
	}, [formik]);

	const onHandleReset = () => {
		formik.resetForm();
		onHandleDismiss();
	};

	return (
		<div className="app-group-edit-form">
			<form className="group-edit-form" autoComplete="off" onSubmit={formik.handleSubmit}>
				<div className="group-edit-form__field">
					<p className="group-edit-form__label">{t("groupForm.field.0.label")}</p>
					<AppInput type="text" name="groupTitle" placeholder={t("groupForm.field.0.placeholder")} value={formik.values.groupTitle} error={formik.errors.groupTitle} touched={formik.touched.groupTitle} onChange={formik.handleChange} />
				</div>

				<div className="group-edit-form__button-container">
					<AppButton type="reset" label={t("groupForm.cancel")} cancel onClick={onHandleReset} />
					<AppButton type="submit" label={t("groupForm.update")} loading={formik.isSubmitting} />
				</div>
			</form>
		</div>
	);
};

export default memo(AppGroupEditForm);
