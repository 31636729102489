import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	error: false,
};

export const emptyStateSlice = createSlice({
	name: "emptyState",
	initialState,
	reducers: {
		updateErrorStatus: (state) => {
			return { ...state, error: true };
		},
		reinitializeStatus: () => initialState,
	},
});

export const { updateErrorStatus, reinitializeStatus } = emptyStateSlice.actions;

export default emptyStateSlice.reducer;
