import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import pathnames from "routes/pathnames";
import logo from "assets/images/menara-logo.svg";
import heroImage from "assets/images/hero-image.svg";

const PageExpiredLink = () => {
	const { t } = useTranslation("expiredLink");

	return (
		<div className="app-page page-expired-link">
			<div className="expired-link">
				<div className="expired-link__container">
					<div className="expired-link__hero-image">
						<img src={heroImage} alt="" />
					</div>
				</div>
				<div className="expired-link__container">
					<div className="expired-link__logo">
						<Link to={pathnames.signIn}>
							<img src={logo} alt="" />
						</Link>
					</div>
					<h1 className="expired-link__title">{t("title")}</h1>
					<p className="expired-link__sub-title">{t("subTitle")}</p>
					<p className="expired-link__description">{t("description")}</p>
				</div>
			</div>
		</div>
	);
};

export default PageExpiredLink;
