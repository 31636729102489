const en = {
	common: {
		required: "This field is required",
		loading: "Loading",
		unsaved: "You will lose all unsaved changes. Are you sure want to exit?",
		search: {
			loading: "Searching",
			empty: "Sorry, we couldn't find any matches.",
			error: "Sorry, there is no available data to display.",
		},
		errors: {
			email: "Please enter valid email address",
			password: "Password does not meet the requirements",
			confirmPassword: "Password confirmation doesn't match",
			phoneNumber: "Phone Number is invalid",
			redemptionPoints: "Invalid redemption points",
			validityPeriod: "Validity period must be greater than or equal to 1",
			fileSize: "File Size is too large",
			fileType: "Unsupported File Format",
			fileLimit: "File format can be jpeg or png, maximum size 5mb and maximum 5 pictures.",
		},
		status: {
			all: "All",
			active: "Active",
			inactive: "Inactive",
			pendingVerification: "Pending Verification",
			expired: "Expired",
			completed: "Completed",
			moved: "Moved",
			passedAway: "Passed Away",
			none: "None",
		},
		states: {
			all: "All",
			johor: "Johor",
			kedah: "Kedah",
			kelantan: "Kelantan",
			malacca: "Malacca",
			negeriSembilan: "Negeri Sembilan",
			pahang: "Pahang",
			penang: "Penang",
			perak: "Perak",
			perlis: "Perlis",
			sabah: "Sabah",
			sarawak: "Sarawak",
			selangor: "Selangor",
			terengganu: "Terengganu",
			kualaLumpur: "Kuala Lumpur",
			labuan: "Labuan",
			putrajaya: "Putrajaya",
		},
		recipients: {
			customer: "Customer",
			admin: "Admin",
		},
	},
	components: {
		allocatePointsModal: {
			title: "Credit Points",
			field: [
				{ label: "Invoice Number (Optional)", placeholder: "Enter invoice number" },
				{ label: "Credit points", placeholder: "Enter amount" },
			],
			cancel: "Cancel",
			cta: "Update",
		},
		deactivateRewardModal: {
			title: "Deactivate Reward",
			description: "Are you sure to deactivate this reward?",
			cancel: "Cancel",
			cta: "Confirm",
		},
		purchaseHistoryForm: {
			title: "Add New Purchase",
			field: [
				{
					label: "Outlet Code & Name",
					placeholder: "Select Outlet",
				},
				{
					label: "Purchase Date",
					placeholder: "Select date",
				},
				{
					label: "Number of Purchase",
					placeholder: "Number of Purchase",
				},
				{
					label: "Prescription Range",
					placeholder: "Select prescription range",
				},
				{
					label: "Index",
					placeholder: "Select index",
				},
				{
					label: "Lenses Prescribed",
					placeholder: "Lenses Prescribed",
				},
				{
					label: "Lens Accessories",
					placeholder: "Select lens accessories",
				},
				{
					label: "Lens Type",
					placeholder: "Lens Type",
				},
				{
					label: "CL Prescribed",
					placeholder: "CL Prescribed",
				},
				{
					label: "CL Type",
					placeholder: "CL Type",
				},
				{
					label: "CL Material",
					placeholder: "CL Material",
				},
			],
			update: "Update",
			create: "Create",
			cancel: "Cancel",
		},
		eyeExaminationForm: {
			title: "Add New Eye Examination",
			field: [
				{
					label: "Outlet Code & Name",
					placeholder: "Select Outlet",
				},
				{
					label: "Examination Date",
					placeholder: "Select date",
				},
				{
					label: "Rx",
					placeholder: "Rx",
				},
				{
					label: "Case/Appointmen",
					placeholder: "Select case/appointmen",
				},
				{
					label: "Examination by",
					placeholder: "Select examination by",
				},
			],
			update: "Update",
			create: "Create",
			cancel: "Cancel",
		},
		groupForm: {
			title: "Add New Group",
			field: [{ label: "Group Name", placeholder: "Group Name" }],
			update: "Update",
			create: "Create",
			cancel: "Cancel",
		},
		categoryForm: {
			field: [
				{ label: "Category Name", placeholder: "Category Name" },
				{ label: "Category Picture", placeholder: "(* File format can be jpeg or png, maximum size 5MB.)" },
				{ label: "Subcategory Name", placeholder: "Subcategory Name" },
				{ label: "Status", placeholder: "Status" },
			],
			uploadPicture: "Upload Picture",
			removePicture: "Remove Picture",
			cancel: "Cancel",
			update: "Update",
			create: "Create",
		},
		statusGroupModal: {
			inactive: {
				title: "Activate",
				description: "Are you sure want to activate?",
			},
			active: {
				title: "Deactivate",
				description: "Are you sure want to deactivate?",
			},
			cancel: "Cancel",
			cta: "Confirm",
		},
		subBannerModal: {
			title: "Add New Sub Banner",
			field: [
				{ label: "Sub Banner Title", placeholder: "Sub Banner Title" },
				{ label: "Sub Banner Picture", placeholder: "(* File format can be jpeg or png, maximum size 5MB.)" },
				{ label: "PDF Content", placeholder: "(* File format must be pdf, maximum size 5MB.)" },
				{ label: "Description", placeholder: "Sub banner description" },
			],
			tooltipsPDF: "The PDF content will only be applicable when there is no description.",
			tooltips: "The Description will only be applicable when there is non PDF content uploaded.",
			cancel: "Cancel",
			update: "Update",
			create: "Create",
		},
		faqForm: {
			title: "New FAQ",
			field: [
				{ label: "FAQ Title", placeholder: "Frequently asked question" },
				{ label: "FAQ Description", placeholder: "Answer for frequently asked question" },
			],
			cancel: "Cancel",
			update: "Update",
			create: "Create",
		},
		faqRemoveModal: {
			title: "Remove FAQ",
			description: "Are you sure to remove this FAQ? This action cannot be undone.",
			cancel: "Cancel",
			cta: "Yes, remove",
		},
		passwordForm: {
			title: "Change Password",
			field: [
				{ label: "Current Password", placeholder: "Enter current password" },
				{ label: "New Password", placeholder: "New Password" },
				{ label: "Confirm New Password", placeholder: "Confirm New Password" },
			],
			editSuccess: "Password has been updated.",
			cancel: "Cancel",
			cta: "Update",
		},
		notificationModal: {
			title: "Notifications",
			cta: "See More",
		},
		unsavedBlocker: {
			title: "Exit Without Saving",
			description: "You will lose all unsaved changes. Are you sure want to exit?",
			cancel: "Cancel",
			cta: "Yes, exit",
		},
		logoutModal: {
			title: "Logout",
			description: "Do you confirm to log out?",
			cancel: "Cancel",
			cta: "Log out",
		},
		blockedModal: {
			title: "Account Deactivated (BM)",
			description: "Your account has been deactivated. If you’d like to reactivate your account, please contact our support team. (BM)",
			cta: "Okay (BM)",
		},
		uploadInput: {
			pdfUpload: "Upload PDF",
			pdfRemove: "Remove PDF",
			pictureUpload: "Upload Picture",
			pictureRemove: "Remove Picture",
		},
		textarea: {
			max: "(max {{currentChar}} / {{max}} characters)",
		},
		pagination: {
			showing: "Show 10 items per page",
			page: "Page",
			of: "of {{page}}",
		},
		time: {
			start: "Time start",
			end: "Time end",
		},
	},
	notifications: {
		back: "Back",
	},
	signIn: {
		title: "Admin Portal",
		subTitle: "Log In",
		forgotPassword: "Forgot Password?",
		field: [
			{
				placeholder: "Email",
			},
			{
				placeholder: "Password",
			},
		],
		error: "Incorrect email or password",
		cta: "Log In",
	},
	forgotPassword: {
		title: "Admin Portal",
		subTitle: "Forgot Password",
		description: "Please enter the email address associated with your account.",
		field: [
			{
				placeholder: "Email",
			},
		],
		cta: "Get Temporary Password",
	},
	resetPassword: {
		title: "Admin Portal",
		subTitle: "Reset Password",
		field: [
			{
				placeholder: "Enter new password",
			},
			{
				placeholder: "Confirm new password",
			},
		],
		requirement: [
			{
				label: "Use at least 8 characters",
			},
			{
				label: "Should contain at least 1 uppercase",
			},
			{
				label: "Should contain at least 1 lowercase",
			},
			{
				label: "Should contain at least 1 numeric",
			},
			{
				label: "Should contain at least 1 special character",
			},
		],
		cta: "Reset Password",
	},
	emailSent: {
		title: "Admin Portal",
		subTitle: "Forgot Password",
		description: "Please check your email to get the temporary link.",
		cta: "Done",
	},
	passwordUpdated: {
		title: "Admin Portal",
		subTitle: "Password Updated",
		description: "Your password has been reset successfully. Please use your new password to log in.",
		cta: "Log in",
	},
	expiredLink: {
		title: "Admin Portal (BM)",
		subTitle: "Expired Link (BM)",
		description: "Your temporary link has expired. (BM)",
	},
	notFound: {
		title: "Page not found",
	},
	customers: {
		title: "Customers",
		showing: "Showing all {{total}} results",
		search: "Search by User ID, customer email, customer mobile",
		table: {
			id: "User ID",
			name: "Name",
			email: "Email",
			PhoneNumber: "Phone Number",
			points: "Points",
			status: "Status",
		},
	},
	customer: {
		breadCrumb: [{ label: "Customer List" }, { label: "Customer Info" }],
		tabs: [{ label: "General Info" }, { label: "Purchase Transaction History" }, { label: "Eye Examination History" }, { label: "Points History" }, { label: "Voucher" }],
		title: "General Info",
		creditPoints: "Credit Points",
		uploadCta: {
			view: "View Profile Picture",
			update: "Update Profile Picture",
		},
		field: [
			{
				label: "Signed Up App",
				placeholder: "Signed Up App",
			},
			{
				label: "User ID",
				placeholder: "User ID",
			},
			{
				label: "Migrated Family Code & Customer ID",
				placeholder: "Migrated Family Code & Customer ID",
			},
			{
				label: "Display Name",
				placeholder: "Display Name",
			},
			{
				label: "Full Name",
				placeholder: "Full Name",
			},
			{
				label: "Migrated Email Address",
				placeholder: "Migrated Email Address",
			},
			{
				label: "Sign Up Email Address",
				placeholder: "Sign Up Email Address",
			},
			{
				label: "D.O.B",
				placeholder: "Select Date of Birth",
			},
			{
				label: "Phone Number",
				placeholder: "Phone Number",
			},
			{
				label: "Address Line 1",
				placeholder: "Address Line 1",
			},
			{
				label: "Address Line 2",
				placeholder: "Address Line 2",
			},
			{
				label: "Postcode",
				placeholder: "Postcode",
			},
			{
				label: "City",
				placeholder: "City",
			},
			{
				label: "State",
				placeholder: "Select state",
			},
			{
				label: "Country",
				placeholder: "Select country",
			},
			{
				label: "Occupation",
				placeholder: "Occupation",
			},
			{
				label: "Remarks",
				placeholder: "Remarks",
			},
			{
				label: "Date Joined",
				placeholder: "Date Joined",
			},
			{
				label: "Current Points",
				placeholder: "Current Points",
			},
			{
				label: "Status",
				placeholder: "Status",
			},
			{
				label: "Sub Status",
				placeholder: "Sub Status",
			},
		],
		success: "Customer has been updated.",
		pointsSuccess: "Customer Allocate Points has been updated.",
		cancel: "Cancel",
		cta: "Update",
	},
	purchaseHistory: {
		breadCrumb: [{ label: "Customer List" }, { label: "Customer Info" }],
		tabs: [{ label: "General Info" }, { label: "Purchase Transaction History" }, { label: "Eye Examination History" }, { label: "Points History" }, { label: "Voucher" }],
		title: "Purchase Transaction History",
		showing: "Showing all {{total}} results",
		newCta: "New Purchase",
		table: {
			purchaseDate: "Purchase Date",
			outlet: "Outlet",
		},
		editSuccess: "Customer purchase history has been updated.",
		createSuccess: "Customer purchase history has been created.",
		formTitle: "Purchase Transaction Details",
	},
	eyeExamination: {
		breadCrumb: [{ label: "Customer List" }, { label: "Customer Info" }],
		tabs: [{ label: "General Info" }, { label: "Purchase Transaction History" }, { label: "Eye Examination History" }, { label: "Points History" }, { label: "Voucher" }],
		title: "Eye Examination History",
		showing: "Showing all {{total}} results",
		newCta: "New Eye Examination",
		table: {
			examinationDate: "Examination Date",
			outlet: "Outlet",
		},
		editSuccess: "Customer eye examination has been updated.",
		createSuccess: "Customer eye examination has been created.",
		formTitle: "Eye Examination Details",
	},
	pointsHistory: {
		breadCrumb: [{ label: "Customer List" }, { label: "Customer Info" }],
		title: "Points History",
		showing: "Showing all {{total}} results",
		table: {
			date: "Date",
			transaction: "Transactions",
			invoiceNumber: "Invoice Number",
			updatedBy: "Updated by",
			totalPoints: "Total Points: {{points}}",
		},
	},
	vouchers: {
		breadCrumb: [{ label: "Customer List" }, { label: "Customer Info" }],
		title: "Voucher",
		showing: "Showing all {{total}} results",
		table: {
			voucherCode: "Voucher Code",
			voucherTitle: "Voucher Title",
			status: "Status",
			dateUsed: "Date used",
			redemptionDate: "Redemption Date",
			expiredDate: "Expired Date",
		},
	},
	loyaltyPrograms: {
		title: "Reward List",
		showing: "Showing all {{total}} results",
		search: "Search by reward code, reward name",
		newCta: "New Reward",
		table: {
			code: "Reward Code",
			name: "Reward Name",
			createdDate: "Date Created",
			redemptionPoint: "Redemption Point",
			status: "Status",
		},
	},
	loyaltyProgram: {
		breadCrumb: [{ label: "Reward List" }, { label: "New Reward" }, { label: "Reward Info" }],
		title: "Reward Info",
		field: [
			{
				label: "Reward Code",
				placeholder: "Reward code, eg : ABCD, RAYA, XMAS",
			},
			{
				label: "Reward Name",
				placeholder: "Reward name or title",
			},
			{
				label: "Reward Picture",
				placeholder: "* File format can be jpeg or png, maximum size 5MB.",
			},
			{
				label: "Description",
				placeholder: "Describe about reward",
			},
			{
				label: "Voucher Title",
				placeholder: "Voucher title",
			},
			{
				label: "Voucher Subtitle",
				placeholder: "Voucher subtitle",
			},
			{
				label: "Voucher Description",
				placeholder: "Voucher description",
			},
			{
				label: "Redemption Point",
				placeholder: "Redemption point",
			},
			{
				label: "Start Date",
				placeholder: "Select start date",
			},
			{
				label: "End Date",
				placeholder: "Select end date",
			},
			{
				label: "Validity Period (Day)",
				placeholder: "(Validity period based on start and end date)",
			},
			{
				label: "Status",
				placeholder: "status",
			},
		],
		editSuccess: "Reward has been updated.",
		createSuccess: "Reward has been created.",
		cancel: "Cancel",
		update: "Update",
		create: "Create",
	},
	groups: {
		title: "Group List",
		showing: "Showing all {{total}} results",
		newCta: "New Group",
		groupEditSuccess: "Group has been updated.",
		groupCreateSuccess: "Group has been created.",
		categoryEditSuccess: "Category has been updated.",
		categoryCreateSuccess: "Category has been created.",
		variantEditSuccess: "Variation has been updated.",
		variantCreateSuccess: "Variation has been created.",
		newCategoryCta: "New Category",
		newSubCategoryCta: "New Subcategory",
	},
	products: {
		title: "Product List",
		showing: "Showing all {{total}} results",
		search: "Search by product name",
		newCta: "New Product",
		table: {
			sku: "SKU",
			name: "Product Name",
			category: "Category",
			subcategory: "Subcategory",
			status: "Status",
		},
	},
	product: {
		breadCrumb: [{ label: "Product List" }, { label: "Create Product" }, { label: "Product Details" }],
		title: "Product Details",
		field: [
			{
				label: "SKU",
				placeholder: "(system autogenerate)",
			},
			{
				label: "Product Name",
				placeholder: "Product Name",
			},
			{
				label: "Category",
				placeholder: "Select Category",
			},
			{
				label: "Subcategory",
				placeholder: "Select Subcategory",
			},
			{
				label: "Product Picture",
				placeholder: "(* File format can be jpeg or png, maximum size 5MB.)",
			},
			{
				label: "PDF Content",
				placeholder: "(* File format must be pdf, maximum size 5MB.)",
			},
			{
				label: "Description",
				placeholder: "Describe about reward",
			},
			{
				label: "Status",
				placeholder: "Status",
			},
		],
		editSuccess: "Product has been updated.",
		createSuccess: "Product has been created.",
		cancel: "Cancel",
		update: "Update",
		create: "Create",
	},
	banners: {
		title: "Banner List",
		showing: "Showing all {{total}} results",
		search: "Search by banner title",
		newCta: "New Banner",
		table: {
			name: "Banner title",
			status: "Status",
		},
	},
	banner: {
		breadCrumb: [{ label: "Banner List" }, { label: "New Banner" }, { label: "Banner Info" }],
		title: "Banner Info",
		newSubBanner: "New Sub Banner",
		tooltipsPDF: "The PDF content will only be applicable when there is non sub banner uploaded.",
		tooltips: "The Description will only be applicable when there is non sub banner uploaded.",
		field: [
			{
				label: "Banner Title",
				placeholder: "Banner Title",
			},
			{
				label: "Sub Banner",
				placeholder: "Sub Banner",
			},
			{
				label: "Banner Picture",
				placeholder: "* File format can be jpeg or png, maximum size 5MB.)",
			},
			{
				label: "PDF Content",
				placeholder: "(* File format must be pdf, maximum size 5MB.)",
			},
			{
				label: "Description",
				placeholder: "Banner description",
			},
			{
				label: "Status",
				placeholder: "Status",
			},
		],
		editSuccess: "Banner has been updated.",
		createSuccess: "Banner has been created.",
		cancel: "Cancel",
		update: "Update",
		create: "Create",
	},
	outlets: {
		title: "Outlet List",
		showing: "Showing all {{total}} results",
		search: "Search by outlet code, outlet name, person in charge",
		newCta: "New Outlet",
		table: {
			code: "Outlet Code",
			name: "Outlet Name",
			pic: "Person in Charge",
			state: "State",
			phone: "Outlet Phone Number",
			status: "Status",
		},
	},
	outlet: {
		breadCrumb: [{ label: "Outlet List" }, { label: "New Outlet" }, { label: "Outlet Info" }],
		title: "Outlet Info",
		uploadPicture: "Upload Picture",
		removePicture: "Remove Picture",
		field: [
			{
				label: "Outlet code",
				placeholder: "Outlet code",
			},
			{
				label: "Outlet Name",
				placeholder: "Outlet or store name",
			},
			{
				label: "Website URL",
				placeholder: "Website Link",
			},
			{
				label: "Address Line 1",
				placeholder: "Eg unit no, apartment, suite",
			},
			{
				label: "Address Line 2",
				placeholder: "Eg street",
			},
			{
				label: "Postcode",
				placeholder: "Postcode",
			},
			{
				label: "City",
				placeholder: "City",
			},
			{
				label: "State",
				placeholder: "Select state",
			},
			{
				label: "Country",
				placeholder: "Country",
			},
			{
				label: "Operation Hours",
				placeholder: "Operation Hours",
			},
			{
				label: "Outlet Photo",
				placeholder: "(* File format can be jpeg or png, maximum size 5MB and maximum 5 pictures.)",
			},
			{
				label: "Outlet Phone Number",
				placeholder: "Outlet Phone Number",
			},
			{
				label: "Person in charge",
				placeholder: "Person in charge for the outlet",
			},
			{
				label: "Status",
				placeholder: "Status",
			},
		],
		editSuccess: "Banner has been updated.",
		createSuccess: "Banner has been created.",
		cancel: "Cancel",
		update: "Update",
		create: "Create",
	},
	faqs: {
		title: "FAQ List",
		showing: "Showing all {{total}} results",
		removeSuccess: "FAQ has been removed.",
		editSuccess: "FAQ has been updated.",
		createSuccess: "FAQ has been created.",
		newCta: "New FAQ",
	},
	announcements: {
		title: "Announcement List",
		showing: "Showing all {{total}} results",
		search: "Search by announcement title",
		newCta: "New Announcement",
		table: {
			name: "Announcement Title",
			date: "Announcement Date & Time",
		},
	},
	announcement: {
		breadCrumb: [{ label: "Announcement List" }, { label: "New Announcement" }, { label: "Announcement Info" }],
		title: "Announcement Info",
		field: [
			{
				label: "Title",
				placeholder: "Title",
			},
			{
				label: "Description",
				placeholder: "Description",
			},
			{
				label: "Recipient",
				placeholder: "Recipient",
			},
		],
		editSuccess: "Announcement has been updated.",
		createSuccess: "Announcement has been created.",
		cancel: "Cancel",
		update: "Update",
		create: "Create",
	},
	settings: {
		tabs: [{ label: "My Profile" }, { label: "Helpdesk" }],
		title: "My Profile",
		editSuccess: "Profile has been updated.",
		field: [
			{
				label: "Admin ID",
				placeholder: "Admin ID",
			},
			{
				label: "Name",
				placeholder: "Name",
			},
			{
				label: "Email Address",
				placeholder: "Email Address",
			},
			{
				label: "Role",
				placeholder: "Role",
			},
			{
				label: "Phone Number",
				placeholder: "Phone Number",
			},
			{
				label: "Store Name",
				placeholder: "Store Name",
			},
		],
		uploadCta: {
			view: "View Profile Picture",
			update: "Update Profile Picture",
		},
		cancel: "Cancel",
		update: "Update",
	},
	helpdesk: {
		tabs: [{ label: "My Profile" }, { label: "Helpdesk" }],
		title: "Manage Helpdesk",
		description: "Configure your email address for receiving customer enquiries.",
		editSuccess: "Helpdesk has been updated.",
		field: [
			{
				label: "Email Address",
				placeholder: "Email Address",
			},
		],
		cancel: "Cancel",
		update: "Update",
	},
	users: {
		title: "Admin List",
		showing: "Showing all {{total}} results",
		search: "Search by admin ID, admin name, admin phone number, store name",
		newCta: "New Admin",
		table: {
			name: "Admin Name",
			id: "Admin ID",
			email: "Email",
			phoneNumber: "Phone Number",
			store: "Store Name",
			status: "Status",
		},
	},
	user: {
		breadCrumb: [{ label: "Admin List" }, { label: "New Admin" }, { label: "Admin Info" }],
		title: "Admin Info",
		uploadCta: {
			view: "View Profile Picture",
			update: "Update Profile Picture",
		},
		field: [
			{ label: "Admin ID", placeholder: "Admin ID" },
			{ label: "Name", placeholder: "Name" },
			{ label: "Email Address", placeholder: "Email Address" },
			{ label: "Role", placeholder: "Role" },
			{ label: "Phone Number", placeholder: "Phone Number" },
			{ label: "Store Name", placeholder: "Store Name" },
			{ label: "Status", placeholder: "Status" },
		],
		editSuccess: "Profile has been updated.",
		createSuccess: "Profile has been created.",
		cancel: "Cancel",
		update: "Update",
		create: "Create",
	},
};
export default en;
