import React, { memo, useMemo, useState, useCallback, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import api from "services/api";
import CONSTANSTS from "common/constansts";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppDropdown from "components/app-dropdown";
import AppDateInput from "components/app-date-input";
import { onHandleRequestError } from "common/utilities";

const getLabelClassNames = ({ important }) => {
	const classNames = ["eye-examination-form__label"];

	if (important) classNames.push("eye-examination-form__label--important");

	return classNames.join(" ");
};

const AppEyeExaminationForm = ({ eyeExamination, onHandleDismiss, onHandleConfirm }) => {
	const { t } = useTranslation("components");
	const [outlets, setOutlets] = useState([]);
	//prettier-ignore
	const validationSchema = useMemo(() => Yup.object().shape({
		outlet: Yup.object().required(t("common:required")),
		examinationDate: Yup.date().required(t("common:required")),
	}), [t]);
	const initialValues = useMemo(() => {
		const values = {
			outlet: "",
			examinationDate: "",
			rx: "",
			appointment: "",
			examinationBy: "",
		};

		if (eyeExamination) {
			if (eyeExamination.outletId) values.outlet = outlets.filter((o) => o.value === eyeExamination.outletId)[0];
			if (eyeExamination.examinationDate) values.examinationDate = new Date(eyeExamination.examinationDate);
			if (eyeExamination.rx) values.rx = eyeExamination.rx;
			if (eyeExamination.caseAppointment) values.appointment = CONSTANSTS.OPTIONS.APPOINTMENTS.filter((o) => o.value === eyeExamination.caseAppointment)[0];
			if (eyeExamination.examinationBy) values.examinationBy = eyeExamination.examinationBy;
		}

		return values;
	}, [eyeExamination, outlets]);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues,
		validationSchema,
		onSubmit: (values) => {
			onHandleConfirm(values, enableSubmitButton);
		},
	});

	const enableSubmitButton = useCallback(() => {
		formik.setSubmitting(false);
	}, [formik]);

	useEffect(() => {
		getOutlets();
	}, []);

	const getOutlets = async () => {
		const payload = { length: 50, page: 0, status: CONSTANSTS.STATUS.ACTIVE, order: "desc" };

		let response = null;

		try {
			response = await api.get.outlets(payload);
		} catch (error) {
			onHandleRequestError(error);
		}

		if (response) {
			const nextOutlets = response.data.result.storeResponseList;
			const dropdownOutlets = nextOutlets.map((o) => ({ label: o?.storeCode + " " + o.storeName, value: o.storeId }));
			setOutlets(dropdownOutlets);
		}
	};

	const form = useMemo(() => {
		const fields = [
			{
				name: "outlet",
				label: t("eyeExaminationForm.field.0.label"),
				placeholder: t("eyeExaminationForm.field.0.placeholder"),
				options: outlets,
				important: true,
				disabled: false,
				type: "select",
			},
			{
				name: "examinationDate",
				label: t("eyeExaminationForm.field.1.label"),
				placeholder: t("eyeExaminationForm.field.1.placeholder"),
				important: true,
				maxDate: new Date(),
				disabled: false,
				type: "date",
			},
			{
				name: "rx",
				label: t("eyeExaminationForm.field.2.label"),
				placeholder: t("eyeExaminationForm.field.2.placeholder"),
				disabled: false,
				type: "text",
			},
			{
				name: "appointment",
				label: t("eyeExaminationForm.field.3.label"),
				placeholder: t("eyeExaminationForm.field.3.placeholder"),
				options: CONSTANSTS.OPTIONS.APPOINTMENTS,
				disabled: false,
				type: "select",
			},
			{
				name: "examinationBy",
				label: t("eyeExaminationForm.field.4.label"),
				placeholder: t("eyeExaminationForm.field.4.placeholder"),
				disabled: false,
				type: "text",
			},
		];
		return fields;
	}, [t, outlets]);

	return (
		<div className="app-eye-examination-form">
			<form className="eye-examination-form" autoComplete="off" onSubmit={formik.handleSubmit}>
				{form.map(({ label, important, ...res }, i) => {
					const value = formik.values[res.name];
					const error = formik.errors[res.name];
					const touched = formik.touched[res.name];
					const isDateField = res.type === "date";
					const isDropdownField = res.type === "select";

					if (isDropdownField) {
						return (
							<div key={i} className="eye-examination-form__field">
								<p className={getLabelClassNames({ important })}>{label}</p>
								<AppDropdown {...res} value={value} error={error} touched={touched} data={res.options} onChange={formik.setFieldValue} />
							</div>
						);
					}

					if (isDateField) {
						return (
							<div key={i} className="eye-examination-form__field">
								<p className={getLabelClassNames({ important })}>{label}</p>
								<AppDateInput {...res} value={value} error={error} touched={touched} onChange={formik.setFieldValue} />
							</div>
						);
					}

					return (
						<div key={i} className="eye-examination-form__field">
							<p className="eye-examination-form__label">{label}</p>
							<AppInput {...res} value={value} error={error} touched={touched} onChange={formik.handleChange} />
						</div>
					);
				})}

				<div className="eye-examination-form__button-container">
					<AppButton type="reset" label={t("eyeExaminationForm.cancel")} cancel onClick={onHandleDismiss} />
					<AppButton type="submit" label={t("eyeExaminationForm.update")} loading={formik.isSubmitting} />
				</div>
			</form>
		</div>
	);
};

export default memo(AppEyeExaminationForm);
