import React, { memo } from "react";
import { Spinner } from "reactstrap";

const getButtonClassName = ({ loading, cancel }) => {
	const classNames = ["app-cta"];

	if (cancel) classNames.push("app-cta--cancel");

	if (loading) classNames.push("app-cta--loading");

	return classNames.join(" ");
};

const AppButton = ({ label, loading, disabled, cancel, ...props }) => {
	const disabledButton = loading || disabled;

	return (
		<button className={getButtonClassName({ loading, cancel })} disabled={disabledButton} {...props}>
			<div className="app-cta__container">
				{loading && !cancel && <Spinner className="app-cta__spinner" as="span" size="sm" role="status" aria-hidden="true" />}
				<span className="app-cta__label">{label}</span>
			</div>
		</button>
	);
};

export default memo(AppButton);
