import React, { memo } from "react";
import { BsFillCaretDownFill } from "react-icons/bs";

import { ReactComponent as EditIcon } from "assets/images/edit-icon.svg";

const getCaretIconClassNames = ({ isActive }) => {
	const classNames = ["tab__icon"];

	if (isActive) classNames.push("tab__icon--caret");

	return classNames.join(" ");
};

const getAccordionTabClassName = ({ type }) => {
	const classNames = ["tab"];

	switch (type) {
		case "category":
			classNames.push("tab--category");
			break;
		case "variation":
			classNames.push("tab--variation");
			break;
		case "group":
			break;
		default:
			break;
	}

	return classNames.join(" ");
};

const getTitleClassName = ({ status }) => {
	const classNames = ["tab__title"];

	switch (status) {
		case "inactive":
			classNames.push("tab__title--inactive");
			break;
		default:
			break;
	}

	return classNames.join(" ");
};

const getTableEditButtonClassNames = ({ viewMore }) => {
	const classNames = ["tab__edit-cta"];

	if (!viewMore) classNames.push("tab__edit-cta--expand");

	return classNames.join(" ");
};

const AppGroupTab = ({ title, type, status, onHandleEdit, onHandleToggle, isActive }) => {
	return (
		<div className="app-tab">
			<div className={getAccordionTabClassName({ type })}>
				<p className={getTitleClassName({ status })}>{title}</p>
				
				<div className="tab__action">
					<button className={getTableEditButtonClassNames({ viewMore: !!onHandleToggle })} data-type={type} onClick={onHandleEdit}>
						<EditIcon className="tab__icon tab__icon--edit" />
					</button>
					{onHandleToggle && (
						<button className="tab__caret-cta" onClick={onHandleToggle}>
							<BsFillCaretDownFill className={getCaretIconClassNames({ isActive })} />
						</button>
					)}
				</div>
			</div>
		</div>
	);
};

export default memo(AppGroupTab);
