import React, { memo, useMemo, useState, useCallback, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import api from "services/api";
import CONSTANSTS from "common/constansts";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppDropdown from "components/app-dropdown";
import AppDateInput from "components/app-date-input";
import { onHandleRequestError } from "common/utilities";

const getLabelClassNames = ({ important }) => {
	const classNames = ["purchase-history-form__label"];

	if (important) classNames.push("purchase-history-form__label--important");

	return classNames.join(" ");
};

const AppPurchaseHistoryForm = ({ purchaseHistory, onHandleDismiss, onHandleConfirm }) => {
	const { t } = useTranslation("components");
	const [outlets, setOutlets] = useState([]);
	//prettier-ignore
	const validationSchema = useMemo(() => Yup.object().shape({
		outlet: Yup.object().required(t("common:required")),
		purchaseDate: Yup.date().required(t("common:required")),
	}), [t]);

	const initialValues = useMemo(() => {
		const values = {
			outlet: "",
			purchaseDate: "",
			numberOfPurchase: "",
			prescriptionRange: "",
			index: "",
			lensPrescribed: "",
			lensAccessories: "",
			lensType: "",
			clPrescribed: "",
			clType: "",
			clMaterial: "",
		};

		if (purchaseHistory) {
			if (purchaseHistory.outletId) values.outlet = outlets.filter((o) => o.value === purchaseHistory.outletId)[0];
			if (purchaseHistory.purchaseDate) values.purchaseDate = new Date(purchaseHistory.purchaseDate);
			if (purchaseHistory.numberOfPurchase) values.numberOfPurchase = purchaseHistory.numberOfPurchase;
			if (purchaseHistory.prescriptionRange) values.prescriptionRange = CONSTANSTS.OPTIONS.PRESCRIPTION_RANGE.filter((o) => o.value === purchaseHistory.prescriptionRange)[0];
			if (purchaseHistory.indexNum) values.index = CONSTANSTS.OPTIONS.INDEX.filter((o) => o.value === purchaseHistory.indexNum)[0];
			if (purchaseHistory.lensPrescribed) values.lensPrescribed = purchaseHistory.lensPrescribed;
			if (purchaseHistory.lensAccessories) values.lensAccessories = CONSTANSTS.OPTIONS.LENS_ACCESSORIES.filter((o) => o.value === purchaseHistory.lensAccessories)[0];
			if (purchaseHistory.lensType) values.lensType = CONSTANSTS.OPTIONS.LENS_TYPE.filter((o) => o.value === purchaseHistory.lensType)[0];
			if (purchaseHistory.clPrescribed) values.clPrescribed = purchaseHistory.clPrescribed;
			if (purchaseHistory.clType) values.clType = CONSTANSTS.OPTIONS.CL_TYPE.filter((o) => o.value === purchaseHistory.clType)[0];
			if (purchaseHistory.clMaterial) values.clMaterial = CONSTANSTS.OPTIONS.CL_MATERIAL.filter((o) => o.value === purchaseHistory.clMaterial)[0];
		}

		return values;
	}, [purchaseHistory, outlets]);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues,
		validationSchema,
		onSubmit: (values) => {
			onHandleConfirm(values, enableSubmitButton);
		},
	});

	const enableSubmitButton = useCallback(() => {
		formik.setSubmitting(false);
	}, [formik]);

	useEffect(() => {
		getOutlets();
	}, []);

	const getOutlets = async () => {
		const payload = { length: 50, page: 0, status: CONSTANSTS.STATUS.ACTIVE, order: "desc" };

		let response = null;

		try {
			response = await api.get.outlets(payload);
		} catch (error) {
			onHandleRequestError(error);
		}

		if (response) {
			const nextOutlets = response.data.result.storeResponseList;
			const dropdownOutlets = nextOutlets.map((o) => ({ label: o?.storeCode + " " + o.storeName, value: o.storeId }));
			setOutlets(dropdownOutlets);
		}
	};

	const form = useMemo(() => {
		const fields = [
			{
				name: "outlet",
				label: t("purchaseHistoryForm.field.0.label"),
				placeholder: t("purchaseHistoryForm.field.0.placeholder"),
				options: outlets,
				important: true,
				disabled: false,
				type: "select",
			},
			{
				name: "purchaseDate",
				label: t("purchaseHistoryForm.field.1.label"),
				placeholder: t("purchaseHistoryForm.field.1.placeholder"),
				important: true,
				maxDate: new Date(),
				disabled: false,
				type: "date",
			},
			{
				name: "numberOfPurchase",
				label: t("purchaseHistoryForm.field.2.label"),
				placeholder: t("purchaseHistoryForm.field.2.placeholder"),
				disabled: false,
				type: "number",
			},
			{
				name: "prescriptionRange",
				label: t("purchaseHistoryForm.field.3.label"),
				placeholder: t("purchaseHistoryForm.field.3.placeholder"),
				options: CONSTANSTS.OPTIONS.PRESCRIPTION_RANGE,
				disabled: false,
				type: "select",
			},
			{
				name: "index",
				label: t("purchaseHistoryForm.field.4.label"),
				placeholder: t("purchaseHistoryForm.field.4.placeholder"),
				options: CONSTANSTS.OPTIONS.INDEX,
				disabled: false,
				type: "select",
			},
			{
				name: "lensPrescribed",
				label: t("purchaseHistoryForm.field.5.label"),
				placeholder: t("purchaseHistoryForm.field.5.placeholder"),
				disabled: false,
				type: "text",
			},
			{
				name: "lensAccessories",
				label: t("purchaseHistoryForm.field.6.label"),
				placeholder: t("purchaseHistoryForm.field.6.placeholder"),
				options: CONSTANSTS.OPTIONS.LENS_ACCESSORIES,
				disabled: false,
				type: "select",
			},
			{
				name: "lensType",
				label: t("purchaseHistoryForm.field.7.label"),
				placeholder: t("purchaseHistoryForm.field.7.placeholder"),
				options: CONSTANSTS.OPTIONS.LENS_TYPE,
				disabled: false,
				type: "select",
			},
			{
				name: "clPrescribed",
				label: t("purchaseHistoryForm.field.8.label"),
				placeholder: t("purchaseHistoryForm.field.8.placeholder"),
				disabled: false,
				type: "text",
			},
			{
				name: "clType",
				label: t("purchaseHistoryForm.field.9.label"),
				placeholder: t("purchaseHistoryForm.field.9.placeholder"),
				options: CONSTANSTS.OPTIONS.CL_TYPE,
				disabled: false,
				type: "select",
			},
			{
				name: "clMaterial",
				label: t("purchaseHistoryForm.field.10.label"),
				placeholder: t("purchaseHistoryForm.field.10.placeholder"),
				options: CONSTANSTS.OPTIONS.CL_MATERIAL,
				disabled: false,
				type: "select",
			},
		];
		return fields;
	}, [t, outlets]);

	return (
		<div className="app-purchase-history-form">
			<form className="purchase-history-form" autoComplete="off" onSubmit={formik.handleSubmit}>
				{form.map(({ label, important, ...res }, i) => {
					const value = formik.values[res.name];
					const error = formik.errors[res.name];
					const touched = formik.touched[res.name];
					const isDateField = res.type === "date";
					const isDropdownField = res.type === "select";

					if (isDropdownField) {
						return (
							<div key={i} className="purchase-history-form__field">
								<p className={getLabelClassNames({ important })}>{label}</p>
								<AppDropdown {...res} value={value} error={error} touched={touched} data={res.options} onChange={formik.setFieldValue} />
							</div>
						);
					}

					if (isDateField) {
						return (
							<div key={i} className="purchase-history-form__field">
								<p className={getLabelClassNames({ important })}>{label}</p>
								<AppDateInput {...res} value={value} error={error} touched={touched} onChange={formik.setFieldValue} />
							</div>
						);
					}

					return (
						<div key={i} className="purchase-history-form__field">
							<p className="purchase-history-form__label">{label}</p>
							<AppInput {...res} value={value} error={error} touched={touched} onChange={formik.handleChange} />
						</div>
					);
				})}

				<div className="purchase-history-form__button-container">
					<AppButton type="reset" label={t("purchaseHistoryForm.cancel")} cancel onClick={onHandleDismiss} />
					<AppButton type="submit" label={t("purchaseHistoryForm.update")} loading={formik.isSubmitting} />
				</div>
			</form>
		</div>
	);
};

export default memo(AppPurchaseHistoryForm);
