import React, { memo, forwardRef, useImperativeHandle, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import AppButton from "components/app-button";
import deactivateRewardIcon from "assets/images/pages/page-loyalty-programs/deactivate-reward-icon.svg";

const AppDeactivateRewardModal = forwardRef(({ onHandleConfirm, formik }, ref) => {
	const { t } = useTranslation("components");
	const [visible, setVisible] = useState(false);

	useImperativeHandle(ref, () => ({
		onHandleDismiss: onHandleDismiss,
		onHandleShow: onHandleShow,
	}));

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
		formik.setSubmitting(false);
	}, [formik]);

	const onHandleShow = useCallback(() => {
		setVisible(true);
	}, []);

	const onHandleSubmit = () => {
		onHandleDismiss();
		onHandleConfirm();
	};

	return (
		<Modal size="lg" className="app-deactivate-reward-modal" contentClassName="deactivate-reward-modal" centered isOpen={visible} toggle={onHandleDismiss}>
			<ModalHeader className="deactivate-reward-modal__header" toggle={onHandleDismiss}></ModalHeader>
			<ModalBody className="deactivate-reward-modal__body">
				<img className="deactivate-reward-modal__image" src={deactivateRewardIcon} alt="" />
				<h1 className="deactivate-reward-modal__title">{t("deactivateRewardModal.title")}</h1>
				<p className="deactivate-reward-modal__description">{t("deactivateRewardModal.description")}</p>
			</ModalBody>
			<ModalFooter className="deactivate-reward-modal__footer">
				<AppButton type="button" label={t("deactivateRewardModal.cancel")} cancel onClick={onHandleDismiss} />
				<AppButton type="submit" label={t("deactivateRewardModal.cta")} onClick={onHandleSubmit} />
			</ModalFooter>
		</Modal>
	);
});

export default memo(AppDeactivateRewardModal);
