import React, { memo, useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

const AppDropdown = ({ data = [], value, name, touched, error, disabled, note, ...props }) => {
	const [isOpen, setIsOpen] = useState(false);
	const label = value?.label || props.placeholder;
	const selected = !!value?.label;
	const invalid = touched && error;

	const onHandleToggleDropdown = () => {
		setIsOpen(!isOpen);
	};

	const onHandleSelectItem = (item) => {
		props.onChange(name, item);
	};

	return (
		<div className="app-dropdown">
			<Dropdown className={getWrapperClassNames({ invalid, disabled })} isOpen={isOpen} toggle={onHandleToggleDropdown}>
				<DropdownToggle caret className={getToggleClassNames({ selected })} disabled={disabled} onClick={onHandleToggleDropdown}>
					{label}
				</DropdownToggle>
				<DropdownMenu className="app-dropdown__list">
					{data?.map((item, i) => {
						return (
							<DropdownItem key={i} className="app-dropdown__item" onClick={() => onHandleSelectItem(item)}>
								{item.label}
							</DropdownItem>
						);
					})}
				</DropdownMenu>
			</Dropdown>
			{invalid && <p className="app-dropdown__text app-dropdown__text--error">{error}</p>}

			{note && <p className="app-dropdown__text">{note}</p>}
		</div>
	);
};

const getWrapperClassNames = ({ invalid, disabled }) => {
	const classNames = ["app-dropdown__container"];

	if (disabled) classNames.push("app-dropdown__container--disabled");

	if (invalid) classNames.push("app-dropdown__container--error");

	return classNames.join(" ");
};

const getToggleClassNames = ({ selected }) => {
	const classNames = ["app-dropdown__toggle"];

	if (selected) classNames.push("app-dropdown__toggle--active");

	return classNames.join(" ");
};

export default memo(AppDropdown);
