import React, { useEffect, useCallback } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { BsDot } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { IoIosLock, IoIosClose, IoIosCheckmark } from "react-icons/io";

import api from "services/api";
import pathnames from "routes/pathnames";
import CONSTANSTS from "common/constansts";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import useIsMount from "hooks/use-is-mount";
import useQueryParams from "hooks/use-query-params";
import { onHandleRequestError } from "common/utilities";
import logo from "assets/images/menara-logo.svg";
import heroImage from "assets/images/hero-image.svg";

const validationSchema = Yup.object().shape({
	password: Yup.string().required("Password does not meet the requirements").matches(CONSTANSTS.REGEX.PASSWORD, "Password does not meet the requirements"),
	confirmPassword: Yup.string()
		.required("Password confirmation does not meet the requirements")
		.oneOf([Yup.ref("password"), null], "Password confirmation doesn't match"),
});

const initialValues = {
	password: "",
	confirmPassword: "",
};

const PageResetPassword = () => {
	const { t } = useTranslation("resetPassword");
	const isMount = useIsMount();
	const params = useQueryParams();
	const otpCode = params["otpCode"];
	const userId = params["userId"];
	const navigate = useNavigate();
	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values) => {
			onHandleResetPassword();
		},
	});

	useEffect(() => {
		if (isMount) {
			if (!otpCode || !userId) return navigate(pathnames.signIn);

			(async function () {
				try {
					const payload = { adminId: userId, otpCode };
					await api.post.verifyUserOtp(payload);
				} catch (error) {
					navigate(pathnames.expiredLink);
				}
			})();
		}
	}, [navigate, otpCode, userId, isMount]);

	const onHandleResetPassword = async () => {
		let response = null;

		try {
			const payload = { adminId: userId, newPassword: formik.values.password };
			response = await api.post.resetPassword(payload);
		} catch (error) {
			onHandleRequestError(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			navigate(pathnames.passwordUpdated);
		}
	};

	const onHandleGetValidationStatus = useCallback(() => {
		const value = formik.values.password;
		const touched = formik.touched.password;
		const digit = /(?=.*\d)/;
		const lowerCase = /(?=.*[a-z])/;
		const upperCase = /(?=.*[A-Z])/;
		const symbol = /(\W|_)/;
		const length = 8;

		const requirement = [
			{
				test: value.length >= length,
				label: t("requirement.0.label"),
			},
			{
				test: value.match(upperCase),
				label: t("requirement.1.label"),
			},
			{
				test: value.match(lowerCase),
				label: t("requirement.2.label"),
			},
			{
				test: value.match(digit),
				label: t("requirement.3.label"),
			},
			{
				test: value.match(symbol),
				label: t("requirement.4.label"),
			},
		];

		const validationItem = requirement.map((item, index) => {
			const initStastus = !value && !touched;
			const icon = initStastus ? <BsDot /> : item.test ? <IoIosCheckmark /> : <IoIosClose />;
			const className = initStastus ? "" : item.test ? "reset-password__description--valid" : "reset-password__description--in-valid";
			return (
				<p key={index} className={`reset-password__description ${className}`}>
					{icon}
					{item.label}
				</p>
			);
		});

		return validationItem;
	}, [formik, t]);

	return (
		<div className="app-page page-reset-password">
			<div className="reset-password">
				<div className="reset-password__container">
					<div className="reset-password__hero-image">
						<img src={heroImage} alt="" />
					</div>
				</div>
				<div className="reset-password__container">
					<div className="reset-password__logo">
						<Link to={pathnames.signIn}>
							<img src={logo} alt="" />
						</Link>
					</div>
					<h1 className="reset-password__title">{t("title")}</h1>
					<p className="reset-password__sub-title">{t("subTitle")}</p>

					<form autoComplete="off" onSubmit={formik.handleSubmit}>
						<AppInput type="password" name="password" placeholder={t("field.0.placeholder")} icon={<IoIosLock />} error={formik.errors.password} touched={formik.touched.password} onChange={formik.handleChange} />
						<AppInput type="password" name="confirmPassword" placeholder={t("field.1.placeholder")} icon={<IoIosLock />} error={formik.errors.confirmPassword} touched={formik.touched.confirmPassword} onChange={formik.handleChange} />

						<div className="reset-password__guidance">{onHandleGetValidationStatus()}</div>

						<AppButton type="submit" label={t("cta")} loading={formik.isSubmitting} />
					</form>
				</div>
			</div>
		</div>
	);
};

export default PageResetPassword;
