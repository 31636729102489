import i18n from "locales/i18n";
import pathnames from "routes/pathnames";

const CONSTANSTS = {
	IS_DEVELOPMENT_MODE: !process.env.NODE_ENV || process.env.NODE_ENV === "development",
	ENDPOINT_PATH: {
		SIGN_IN: "/admin/login",
		PROFILE: "/profile/",
		FORGET_PASSWORD: "/admin/forgot-password",
		VERIFY_USER_OTP: "/admin/verify-reset-password",
		RESET_PASSWORD: "/admin/reset-password",
		UPDATE_PROFILE: "/admin/update",
		UPDATE_SUPER_PROFILE: "/super-admin/update",
		RESET_SUPER_PASSWORD: "/super-admin/reset-password",
		CREATE_ADMIN: "/super-admin/create-admin",
		UPDATE_ADMIN_PROFILE: "/super-admin/update-admin",
		UPDATE_HELPDESK: "/super-admin/update-helpdesk-email",
		USERS: "/super-admin/",
		OUTLETS: "/store/",
		CREATE_OUTLET: "/store/create",
		UPDATE_OUTLET: "/store/update",
		FAQS: "/faq/",
		CREATE_FAQ: "/faq/create",
		UPDATE_FAQ: "/faq/update",
		REMOVE_FAQ: "/faq/remove/",
		BANNERS: "/banner/",
		CREATE_BANNER: "/banner/create",
		UPDATE_BANNER: "/banner/update",
		REWARDS: "/rewards/",
		CREATE_REWARD: "/rewards/create",
		UPDATE_REWARD: "/rewards/update",
		CUSTOMERS: "/customer/",
		CUSTOMER: "/customer/",
		UPDATE_CUSTOMER: "/admin/update-customer-profile",
		UPDATE_CUSTOMER_POINTS: "/admin/allocate-points",
		CUSTOMER_PURCHASE_HISTORY: "/purchase-history/find-transaction-by-customer",
		CREATE_CUSTOMER_PURCHASE_HISTORY: "/purchase-history/create",
		UPDATE_CUSTOMER_PURCHASE_HISTORY: "/purchase-history/update",
		CUSTOMER_EYE_EXAMINATION_HISTORY: "/eye-examination-history/find-eye-exam-history-by-customer",
		CREATE_CUSTOMER_EYE_EXAMINATION_HISTORY: "/eye-examination-history/create",
		UPDATE_CUSTOMER_EYE_EXAMINATION_HISTORY: "/eye-examination-history/update",
		CUSTOMER_POINTSLOG: "/pointsLog/get-customer-pointslog",
		CUSTOMER_VOUCHERS: "/vouchers/get-customer-voucher",
		GROUPS: "/group/",
		CREATE_GROUP: "/group/create",
		UPDATE_GROUP: "/group/update",
		UPDATE_GROUP_STATUS: "/group/{groupId}/trigger-event/",
		CATEGORIES: "/category/",
		CREATE_CATEGORY: "/category/create",
		UPDATE_CATEGORY: "/category/update",
		VARIATION: "/variation/",
		CREATE_VARIANT: "/variation/create",
		UPDATE_VARIANT: "/variation/update",
		CATEGORIES_VARIATION: "/group/get-categories-and-variations/",
		PRODUCTS: "/product/",
		CREATE_PRODUCT: "/product/create",
		UPDATE_PRODUCT: "/product/update",
		ANNOUNCEMENTS: "/notification/get-announcement",
		CREATE_ANNOUNCEMENT: "/admin/publish-announcement",
		UPDATE_ANNOUNCEMENT: "/admin/update-announcement",
		NOTIFICATIONS: "/admin/get-admin-announcement",
		NOTIFICATION_ALERT: "/notification/check-new-notification",
		NOTIFICATION_EVENT: "/notification/{notificationId}/trigger-event/setToRead",
		PUSH_NOTIFICATIONS: "/fcm/register",
		REMOVE_PUSH_NOTIFICATIONS: "/fcm/remove",
	},
	DATE_FORMAT: {
		HHMM: "HH:mma",
		YYYYMMDD: "YYYY-MM-DD",
		DDMMYYYY: "DD/MM/yyyy",
		ddMMYYYY: "dd/MM/yyyy",
		YYYYMMDDHH_MMA: "YYYY-MM-DD, HH:mmA",
	},
	REGEX: {
		PASSWORD: /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
	},
	UPLOAD: {
		PDF: "pdf",
		IMAGE: "image",
		PDF_TYPE: ["application/pdf"],
		IMAGES_TYPE: ["image/jpg", "image/jpeg", "image/png"],
		SIZES_LIMIT: 5000000, //5mb
		ERRORS: [],
	},
	LOCAL_STORAGE: {
		STORAGE_TOKEN: "@menara-storage-token",
		USER_LANGUAGE: "@menara-user-language",
	},
	ROLE: {
		SUPER_ADMIN: "superAdmin",
		ADMIN: "admin",
	},
	MODE: {
		CREATE: "create",
		EDIT: "edit",
	},
	STATUS: {
		ACTIVE: "active",
		USED: "used",
		EXPIRED: "expired",
		INACTIVE: "inactive",
		PENDING_VERIFICATION: "pendingVerification",
	},
	NOTIFICATIONS: {
		READ: "read",
		NEW: "new",
		PERMISSIONS: {
			GRANTED: "granted",
			DENIEND: "denied",
		},
	},
	TRIGGER_EVENTS: {
		ACTIVE: "setToActive",
		INACTIVE: "setToInactive",
	},
	COUNTRIES: [{ label: "Malaysia", value: "malaysia" }],
	OPTIONS: {
		STATUS: [],
		SUB_STATUS: [],
		STATES: [],
		RECIPIENTS: [],
		PRESCRIPTION_RANGE: [
			{ label: "EXTREME CYL >-3.00D", value: "EXTREME CYL >-3.00D" },
			{ label: "HI MYOP >-6.00D", value: "HI MYOP >-6.00D" },
			{ label: "MID MYOP >-3.00D <-6.00D", value: "MID MYOP >-3.00D <-6.00D" },
			{ label: "LOW MYOP <-3.00D", value: "LOW MYOP <-3.00D" },
			{ label: "HI HYPEROP >+5.00D", value: "HI HYPEROP >+5.00D" },
			{ label: "MID HYPEROP >+2.00D <+5.00D", value: "MID HYPEROP >+2.00D <+5.00D" },
			{ label: "LOW HYPEROP <+2.00D", value: "LOW HYPEROP <+2.00D" },
			{ label: "HI CYL >-2.00D", value: "HI CYL >-2.00D" },
			{ label: "ANISO", value: "ANISO" },
		],
		INDEX: [
			{ label: "1.5", value: 1.5 },
			{ label: "1.53", value: 1.53 },
			{ label: "1.55", value: 1.55 },
			{ label: "1.56", value: 1.56 },
			{ label: "1.59", value: 1.59 },
			{ label: "1.6", value: 1.6 },
			{ label: "1.67", value: 1.67 },
			{ label: "1.7", value: 1.7 },
			{ label: "1.74", value: 1.74 },
			{ label: "1.8", value: 1.8 },
			{ label: "1.9", value: 1.9 },
		],
		LENS_ACCESSORIES: [
			{ label: "None", value: "None" },
			{ label: "Prism", value: "Prism" },
			{ label: "Photogrey", value: "Photogrey" },
			{ label: "Photogrey BC", value: "Photogrey BC" },
			{ label: "Polarized", value: "Polarized" },
			{ label: "Trans", value: "Trans" },
			{ label: "Trans BC", value: "Trans BC" },
			{ label: "BC", value: "BC" },
			{ label: "Tinted", value: "Tinted" },
			{ label: "Others", value: "Others" },
		],
		LENS_TYPE: [
			{ label: "MF", value: "MF" },
			{ label: "BF", value: "BF" },
			{ label: "SV", value: "SV" },
			{ label: "OL", value: "OL" },
			{ label: "Myopia Control", value: "Myopia Control" },
			{ label: "Antifatigue", value: "Antifatigue" },
		],
		CL_TYPE: [
			{ label: "SV", value: "SV" },
			{ label: "MF", value: "MF" },
			{ label: "Monovision", value: "Monovision" },
			{ label: "Color SV", value: "Color SV" },
			{ label: "Color MV", value: "Color MV" },
			{ label: "Toric", value: "Toric" },
			{ label: "Myopia Control", value: "Myopia Control" },
		],
		CL_MATERIAL: [
			{ label: "RGP", value: "RGP" },
			{ label: "SiHy Dailies", value: "SiHy Dailies" },
			{ label: "SiHy Bi-Weekly", value: "SiHy Bi-Weekly" },
			{ label: "SiHy Monthly", value: "SiHy Monthly" },
			{ label: "Hydrogel Dailies", value: "Hydrogel Dailies" },
			{ label: "Hydrogel Monthly", value: "Hydrogel Monthly" },
		],
		APPOINTMENTS: [
			{ label: "KIV Eye Test", value: "KIV Eye Test" },
			{ label: "Vergence", value: "Vergence" },
			{ label: "Accommodation", value: "Accommodation" },
			{ label: "Visual Skill", value: "Visual Skill" },
			{ label: "Amblyope", value: "Amblyope" },
			{ label: "Myopia progression", value: "Myopia progression" },
			{ label: "SV CL", value: "SV CL" },
			{ label: "Monovision CL", value: "Monovision CL" },
			{ label: "Multifocal CL", value: "Multifocal CL" },
			{ label: "Toric CL", value: "Toric CL" },
			{ label: "CL Complications", value: "CL Complications" },
			{ label: "Diabetic", value: "Diabetic" },
			{ label: "Hypertension", value: "Hypertension" },
			{ label: "Glaucoma", value: "Glaucoma" },
			{ label: "Cataract Progression", value: "Cataract Progression" },
		],
	},
	TABS: {
		CUSTOMERS: [],
		SETTINGS: [],
	},
	FILTER_BY: {
		STATUS: [],
		VOUCHER_STATUS: [],
		CUSTOMER_STATUS: [],
		STATES: [],
	},
};

const initializeOptions = () => {
	CONSTANSTS.UPLOAD.ERRORS = [
		{ type: "fileSize", error: i18n.t("common:errors.fileSize") },
		{ type: "fileType", error: i18n.t("common:errors.fileType") },
		{ type: "fileLimit", error: i18n.t("common:errors.fileLimit") },
	];

	CONSTANSTS.OPTIONS.STATUS = [
		{ label: i18n.t("common:status.active"), value: "active" },
		{ label: i18n.t("common:status.inactive"), value: "inactive" },
	];

	CONSTANSTS.OPTIONS.SUB_STATUS = [
		{ label: i18n.t("common:status.moved"), value: "moved" },
		{ label: i18n.t("common:status.passedAway"), value: "passedAway" },
		{ label: i18n.t("common:status.none"), value: "" },
	];

	CONSTANSTS.OPTIONS.RECIPIENTS = [
		{ label: i18n.t("common:recipients.customer"), value: "customer" },
		{ label: i18n.t("common:recipients.admin"), value: "admin" },
	];

	CONSTANSTS.OPTIONS.STATES = [
		{ label: i18n.t("common:states.johor"), value: "johor" },
		{ label: i18n.t("common:states.kedah"), value: "kedah" },
		{ label: i18n.t("common:states.kelantan"), value: "kelantan" },
		{ label: i18n.t("common:states.malacca"), value: "malacca" },
		{ label: i18n.t("common:states.negeriSembilan"), value: "negeri sembilan" },
		{ label: i18n.t("common:states.pahang"), value: "pahang" },
		{ label: i18n.t("common:states.penang"), value: "penang" },
		{ label: i18n.t("common:states.perak"), value: "perak" },
		{ label: i18n.t("common:states.perlis"), value: "perlis" },
		{ label: i18n.t("common:states.sabah"), value: "sabah" },
		{ label: i18n.t("common:states.sarawak"), value: "sarawak" },
		{ label: i18n.t("common:states.selangor"), value: "selangor" },
		{ label: i18n.t("common:states.terengganu"), value: "terengganu" },
		{ label: i18n.t("common:states.kualaLumpur"), value: "kuala lumpur" },
		{ label: i18n.t("common:states.labuan"), value: "labuan" },
		{ label: i18n.t("common:states.putrajaya"), value: "putrajaya" },
	];

	CONSTANSTS.FILTER_BY.STATUS = [
		{ label: i18n.t("common:status.all"), value: "all" },
		{ label: i18n.t("common:status.active"), value: "active" },
		{ label: i18n.t("common:status.inactive"), value: "inactive" },
	];

	CONSTANSTS.FILTER_BY.CUSTOMER_STATUS = [
		{ label: i18n.t("common:status.all"), value: "all" },
		{ label: i18n.t("common:status.active"), value: "active" },
		{ label: i18n.t("common:status.inactive"), value: "inactive" },
		{ label: i18n.t("common:status.pendingVerification"), value: "pendingVerification" },
	];

	CONSTANSTS.FILTER_BY.VOUCHER_STATUS = [
		{ label: i18n.t("common:status.all"), value: "all" },
		{ label: i18n.t("common:status.active"), value: "active" },
		{ label: i18n.t("common:status.expired"), value: "expired" },
		{ label: i18n.t("common:status.completed"), value: "used" },
	];

	CONSTANSTS.FILTER_BY.STATES = [
		{ label: i18n.t("common:states.all"), value: null },
		{ label: i18n.t("common:states.johor"), value: "johor" },
		{ label: i18n.t("common:states.kedah"), value: "kedah" },
		{ label: i18n.t("common:states.kelantan"), value: "kelantan" },
		{ label: i18n.t("common:states.malacca"), value: "malacca" },
		{ label: i18n.t("common:states.negeriSembilan"), value: "negeri sembilan" },
		{ label: i18n.t("common:states.pahang"), value: "pahang" },
		{ label: i18n.t("common:states.penang"), value: "penang" },
		{ label: i18n.t("common:states.perak"), value: "perak" },
		{ label: i18n.t("common:states.perlis"), value: "perlis" },
		{ label: i18n.t("common:states.sabah"), value: "sabah" },
		{ label: i18n.t("common:states.sarawak"), value: "sarawak" },
		{ label: i18n.t("common:states.selangor"), value: "selangor" },
		{ label: i18n.t("common:states.terengganu"), value: "terengganu" },
		{ label: i18n.t("common:states.kualaLumpur"), value: "kuala lumpur" },
		{ label: i18n.t("common:states.labuan"), value: "labuan" },
		{ label: i18n.t("common:states.putrajaya"), value: "putrajaya" },
	];

	CONSTANSTS.TABS.CUSTOMERS = [
		{ title: i18n.t("customer:tabs.0.label"), path: pathnames.customer },
		{ title: i18n.t("customer:tabs.1.label"), path: pathnames.purchaseTransaction },
		{ title: i18n.t("customer:tabs.2.label"), path: pathnames.eyeExamination },
		{ title: i18n.t("customer:tabs.3.label"), path: pathnames.pointsHistory },
		{ title: i18n.t("customer:tabs.4.label"), path: pathnames.vouchers },
	];
	CONSTANSTS.TABS.SETTINGS = [
		{ title: i18n.t("helpdesk:tabs.0.label"), path: pathnames.settings },
		{ title: i18n.t("helpdesk:tabs.1.label"), path: pathnames.helpdesk },
	];
};

initializeOptions();
i18n.on("languageChanged", initializeOptions);

export default CONSTANSTS;
