import React, { memo, useMemo, useCallback } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppTextarea from "components/app-textarea";

const AppFaqEditForm = ({ initialValues, onHandleDismiss, onHandleConfirm }) => {
	const { t } = useTranslation("components");
	//prettier-ignore
	const validationSchema = useMemo(() => Yup.object().shape({
		faqTitle: Yup.string().required(t("common:required")),
		faqDescription: Yup.string().required(t("common:required")),
	}), [t]);
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			faqId: initialValues.faqId,
			faqTitle: initialValues.faqTitle,
			faqDescription: initialValues.faqDescription,
		},
		validationSchema,
		onSubmit: (values) => {
			onHandleConfirm(values, enableSubmitButton);
		},
	});

	const enableSubmitButton = useCallback(() => {
		formik.setSubmitting(false);
	}, [formik]);

	const onHandleReset = () => {
		formik.resetForm();
		onHandleDismiss();
	};

	return (
		<div className="app-faq-edit-form">
			<form className="faq-edit-form" autoComplete="off" onSubmit={formik.handleSubmit}>
				<div className="faq-edit-form__field">
					<p className="faq-edit-form__label">{t("faqForm.field.0.label")}</p>
					<AppInput type="text" name="faqTitle" placeholder={t("faqForm.field.0.placeholder")} value={formik.values.faqTitle} error={formik.errors.faqTitle} touched={formik.touched.faqTitle} onChange={formik.handleChange} />
				</div>

				<div className="faq-edit-form__field">
					<p className="faq-edit-form__label">{t("faqForm.field.1.label")}</p>
					<AppTextarea name="faqDescription" placeholder={t("faqForm.field.1.placeholder")} value={formik.values.faqDescription} error={formik.errors.faqDescription} touched={formik.touched.faqDescription} onChange={formik.handleChange} />
				</div>

				<div className="faq-edit-form__button-container">
					<AppButton type="reset" label={t("faqForm.cancel")} cancel onClick={onHandleReset} />
					<AppButton type="submit" label={t("faqForm.update")} loading={formik.isSubmitting} />
				</div>
			</form>
		</div>
	);
};

export default memo(AppFaqEditForm);
