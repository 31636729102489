import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import routes from "routes/routes";
import history from "routes/history";
import AppLayout from "components/app-layout";
import AppAlertMessage from "components/app-alert-message";
import InterceptorProvider from "contexts/with-interceptor-provider";
import AuthenticateProvider from "contexts/with-authentication-provider";

const PageApp = () => {
	return (
		<BrowserRouter>
			<InterceptorProvider>
				<AppLayout>
					<AppAlertMessage />
					<Routes history={history}>
						{routes.map((route) => {
							const path = route.query ? route.path + route.query : route.path;
							const options = { authenticate: route.authenticate, restrict: route.restrict };
							return <Route key={route.path} path={path} exact strict element={<AuthenticateProvider {...options}>{route.component}</AuthenticateProvider>} />;
						})}
					</Routes>
				</AppLayout>
			</InterceptorProvider>
		</BrowserRouter>
	);
};

export default PageApp;
